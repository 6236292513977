import "./OpenNavListcopy.css";

import React, { useEffect, useState } from "react";
import NavList from "../NavList/NavList";

import Select from "react-select";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { useSelector, useDispatch } from "react-redux";
import { parseBookPageToHebrew } from "../../utils/menuUtils";
import {
  getPageId,
  getUserProfileView,
  getMenuChapters,
  getMenuChaptersView,
  getSectionNames,
  getPageNumberFromUrl
} from "../../store/selectors/selectors";
import {
  closeMenu,
  setUserProfileView,
  setCurrentEvents,
  setMenuChaptersView,
} from "../../store/actions/viewActions";
import {
  clearTabOpenRight,
  getPageData,
  setCommentaryToShow,
  setCommentaryselectChecked,
  setIsSfarimOn,
  setPageNumberFromUrl,

} from "../../store/actions/pageActions";
import copy from 'copy-to-clipboard';
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  getAllnodes,
  getAllCategoryNameSub,
  getIsSelect,
  getTitle,
  getAllPages,
  isPentateuchIncludes,
  isPentateuchG
} from "../../utils/booksAndChapters2";
interface Props {
  menuView: string;
  chosenBookAllPages: Array<string>;
  pageLocal: string;
  contentCounts: Array<any>;
  allCategoryName: Array<any>;
  first: number;
  bookBefor: any;
}

const OpenNavList: React.FC<Props> = ({ menuView, chosenBookAllPages, pageLocal, contentCounts, allCategoryName, first, bookBefor }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const pageId = useSelector(getPageId);
  const userProfileView = useSelector(getUserProfileView);
  const chosenBookChapters = useSelector(getMenuChapters);
  const menuChaptersView = useSelector(getMenuChaptersView);
  const sectionNames = useSelector(getSectionNames);
  const [perek, setPerek] = useState(0);
  const pageNumberFromUrl = useSelector(getPageNumberFromUrl);

  // remove the commentary from the list of commentaries that need to be shown, and if it's the last one, come back to- choose-sfarim comp.
  const removeFromCommentary = () => {
    dispatch(setCommentaryToShow(null));
    dispatch(setCommentaryselectChecked([]));
    dispatch(setIsSfarimOn(false));
    dispatch(clearTabOpenRight());
  };

  useEffect(() => {
    if (allCategoryName && getAllCategoryNameSub().includes(allCategoryName[0]) && (allCategoryName.length === 2 || allCategoryName.length === 1)) {
      setPerek(pageNumberFromUrl.split('.')[0]);
      return;
    }
    else if (chosenBookChapters && chosenBookChapters.length > 0) {
      chosenBookChapters.map((e: any) => {
        if (pageLocal === e.chapterName) {
          dispatch(setMenuChaptersView(e.chapterName));
        }
      })
    }
  }, []);


  const handleSelectPage = (bookId: any, sectionNames: boolean) => {

    if (userProfileView === "MenuMobile") {
      dispatch(setUserProfileView(""));
    }
    if (bookId.title) {
      bookId = bookId.title;
    }

    if (!bookId.includes('.') && isPentateuchIncludes(bookId.replace(bookId.split(" ")[bookId.split(" ").length - 1], ''))) {
      setPerek(Number(bookId.split(" ")[bookId.split(" ").length - 1]));
      dispatch(setMenuChaptersView('subPage'));
      dispatch(setPageNumberFromUrl(`${Number(bookId.split(" ")[bookId.split(" ").length - 1])}`));
      return;
    }

    if (bookId) {
      //dispatch(setMenuChaptersView('allPages'));

      const bookSplit = bookId.replace('%2C', ',').split(" ").join("_").split("_");
      let page = bookSplit[bookSplit.length - 1];

      bookSplit.splice(-1);
      const book = bookSplit.join("_");
      dispatch(closeMenu());


      if (sectionNames) {
        if (Number(page) % 2 === 1) {
          page = (((Number(page) - 1) / 2) + 1).toString() + 'a';
        }
        if (Number(page) % 2 === 0) {
          page = (((Number(page)) / 2)).toString() + 'b';
        }
        dispatch(getPageData(book, page));
      }
      else {
        if (book.endsWith('_,'))
          dispatch(getPageData(book.split('_,')[0], ',_' + page));
        else {
          dispatch(getPageData(book, page));
        }
      }

      dispatch(setCurrentEvents(''));
      removeFromCommentary();
    }
  };

  const handleDropDownMenuClick = (e: any) => {
    dispatch(setMenuChaptersView(e));
  };

  const getSelectMenu = () => {

    let defaultValue = [{ value: 'allPages', label: 'הכל' }];
    if (chosenBookAllPages) {
      return defaultValue.concat(
        chosenBookChapters.map((chapter: any) => {
          return { value: chapter.chapterName, label: chapter.chapterName };
        })
      );
    }
  };



  const getChapterPages = (chapterName = '') => {
    let filterdBook: string[] = [];
    if (chapterName !== '' && menuChaptersView === 'allPages') {
      filterdBook = chosenBookChapters?.filter((chapter: any) => {
        return chapter.chapterName === chapterName;
      });
    }
    else {
      filterdBook = chosenBookChapters?.filter((chapter: any) => {
        return chapter.chapterName === menuChaptersView;
      });
    }

    if (filterdBook && filterdBook[0]) {
      return getAllPages(filterdBook).map((page: any) => {

        if (page !== undefined)
          return (
            <>
              <ContextMenuTrigger id={page === pageId ? "selected-page" : page}>
                <div
                  className={
                    page === pageId
                      ? "bookChosen-page-item bookChosen-current-item"
                      : "bookChosen-page-item"
                  }

                  key={page}
                  onClick={() => handleSelectPage(page, sectionNames && sectionNames[0] === 'Daf' && sectionNames[1] === 'Line')}
                >
                  <p className="bookChosen-page-item-text">
                    {page && parseBookPageToHebrew(page.split(':')[0], sectionNames && sectionNames[0] === 'Daf' && sectionNames[1] === 'Line')}
                  </p>
                </div>
              </ContextMenuTrigger>
              <ContextMenu className={'context-menu'} id={page === pageId ? "selected-page" : page} >
                <MenuItem className={'context-menu-item'} onClick={() => {
                  window.open(`/${page.split(' ').length === 2 ? page.split(' ')[0] : page.split(' ')[0] + ' ' + page.split(' ')[1]}/${page.split(' ').length === 2 ? page.split(' ')[1] : page.split(' ')[2]}`, 'new_window')
                }}>
                  {'פתיחת קישור בכרטיסייה חדשה'}
                </MenuItem>
                <MenuItem className={'context-menu-item'} onClick={() => {
                  window.open(`/${page.split(' ').length === 2 ? page.split(' ')[0] : page.split(' ')[0] + ' ' + page.split(' ')[1]}/${page.split(' ').length === 2 ? page.split(' ')[1] : page.split(' ')[2]}`, '_blank', 'toolbar=0,location=0,menubar=0')
                }} >
                  {'פתיחת קישור בחלון חדש'}
                </MenuItem>
                <MenuItem className={'context-menu-item'} onClick={() => {
                  copy(`${window.location.origin}/${page.split(' ').length === 2 ? page.split(' ')[0] : page.split(' ')[0] + ' ' + page.split(' ')[1]}/${page.split(' ').length === 2 ? page.split(' ')[1] : page.split(' ')[2]}`, {
                    debug: true,
                    message: 'Press #{key} to copy',
                  });
                }} >
                  {'העתק כתובת קישור'}
                </MenuItem>
              </ContextMenu>
            </>
          );
      });
    }
    else {
      return null
    };
  };

  const getChapterPagesSub = () => {
    let p = pageNumberFromUrl.split('.')[0];

    if (p === 0) {

    }

    if (isPentateuchG(getTitle(pageLocal))) {
      contentCounts = contentCounts[1]
    }
    return [...Array(contentCounts[p - 1])].map((e: any, i: any) => {
      const bookEm = getTitle(pageLocal)
      const link = `/${bookEm}/${p + '.' + i + 1}`
      return (
        <>
          <ContextMenuTrigger id={i + 1 === pageId ? "selected-page" : `${i + 1}`}>
            <button
              className={
                i + 1 === pageId
                  ? "bookChosen-page-item bookChosen-current-item"
                  : "bookChosen-page-item"
              }
              id={i + 1 === pageId ? "selected-page" : `${i + 1}`}
              key={i + 1}

              onClick={() => {
                dispatch(closeMenu());
                dispatch(getPageData(bookEm, p + '.' + (i + 1)));
              }
              }
            >
              <p className="bookChosen-page-item-text">
                {parseBookPageToHebrew(bookEm + ' ' + p + ' ' + (i + 1), sectionNames && sectionNames[0] === 'Daf' && sectionNames[1] === 'Line')}
              </p>
            </button>
          </ContextMenuTrigger>
          <ContextMenu className={'context-menu'} id={i + 1 === pageId ? "selected-page" : `${i + 1}`} >
            <MenuItem className={'context-menu-item'} onClick={() => {
              window.open(link, 'new_window')
            }} >
              {'פתיחת קישור בכרטיסייה חדשה'}                         </MenuItem>
            <MenuItem className={'context-menu-item'} onClick={() => {
              window.open(link, '_blank', 'toolbar=0,location=0,menubar=0')
            }} >
              {'פתיחת קישור בחלון חדש'}
            </MenuItem>
            <MenuItem className={'context-menu-item'} onClick={() => {
              copy(`${window.location.origin}/${link}`, {
                debug: true,
                message: 'Press #{key} to copy',
              });
            }} >
              {'העתק כתובת קישור'}
            </MenuItem>
          </ContextMenu>

        </>
      );
    })
  };

  const isSelect = () => {

    if (allCategoryName && getIsSelect().includes(allCategoryName[0]))
      return true

    return false
  }
  const allEqual = (arr: any) => arr.every((val: any) => val === arr[0]);

  return (
    <div className={`openNav-bookChosen-container-wrapper ${first === 1 ? 'first' : ''}`}>
      {isSelect() &&
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={getSelectMenu()}
          isRtl={true}
          isSearchable={false}
          defaultValue={{ value: 'allPages', label: 'הכל' }}
          onChange={(e: any) => handleDropDownMenuClick(e.value)}
          maxMenuHeight={200}
        />}

      {
        contentCounts && //chosenBookAllPages && //chosenBookAllPages.length !== 0 &&
        //Array.from({ length: contentCounts[Number(pageLocal.split(' ')[pageLocal.split(' ').length - 1]) - 1] }, (v, i) => i).length === 0 &&
        <div className="select-chapter-openNav">
          <div className="openNav-bookChosen-container">


            {menuChaptersView === "allPages" && getAllnodes(contentCounts, pageLocal, sectionNames && sectionNames[0] === 'Daf' && sectionNames[1] === 'Line', pageId.split(' ').join('_').replace(pageNumberFromUrl, '').split('_').join(' ').trim()).map((page: any, index: any) => {

              if (page && page.book && isPentateuchG(page.book) && menuChaptersView !== 'subPage') {

                return (<>
                  <h2 className="openNavList-list-header">{page.hePage}</h2>
                  {getChapterPages(page.hePage)}</>
                )
              }
              if (!page) {
                return (<></>)
              }
              if (page && page.book === `~`) {

                return (
                  <h2 className="openNavList-list-header">{page.hebook}</h2>
                )
              }


              if (typeof contentCounts[index] === 'object' && !contentCounts[index].key && contentCounts[index][0] && (allEqual(contentCounts[index]))) {
                return (<></>)
              }

              if ((contentCounts && contentCounts[index] === 0)) {
                return (<></>)
              }

              if ((page.sectionName && page.sectionName[0] === 'Daf') || (sectionNames && sectionNames[0] === 'Daf' && sectionNames[1] === 'Line')) {
                if (Number(page.page) % 2 === 1) {
                  page.page = (((Number(page.page) - 1) / 2) + 1).toString() + 'a';
                }
                if (Number(page.page) % 2 === 0) {
                  page.page = (((Number(page.page)) / 2)).toString() + 'b';
                }
              }

              return (<NavList
                book={page.book === 'default' ? getTitle(pageLocal) : page.book}
                hePage={page.hePage}
                isLink={page.isLink}
                page={page.page}
                sectionName={page.sectionName}
              />)

            })}

            {(menuChaptersView !== "" && menuChaptersView !== "allPages" && menuChaptersView !== 'subPage') && getChapterPages()}
            {(menuChaptersView === 'subPage') && allCategoryName && getAllCategoryNameSub().includes(allCategoryName[0]) && getChapterPagesSub()}
          </div>
        </div>}

    </div >
  )
}

export default OpenNavList;
