import config from "./configService";
import Axios, { AxiosRequestConfig } from "axios";
import $ from 'jquery';
import groupBy from 'lodash/groupBy';
import qs from 'qs';
class WikiService {

  private async getToken(type: string) {
    const res: any = await Axios.get(
      config.pathApi, {
      params: {
        action: 'query',
        meta: 'tokens',
        format: 'json',
        origin: config.origin,
        type: type
      },
      withCredentials: true,
      responseType: 'json'
    })

    return res.data.query.tokens[type + 'token'];
  }

  public async callAxios(data: any, method: any) {
    let pathApi = config.pathApi
    const options = {
      method: method,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      pathApi,
      withCredentials: true,
      dataType: 'json'
    };
    const res: any = await Axios(pathApi, options as AxiosRequestConfig)
    return res.data;
  }

  public async LogIn(name: string, password: string) {
    const login = await this.getToken('login');
    const res: any = await this.callAxios({
      origin: config.origin,
      action: 'clientlogin',
      format: 'json',
      username: name,
      logintoken: login,
      password: password,
      loginreturnurl: config.loginreturnurl,
      rememberMe: 1,
    }
      , 'POST')

    return res.clientlogin.status;
  }

  public async replyTopic(Topic: string, replyTo: string, content: any) {
    const login = await this.getToken('csrf');
    const response: any = await this.callAxios({
      origin: config.origin,
      action: 'flow',
      format: 'json',
      submodule: 'reply',
      page: `Topic:` + Topic,
      repreplyTo: replyTo,
      repcontent: content,
      token: login
    }
      , 'POST')
    if (response.flow && response.flow['reply'].status === 'ok')
      return response.flow['reply'].status;
    else
      return '';
  }

  public async viewTopicList(title: string) {

    const response: any = await this.callAxios({
      origin: config.origin,
      action: 'flow',
      format: 'json',
      submodule: 'view-topiclist',
      page: title,
      vtlsortby: 'newest'
    }
      , 'POST')

    if (response.flow && response.flow['view-topiclist'] && response.flow['view-topiclist'].result.topiclist.revisions) {
      const a = groupBy(response.flow['view-topiclist'].result.topiclist.revisions, function (n) {
        return n.workflowId;
      });
      return a;
    }

  }

  public handleSendRep = (Topic: any, replyTo: any, content: any, title: any) => {
    return this.replyTopic(Topic, replyTo, content).then((responseReplyTopic) => {
      if (responseReplyTopic === 'ok') {
        return this.viewTopicList(title).then((responseViewTopicList) => {
          return responseViewTopicList;
        });
      }
    });
  }

  public async editPost(articleTitle: any, data: any, postId: any, revision: any) {
    const login = await this.getToken('csrf');
    this.callAxios({
      origin: config.origin,
      action: 'flow',
      submodule: 'edit-post',
      page: articleTitle,// flowV[0].articleTitle,
      epcontent: data,
      epformat: 'html',
      eppostId: postId,
      epprev_revision: revision,
      format: 'json',
      token: login
    }, 'POST')

  }

  public async LogOut() {
    const logintoken = await this.getToken('csrf');
    let data = {
      origin: config.origin,
      action: 'logout',
      format: 'json',
      token: logintoken
    }
    await this.callAxios(data, 'POST')
  }

  public async makeFollowing(isWatched = 'yes', userName: string, pass = '') {
    const watchToken = await this.getToken('watch');
    let data = {
      origin: config.origin,
      action: 'userswatch',
      user: userName,
      format: 'json',
      token: watchToken,
      watch: isWatched,
    }
    this.callAxios(data, 'POST')
  }

  public async setPageToLearn(userName: string, bookNmae: string, pageName: string, shasmehulak: string, email = 'e') {

    let data = {
      origin: config.origin,
      action: 'setPageToLearn',
      user_name: userName,
      bookNmae: bookNmae,
      pageName: pageName,
      shasmehulak: shasmehulak,
      format: 'json',
      email: email,
    }
    return this.callAxios(data, 'POST')

  }

  public async getMasechetLearn(bookNmae: string, shasMachulak: string) {
    let data = {
      origin: config.origin,
      action: 'getMasechetLearn',
      bookNmae: bookNmae,
      format: 'json',
      maxage: Math.random(),
      shasMachulak: shasMachulak
    }
    return this.callAxios(data, 'POST')


  }

  public async makeLike(postid: any) {
    const logintoken = await this.getToken('csrf');
    let data = {
      origin: config.origin,
      action: 'flowthank',
      format: 'json',
      token: logintoken,
      postid: postid
    }
    this.callAxios(data, 'POST')
  }

  public async makeRead() {
    const tokencsrf = await this.getToken('csrf');
    let data = {
      origin: config.origin,
      action: 'echomarkread',
      format: 'json',
      token: tokencsrf,
      all: 1
    }
    this.callAxios(data, 'POST')
  }

  public AllUsers = (auprefix = '', auuser = '', offset = 0) => {
    let data = {
      origin: config.origin,
      action: 'query',
      format: 'json',
      list: 'allusers',
      augroup: 'רבני-שיתופתא|משתמש_שאושר|משתמשת_שאושר',
      aulimit: 200,
      auprefix: auprefix,
      aufrom: auuser === '' ? null : auuser,
      maxage: Math.random(),
      auoffset: offset
    }
    return this.callAxios(data, 'POST').then((response) => {
      return { allusers: response.query.allusers, continue: response.continue }
    })
  }

  public getUserInfo() {

    let data = {
      origin: config.origin,
      action: 'query',
      format: 'json',
      meta: 'userinfo'
    }
    return this.callAxios(data, 'POST').then((respons) => {
      return respons.query
    })
  }

  public async getNotifications(prop: any, filter: any, notgroupbysection: any) {
    if (notgroupbysection === '') {
      let data = {
        origin: config.origin,
        action: 'query',
        format: 'json',
        meta: 'notifications',
        notprop: prop,//'count|list',
        notfilter: filter,//'!read',
        notmessageunreadfirst: 1,
        notunreadfirst: 1,
        notformat: 'model',
        notlimit: 1
      }
      return this.callAxios(data, 'POST').then((respons) => {
        return respons.query
      })
    }
    if (notgroupbysection !== '') {
      let data = {
        origin: config.origin,
        action: 'query',
        format: 'json',
        meta: 'notifications',
        notprop: prop,
        notfilter: filter,
        notmessageunreadfirst: 1,
        notunreadfirst: 1,
        notgroupbysection: notgroupbysection
      }
      return this.callAxios(data, 'POST').then((respons) => {
        return respons.query
      })
    }

  }

  public getFlowinfo(topicIds: any, items2: any, workflowId: any) {

    let flilist: any;
    if (typeof topicIds === 'string') {
      flilist = topicIds;
    }
    else {
      flilist = topicIds.join('|');
    }
    let data = {
      origin: config.origin,
      action: 'query',
      format: 'json',
      prop: 'flowinfo',
      titles: `נושא:Vovt0vkuavo156jg`,
      flilist: flilist,
    }
    return this.callAxios(data, 'POST').then((res) => { return res.query })
      .then((responseJSON1) => {
        var revisionsCount = responseJSON1;
        if (revisionsCount && revisionsCount.pages['6154']) {
          var children = revisionsCount.pages['6154'].flowinfo.flow.aaa.concat(items2)
          return { workflowId: workflowId, items2: children }
        }
      });
  }

  public getViewTopicPage(workflowId: any) {
    let data = {
      origin: config.origin,
      action: 'flow',
      format: 'json',
      submodule: 'view-topic',
      page: `Topic:` + workflowId,
    }

    return this.callAxios(data, 'POST')
  }

  public getViewTopic(offset: any, type: any, items2: any, auprefix = '') {
    let arr: any
    let topicIds: any;
    let data = {
      origin: config.origin,
      action: 'flow',
      format: 'json',
      submodule: 'view-topic',
      page: `נושא:Vovt0vkuavo156jg`,
      vtlist: offset,
      vttype: type,
      vtseferLen: 1,
      seferName: 'Yevamot',
      vtauprefix: auprefix
    }
    if (arr === undefined)
      arr = [];
    if (topicIds === undefined)
      topicIds = [];
    return this.callAxios(data, 'POST').then((respons) => {
      var topict = respons;

      if (topict && topict.flow)
        for (var prop in topict.flow['view-topic'].result) {

          if (!Number.isNaN(Number(prop))) {
            if (topict && topict.flow['view-topic'] && topict.flow['view-topic'].result)
              arr.push(topict.flow['view-topic'].result[prop].topic);
            if (topict && topict.flow['view-topic'] && topict.flow['view-topic'].result && topict.flow['view-topic'].result[prop])
              for (var prop1 in topict.flow['view-topic'].result[prop].topic.revisions) {
                topicIds.push(prop1)
              }
          }
        }
      const workflowId = groupBy(arr, function (n) {
        return n.workflowId;
      });
      return this.getFlowinfo(topicIds, items2, workflowId);
    })
  }
  public getAllViewTopic(offset: any, type: any, items2: any, auprefix = '') {

    let data = {
      origin: config.origin,
      action: 'flow',
      format: 'json',
      submodule: 'view-topic',
      page: `נושא:Vovt0vkuavo156jg`,
      vtlist: offset,
      vttype: type,
      vtseferLen: 1,
      seferName: 'Yevamot',
      vtauprefix: auprefix,
      vtall: 'all'
    }

    return this.callAxios(data, 'POST').then((respons) => {
      return respons
    })
  }
  public getViewTopicCount(auprefix: any) {

    let data = {
      origin: config.origin,
      action: 'flow',
      format: 'json',
      submodule: 'view-topic',
      page: `נושא:Vovt0vkuavo156jg`,
      vtlist: '1',
      vttype: '',
      vtseferLen: 1,
      seferName: 'Yevamot',
      vtauprefix: auprefix,
      vtcount: '1',
    }

    return this.callAxios(data, 'POST').then((respons) => {
      var topict = respons;

      return topict.flow['view-topic'].result[0].count;
    })
  }
  public getOneTopic(uuid: any) {
    let arr: any
    let topicIds: any;
    let data = {
      origin: config.origin,
      action: 'flow',
      format: 'json',
      submodule: 'view-topic',
      page: `נושא:Vovt0vkuavo156jg`,
      vtlist: 1,
      vtuuid: uuid
    }

    if (arr === undefined)
      arr = [];
    if (topicIds === undefined)
      topicIds = [];
    return this.callAxios(data, 'POST').then((respons) => {
      var topict = respons;

      if (topict && topict.flow)
        for (var prop in topict.flow['view-topic'].result) {

          if (!Number.isNaN(Number(prop))) {
            if (topict && topict.flow['view-topic'] && topict.flow['view-topic'].result)
              arr.push(topict.flow['view-topic'].result[prop].topic);
            if (topict && topict.flow['view-topic'] && topict.flow['view-topic'].result && topict.flow['view-topic'].result[prop])
              for (var prop1 in topict.flow['view-topic'].result[prop].topic.revisions) {
                topicIds.push(prop1)
              }
          }
        }
      const workflowId = groupBy(arr, function (n) {
        return n.workflowId;
      });
      return this.getFlowinfo(topicIds, uuid, workflowId);
    })
  }

  public getPage(page: any) {
    const res = $.ajax({
      url: config.pathApi,
      async: false,
      method: 'GET',
      data: {
        origin: config.origin,
        action: 'parse',
        format: 'json',
        page: page,
        prop: 'text',
      },
      xhrFields: {
        withCredentials: true
      },
      dataType: 'json'
    }).responseJSON;
    if (res && res.parse && res.parse.text['*'])
      return res.parse.text['*']
    else
      return ''
  }

  public handleSend(pageTitle: any, selectedUnitText: any, body: any, pageHidushim: any) {
    return this.createNewTopic(pageTitle, body).then((res) => {
      return res.flow['new-topic']
    }).then((response2) => {
      if (response2.status === 'ok') {
        return this.viewTopicList(pageTitle).then((respon) => {
          if (selectedUnitText)
            pageHidushim[selectedUnitText] = respon;
          else {
            pageHidushim[0] = respon;
          }
          if (response2 && response2['committed'] && response2['committed']['topiclist'] && response2['committed']['topiclist']['post-id'])
            return { pageHidushim: pageHidushim, workflow: response2['committed']['topiclist']['post-id'] };
        });
      }
    });
  }

  public async createNewTopic(pageTitle: any, body: any, timestampWrite = 1640613532000) {
    const csrftoken = await this.getToken('csrf');

    let data = {
      origin: config.origin,
      action: 'flow',
      format: 'json',
      submodule: 'new-topic',
      page: pageTitle,
      nttopic: body,
      ntcontent: body,
      token: csrftoken
    }
    return this.callAxios(data, 'POST')
  }
  public async sendEmail(target: any, subject: any, text: any) {
    const csrftoken = await this.getToken('csrf');

    let data = {
      origin: config.origin,
      action: "emailuser",
      target: target,
      subject: subject,
      format: 'json',
      text: text,
      token: csrftoken
    }
    return this.callAxios(data, 'POST')
  }

  public async handleClickRep(workflowId: any, postId: any, replys: any) {
    var content = replys.get(postId);
    return this.replyTopic(workflowId, postId, content).then((respons) => {
      if (respons === 'ok') {
        replys.set(postId, '');
        return this.getViewTopicPage(workflowId).then((res) => {
          return res.flow['view-topic'].result.topic.revisions
        })
      }
    })
  }

  public async changeauthenticationdata(ExamplePassword: any, rePassword: any) {
    const csrftoken = await this.getToken('csrf');
    let data = {
      origin: config.origin,
      action: 'changeauthenticationdata',
      // format: 'json',
      password: ExamplePassword,
      retype: rePassword,
      changeauthtoken: csrftoken,
      changeauthrequest: 'MediaWiki%5CAuth%5CTemporaryPasswordAuthenticationRequest',
    }
    return this.callAxios(data, 'POST')
  }

  public async reSetPassword(userName: any) {
    const csrftoken = await this.getToken('csrf');
    let data = {
      origin: config.origin,
      action: 'resetpassword',
      format: 'json',
      user: userName,
      token: csrftoken,
    }
    return this.callAxios(data, 'POST').then((res) => {
      return res
    })
  }

  public async createAccount(
    email: any,
    firstName: any,
    lastName: any,
    createaccounttoken: any,
    password: any,
    rePassword: any,
    tokenCAPTCHAID: any,
    tokenCAPTCHA: any,
    phon: any,
    gender: any,
    nickname: any
  ) {

    let data = {
      email: email,
      action: 'createaccount',
      username: firstName + ' ' + lastName,
      format: 'json',
      origin: config.origin,
      createtoken: createaccounttoken,
      password: password,
      retype: rePassword,
      createreturnurl: config.loginreturnurl,
      captchaId: tokenCAPTCHAID,
      captchaWord: tokenCAPTCHA,
      realname: phon + '`' + gender + '`' + nickname
    }
    return this.callAxios(data, 'POST').then((res) => {
      return res.createaccount.status
    })
  }

  public async CAPTCHA() {
    let data = {
      action: 'query',
      meta: 'authmanagerinfo|tokens',
      format: 'json',
      origin: config.origin,
      amirequestsfor: 'create',
      type: 'createaccount',
    }
    return this.callAxios(data, 'POST').then((res) => {
      return res.query
    })
  }
  public async nameIsExist(ususers: any) {
    let data = {
      action: 'query',
      format: 'json',
      list: 'users',
      ususers: ususers,
      usprop: 'cancreate',
      formatversion: 2,
      errorformat: 'html',
      errorsuselocal: true,
      uselang: 'he',
      origin: config.origin,
    }
    return this.callAxios(data, 'POST').then((res) => {
      return res.query
    })
  }
}
export default new WikiService();
