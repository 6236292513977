import React from "react";
import "./OpenNavPage.css";
import OpenNav from "../OpenNavCopy/OpenNavCopy";

const OpenNavPage = () => {
  
  return (
    <>
      <div className="OpenNavPage-background">
        <div className="OpenNavPage-background-sub">
          <div className="OpenNavPage-background-sub-sub">
            <OpenNav />
          </div>
        </div>
      </div>
    </>)
}

export default OpenNavPage;