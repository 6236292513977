import React, { useEffect, useLayoutEffect, useState } from "react";
import { Breadcrumbs } from '@mui/material';
import config from "../../services/configService";
import { parseBookPageToHebrew } from "../../utils/menuUtils";

import Axios from "axios";
import "./OpenNavCopy.css";
import wikiService from "../../services/wikiService";
import OpenNavList from "../OpenNavListcopy/OpenNavListcopy";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMenuView,
  clearMenuView,
  closeMenu,
  setMenuBook,
  setCurrentEvents,
  setNotificationsAll,
} from "../../store/actions/viewActions";
import { ReactComponent as SearchCircle } from "../../svg/searchCircle.svg";
import { ReactComponent as SquareLine } from "../../svg/square-line.svg";
import { ReactComponent as SquareLineDown } from "../../svg/square-line-down.svg";
import { getPageData, } from "../../store/actions/pageActions";
import {
  getAllBooks,
  getBookName,
  getCurrentBook,
  getHeTitleBook,
  getIsMenuOpen,
  getMenuAllPages,
  getMenuChaptersView,
  getMenuView,
  getCategoryName,
  getIsLoading,
  getIsOpenNavPage,
  getContentCountsMenu,
  getPageNumberFromUrl
} from "../../store/selectors/selectors";
import isMobile from "ismobilejs";
import { list } from "../../utils/booksAndChapters";
import { ThunkDispatch } from "@reduxjs/toolkit";
import history from "../../history/history";
import {
  getAllCategory,
  getAllHeCategory,
  getAllHeCategoryName,
  getAllCategoryName,
  isPentateuch,
  isPentateuchIncludes,
  getHeTitle
} from "../../utils/booksAndChapters2";

const OpenNav = () => {

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isMenuOpen = useSelector(getIsMenuOpen);
  const menuView = useSelector(getMenuView);
  const allBooksNames = useSelector(getAllBooks);
  const currentBook = useSelector(getCurrentBook);
  const currentHeBook = useSelector(getBookName);
  const chosenBookHeName = useSelector(getHeTitleBook);
  const chosenBookAllPages = useSelector(getMenuAllPages);
  const contentCounts = useSelector(getContentCountsMenu);
  const categoryName = useSelector(getCategoryName);
  const isLoading = useSelector(getIsLoading);
  const menuChaptersView = useSelector(getMenuChaptersView);
  const [valueSearch, setValueSearch] = useState('');
  const pageNumberFromUrl = useSelector(getPageNumberFromUrl);
  const isOpenNavPage = useSelector(getIsOpenNavPage);
  /*------------------------*/
  /*new version*/
  /*------------------------*/
  const [book, setBook] = useState('');
  const [bookBefor, setBookBefor] = useState<string[]>([]);
  const [contents, setContents] = useState<any[]>([]);




  const clearView = () => {
    setBookBefor([])
    setContents([])
    setBook('~')
    dispatch(clearMenuView());
  };

  const handleViewChange = (view: string, render = '') => {


    const AllHeCategoryName = getAllHeCategoryName(render, bookBefor);
    setBookBefor(AllHeCategoryName ? AllHeCategoryName.concat([render]) : [render])



    setBook(render);
    dispatch(changeMenuView(view));
  };


  const handleChooseBook = (heBook: string, bookName: string, bookChosen: any, seder: any = '') => {

    let pentateuch = isPentateuch(bookName)
    dispatch(setMenuBook(heBook, pentateuch, bookChosen, pentateuch === bookName ? '1' : '3'));

    const AllHeCategoryName = getAllHeCategoryName(heBook, bookBefor);

    if (AllHeCategoryName)
      setBookBefor(getAllHeCategoryName(heBook, bookBefor).concat([heBook]))
    else {
      setBookBefor([heBook])
    }
    setBook(heBook)
  };

  useLayoutEffect(() => {
    function scrollIntoSelectedPage() {
      if (menuView.includes("allbooks")) {
        const selectedBook = document.getElementsByClassName(
          "openNav-book-item-current"
        )[0];
        if (selectedBook) {
          setTimeout(() => {
            selectedBook.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 200);
        }
      }
      if (menuView.includes("bookChosen")) {
        const selectedPage = document.getElementById("selected-page");
        const selectedContainer = document.getElementById("openNav");
        const topPagesContainer = document.getElementsByClassName(
          "react-select-container"
        )[0];
        if (selectedPage && selectedContainer) {
          setTimeout(() => {
            selectedPage.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 200);
        } else if (!selectedPage && topPagesContainer) {
          setTimeout(() => {
            topPagesContainer.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 0);
        }
      }
    }
    scrollIntoSelectedPage();
  }, [menuView, isMenuOpen, chosenBookHeName]);


  useEffect(() => {


    if (!isLoading && currentBook && currentHeBook && categoryName !== null && (isMenuOpen || isOpenNavPage) && book === '') {
      let view = menuView;
      if (menuView === 'basic') {
        view = 'bookChosen';
      }
      var filteredArray = categoryName.filter(function (e: any, i: any) {
        if (e === 'Talmud') {
          return false
        }
        if (e === 'Bavli' && categoryName[2] && !categoryName[2].includes('Seder')) {
          return false
        }
        if (e === 'Yerushalmi' && categoryName[2] && !categoryName[2].includes('Seder')) {
          return false
        }

        if (e === 'Mishnah' && categoryName && !categoryName[1].includes('Seder'))
          return false
        return true
      })

      let AllHeCategoryName = getAllHeCategoryName(currentHeBook, filteredArray);

      if (AllHeCategoryName && AllHeCategoryName.length > 0) {
        setBookBefor(AllHeCategoryName.concat([currentHeBook]))
      }
      setBook(currentHeBook);
      if (menuChaptersView !== 'subPage')
        dispatch(setMenuBook(currentHeBook, currentBook, view, '3'));
    }

    wikiService.getNotifications('list', 'read|!read', '').then((res: any) => {
      if (res && res.notifications)
        dispatch(setNotificationsAll(res.notifications.list.reverse()));
    });
  }, [isLoading, dispatch, isMenuOpen]);



  const onChangeValue = (e: any) => {
    setValueSearch(e.target.value)
  }


  const onSubmitValue = () => {

    Axios.get(`${config.shitufta_api_url}name/${encodeURIComponent(valueSearch.replace("'", ''))}`).
      then((res) => {

        if (res.data.is_book === true) {
          if (list.includes(res.data.book.split(' ').join('_')) || ["Genesis", "Exodus", "Numbers", "Leviticus", "Deuteronomy"].includes(res.data.book.split(' ').join('_'))) {
            if (res.data.sectionNames[0] === 'Siman' || res.data.sectionNames[1] === 'Seif')
              handleChooseBook(res.data.completions[0], res.data.book, 'bookChosenSul')
            else if (res.data.sectionNames[0] === 'Chapter' || res.data.sectionNames[1] === 'Verse')
              handleChooseBook(res.data.completions[0], res.data.book, 'bookChosenTea')
            else
              handleChooseBook(res.data.completions[0], res.data.book, 'bookChosen')
          }
        }
        else if (res.data.book && ["Genesis", "Exodus", "Numbers", "Leviticus", "Deuteronomy"].includes(res.data.book.split(' ').join('_'))) {
          dispatch(closeMenu());
          dispatch(getPageData(res.data.book, res.data.ref.split(' ')[1].split('-')[0].replace(':', '.')));
          dispatch(setCurrentEvents(''));
        }
        else if (res.data.book && list.includes(res.data.book.split(' ').join('_'))) {
          dispatch(closeMenu());
          dispatch(getPageData(res.data.book, res.data.url.split('.')[1] + (res.data.url.split('.').length > 2 ? '.' + res.data.url.split('.')[2] : '')));
          dispatch(setCurrentEvents(''));
        }
      });
    // })
  }

  const basicNemuView = () => {
    const buttons = getAllCategory();

    return buttons.map((button: any) => {
      return (
        <div key={button}
          className={!(isMobile().phone || isMobile().tablet) ? ` openNav-choose-subject` : 'openNav-choose-subject-mobile'}
          onClick={() => {
            handleViewChange('allbooks', button)
          }}
        >
          {button}
        </div>
      )
    })
  }


  /*const remuveHeTitle = (heTitle: any) => {
    if (heTitle)
      return [
        'עין אי"ה',
        'לנבוכי הדור',
        'מאמר הדור',
        'שו"ת במראה הבזק',
        'תשובות לקציני דת (אנגלית)',
        'מרכז לינדנבאום',
        'קול דודי דופק',
        'במצולות ספר יונה',
        'גן נעול',
        'אורות',
        'ענקי הרוח שלנו',
        'מאמר מזכה הרבים',
        'מילון קליין',
        'מילון יסטרוב',
        'אוצר לעזי רש"י',
        'הפלאה שבערכין על ספר',
        'פירושים מודרניים',
        'דברי שלום ואמת',
        'בעיות הלכתיות עכשוויות',
        'פדיון הרלוונטיות',
        'מדרשי פילון',
        'תשובות בעת מלחמה (אנגלית)',
        'הערות שוליים',
        "מלחמות השם יחיא קאפח"
      ].filter(e => heTitle.includes(e)).length === 0;
    else
      return false
  }*/

  const getClassNameBookItem = (current = false) => {
    if (current) {
      return `openNav-book-item x openNav-book-item-current ` + ((isMobile().phone || isMobile().tablet) ? ' mobile-1' : '')
    }
    else {
      return `openNav-book-item` + ((isMobile().phone || isMobile().tablet) ? ' mobile-1' : '')
    }
  }



  const getAllCategoryBySeder: any = (book_: any) => {

    if (book_.heTitle && book_.title)
      return (
        <li
          key={book_.title}
          className={getClassNameBookItem(currentBook === book_.title)}
          onClick={() => {
            handleChooseBook(book_.heTitle, book_.title, 'bookChosen')
          }
          }
        >
          {getHeTitle(book_.heTitle, bookBefor)}
        </li>
      );
    else {
      return (
        <div
          className="openNav-allbooks-list-container"
          key={book_.category}
        >
          {book_.contents && <ul className={!(isMobile().phone || isMobile().tablet) ? `openNav-allbooks-list` : "openNav-allbooks-list-mobile-1"}>

            {book_.contents.map((book: any) => {
              return getAllCategoryBySeder(book)
            })}
          </ul>}
        </div>
      )
    }
  }

  const classNameListContainer = (category: any) => {
    if (category)
      return "openNav-allbooks-list-container"
    else {
      return ''
    }
  }


  const allbooksNemuView = () => {

    const contentsFromGetAllHeCategory = contents.length > 0 ? contents : getAllHeCategory(book, bookBefor)?.contents;

    if (contentsFromGetAllHeCategory && contentsFromGetAllHeCategory[0]) {
      return (
        <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-allbooks-container" : 'openNav-allbooks-container-mobile-1'}>
          <ul className={!(isMobile().phone || isMobile().tablet) ? `openNav-allbooks-list` : "openNav-allbooks-list-mobile-1"}>
            {allBooksNames && contentsFromGetAllHeCategory.map((seder: any) => {

              if (seder.title) {
                return getAllCategoryBySeder(seder)
              }
              //
              return (<li
                key={seder.category}
                className={getClassNameBookItem(currentBook === seder.category)}
                onClick={() => {
                  if (seder.contents) {
                    setContents(seder.contents)
                  }

                  setBookBefor(bookBefor.concat([seder.heCategory]));
                  setBook(seder.heCategory)
                }
                }
              >
                {getHeTitle(seder.heCategory, bookBefor)}
              </li>)

            })}

          </ul>
          <div
            className={
              !(isMobile().phone || isMobile().tablet) ?
                "openNav-main-container-empty" :
                "openNav-main-container-empty"
            }
          ></div>
        </div>
      )

    }
    if (contentsFromGetAllHeCategory && contentsFromGetAllHeCategory[0] && contentsFromGetAllHeCategory[0].title) {

      return (
        <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-allbooks-container" : 'openNav-allbooks-container-mobile-1'}>
          <ul className={!(isMobile().phone || isMobile().tablet) ? `openNav-allbooks-list` : "openNav-allbooks-list-mobile-1"}>
            {allBooksNames && contentsFromGetAllHeCategory.map((seder: any) => {
              return (
                <div
                  className={classNameListContainer(seder.title)}
                  key={seder.title}
                >
                  {getAllCategoryBySeder(seder)}
                </div>
              );
            })
            }
          </ul>
          <div
            className={
              !(isMobile().phone || isMobile().tablet) ?
                "openNav-main-container-empty" :
                "openNav-main-container-empty"
            }
          ></div>

        </div >
      )
    }
  }



  const BreadcrumbsOne = () => {
    let AllHeCategory = (bookBefor && bookBefor.length > 0 && bookBefor) || getAllHeCategoryName(book, bookBefor) || [];


    /* if (book !== '' && book !== '~')
       AllHeCategory.push(book)
 */
    if (isPentateuchIncludes(book) && menuChaptersView === 'subPage') {
      if (AllHeCategory && AllHeCategory.length > 0 && !(AllHeCategory[AllHeCategory.length - 1]).includes('פרק'))
        AllHeCategory.push('פרק ' + parseBookPageToHebrew(pageNumberFromUrl.split('.')[0], false))
    }

    return AllHeCategory.map((b: any, index: any) => {
      return (
        <div
          className={"openNav-top-item-background openNav-talmud " + ((isMobile().phone || isMobile().tablet) && 'openNav-top-item-background-mobile')}
          onClick={() => {

            if (menuChaptersView === 'subPage' && index === (AllHeCategory.length - 2)) {
              return handleViewChange('bookChosen', b)
            }
            else {
              setContents([])
              return handleViewChange('allbooks', b)
            }
          }}
        >
          {getHeTitle(b, bookBefor)}
        </div>
      )
    })
  }


  const getClassName = () => {
    if (currentHeBook === null && isMenuOpen && !(isMobile().phone || isMobile().tablet)) {
      return `header-openNav-home-page`
    }
    if (isMenuOpen && !(isMobile().phone || isMobile().tablet)) {
      return `header-openNav`
    }
    if (isMenuOpen && (isMobile().phone || isMobile().tablet)) {
      return `header-openNavD`
    }
    if (isOpenNavPage) {
      return ''
    }
    else {
      return "header-hidden"
    }
  }

  return (
    <div
      className={getClassName()}
      id={"openNav"}>
      {!(isMobile().phone || isMobile().tablet) && <div className='onclick-close'
        onClick={() => {
          history.push('?')
          dispatch(closeMenu())
        }} >
      </div>}
      {!isOpenNavPage && !(isMobile().phone || isMobile().tablet) &&
        <div className='sfarim-search'>
          <input className='sfarim-search-input'
            placeholder="חפש ספר"
            autoComplete={'off'}
            maxLength={50}
            type="search"
            onChange={onChangeValue}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                onSubmitValue()
              }
            }}
          >
          </input>
          <div
            className='sfarim-search-circle'
            onClick={onSubmitValue}
          >
            <SearchCircle style={{ top: 10, position: 'relative' }} />
          </div>

        </div>}

      <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-half-page-center" : "openNav-half-page-center-mobile"}>
        {!isOpenNavPage && <div className={!(isMobile().phone || isMobile().tablet) ? `header-openNav-top ${currentHeBook === null ? 'header-openNav-top-home-page' : ''}` : ''}>
          <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-text-wrapper" : 'openNav-text-wrapper'}>
            <Breadcrumbs aria-label="breadcrumb">
              <div
                className={"openNav-top-item-background " + ((isMobile().phone || isMobile().tablet) && 'openNav-top-item-background-mobile')}
                onClick={() =>

                  clearView()}
              >
                {'אוצר ספרים'}
              </div>

              {BreadcrumbsOne()}


            </Breadcrumbs>
          </div>
        </div>}
        {!isOpenNavPage && !(isMobile().phone || isMobile().tablet) && (menuView === 'basic') &&
          <div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon' : 'header-open-nav-icon-mobile'}>
            <SquareLineDown />
            {<div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text' : 'header-open-nav-icon-text-mobile'}>
              {'אוצר הספרים'}
            </div>}
            {<div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text-2' : 'header-open-nav-icon-text-mobile'}>
              <br />
            </div>}
          </div>}
        {!isOpenNavPage && !(isMobile().phone || isMobile().tablet) && (menuView === 'bookChosenMish' || menuView === 'bookChosenHalCom' || menuView === 'bookChosenHalCom' || menuView === 'bookChosenRam' || menuView === 'bookChosen' || menuView === 'bookChosenTea' || menuView === 'bookChosenSul' || menuView === 'bookChosenProphets') &&
          <div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon' : 'header-open-nav-icon-mobile'}>
            {chosenBookHeName && (menuView === 'bookChosenMish' || menuView === 'bookChosenHalCom' || menuView === 'bookChosenHalCom' || menuView === 'bookChosen' || menuView === 'bookChosenTea' || menuView === 'bookChosenSul' || menuView === 'bookChosenProphets') && <SquareLineDown />}
            {chosenBookHeName && (menuView === 'bookChosenRam') && <SquareLine />}
            {chosenBookHeName && <span className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text-empty' : 'header-open-nav-icon-text-mobile'}>
              {book ? getHeTitle(book, bookBefor) : getHeTitle(menuChaptersView, bookBefor)}
            </span>}
            {<div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text-2' : 'header-open-nav-icon-text-mobile'}>
              <br />
            </div>}
          </div>}
        {!isOpenNavPage && !(isMobile().phone || isMobile().tablet) &&
          (
            menuView === 'allbooks'
          ) &&
          <div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon' : 'header-open-nav-icon-mobile'}>
            {(
              menuView === 'allbooks'
            ) && <SquareLineDown />}
            {<div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text' : 'header-open-nav-icon-text-mobile'}>
              {book}
              <br />
              {false && <span style={{ fontSize: 27, position: 'relative', top: -50 }}>{'מהדורת רוזנבוים'}</span>}
            </div>}
          </div>}
        <div
          className={!(isMobile().phone || isMobile().tablet) ? "openNav-main-container" : "openNav-main-container-mobile"}>
          {(menuView === "basic") && (
            <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-choose-subject-container" : "openNav-choose-subject-container-mobile"}>
              {basicNemuView()}
              <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-main-container-empty" : "openNav-main-container-empty-mobile"}></div>
            </div>
          )}

          {menuView === "allbooks" && allbooksNemuView()}

          {menuView === "bookChosen" &&
            <OpenNavList
              menuView={menuView}
              chosenBookAllPages={chosenBookAllPages}
              pageLocal={book}
              contentCounts={contentCounts}
              allCategoryName={getAllCategoryName(book, bookBefor)}
              first={1}
              bookBefor={bookBefor}
            ></OpenNavList>}

        </div>
      </div>
    </div >
  );
};

export default OpenNav;