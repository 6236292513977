import { ThunkDispatch } from "redux-thunk";
import * as Types from "../types/types";
import menuService from "../../services/menuService";
import wikiService from "../../services/wikiService";
import {
  isPentateuchIncludes
} from "../../utils/booksAndChapters2";
export const changeMenuView = (view: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_MENU_VIEW, payload: view } as any);
  };
};

export const changeMenuViewYoma = (view: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {

    dispatch({ type: Types.SET_MENU_VIEW_YOMA, payload: view } as any);
  };
};


export const setMenuBook = (heBook: string, book: string, bookChosen: any = '', seder: string = '') => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    //TODO Check if needed
    const bookNew = book.split(",_")[0].split(" ").join("_");
    let sederTemp = '';
    if (seder === 'הלכות פסח') {
      seder = '';
      sederTemp = 'הלכות פסח'
    }


    const data = await menuService.getBookPagesAndChapters(bookNew,'',book);

    if (data === undefined) {
      dispatch({
        type: Types.SET_ERROR,
        payload: "Could Not Get Page Chapters And Pages"
      });
    } else {
      dispatch({ type: Types.SET_MENU_CHAPTERS, payload: data[0] });
      dispatch({ type: Types.SET_MENU_ALLPAGES, payload: data[1] });
      dispatch({ type: Types.SET_MENU_VIEW, payload: bookChosen });

      dispatch({ type: Types.SET_MENU_BOOK, payload: bookNew });
      if (data[5] && data[5][0])
        dispatch({ type: Types.SET_MENU_HE_BOOK, payload: data[5] });
      dispatch({ type: Types.SET_CONTENT_COUNTS_MENU, payload: data[3] });
      dispatch({ type: Types.SET_SECTION_NAMES, payload: data[4] });
      if (sederTemp === 'הלכות פסח')
        dispatch({ type: Types.SET_CHAPTERS_VIEW, payload: sederTemp });
    }
  };
};
export const setMenuBookYoma = (heBook: string, book: string, bookChosen: any = '', seder: any = '', projectNameShasmehulak: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    //TODO Check if needed
    book = book.split(" ").join("_");

    if (!book)
      book = 'Yoma';
    const data = await menuService.getBookPagesAndChapters(book, seder);



    if (data === undefined) {
      dispatch({
        type: Types.SET_ERROR,
        payload: "Could Not Get Page Chapters And Pages"
      });
    } else {

      wikiService.getMasechetLearn('all', projectNameShasmehulak).then(function (result) {

        setBookListAll(result.getMasechetLearn)
        dispatch({ type: Types.SET_BOOK_LIST_ALL, payload: result.getMasechetLearn });
      });
      dispatch({ type: Types.SET_MENU_CHAPTERS_YOMA, payload: data[0] });
      dispatch({ type: Types.SET_MENU_ALLPAGES_YOMA, payload: data[1] });

    }
    dispatch({ type: Types.SET_MENU_VIEW_YOMA, payload: bookChosen });
    dispatch({ type: Types.SET_MENU_BOOK_YOMA, payload: book });
    dispatch({ type: Types.SET_MENU_HE_BOOK_YOMA, payload: heBook });
  };
};


export const setMenuBookEmpty = () => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {


    menuService.getUserData().then((data) => {
      if (data === undefined) {
        dispatch({
          type: Types.SET_ERROR,
          payload: "Could Not Get Page Chapters And Pages"
        });
      } else {


        dispatch({ type: Types.SET_ALL_BOOKS, payload: data[0].data.basic });
        dispatch({ type: Types.SET_MENU_BOOK, payload: '' });
        dispatch({ type: Types.SET_MENU_HE_BOOK, payload: '' });
        if (data[2] && data[2].userinfo && data[2].userinfo.id !== 0) {
          dispatch({ type: Types.SET_USER_NAME, payload: data[2].userinfo.name });
          dispatch({ type: Types.SET_USER_ID, payload: data[2].userinfo.id });
          dispatch({ type: Types.SET_USER_FOLLOWERS, payload: data[2].userinfo.follow });
        }
        else {
          dispatch({ type: Types.SET_USER_NAME, payload: 0 });
          dispatch({ type: Types.SET_USER_ID, payload: 0 });
        }

        if (data[1] && data[1].notifications)
          dispatch({ type: Types.SET_NOTIFICATIONS_ALL, payload: data[1].notifications.list.reverse() });
        if (data[4] && data[4].allusers && data[4].allusers[0] && data[4].allusers[0].WatchedPage)
          dispatch({ type: Types.SET_USER_PAGE, payload: data[4].allusers[0].WatchedPage });

      }
    });
  };
};

export const clearMenuView = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.CLEAR_MENU_VIEW });
  };
};


export const closeMenu = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    //making scroll of body good again.
    document.body.style.overflow = "";
    dispatch({ type: Types.CLOSE_MENU });
  };
};
export const openMenu = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    // making scroll of body hidden to prevent extra scroll when menu open
    document.body.style.overflow = "hidden";
    dispatch({ type: Types.OPEN_MENU });
  };
};
export const closeMenuYoma = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    //making scroll of body good again.
    document.body.style.overflow = "";
    dispatch({ type: Types.CLOSE_MENU_YOMA });
  };
};
export const openMenuYoma = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    // making scroll of body hidden to prevent extra scroll when menu open
    document.body.style.overflow = "hidden";
    dispatch({ type: Types.OPEN_MENU_YOMA });
  };
};

export const closeMenu1 = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    //making scroll of body good again.
    document.body.style.overflow = "";
    dispatch({ type: Types.CLOSE_MENU1 });
  };
};


export const setOpenPid = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    // making scroll of body hidden to prevent extra scroll when menu open
    document.body.style.overflow = "hidden";
    dispatch({ type: Types.OPEN_PID });
  };
};

export const setclosePid = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    //making scroll of body good again.
    document.body.style.overflow = "";
    dispatch({ type: Types.CLOSE_PID } as any);
  };
};

export const setOpenPid1 = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    // making scroll of body hidden to prevent extra scroll when menu open
    //  document.body.style.overflow = "hidden";
    dispatch({ type: Types.OPEN_PID1 });
  };
};



export const setclosePid1 = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    //making scroll of body good again.
    document.body.style.overflow = "";
    dispatch(setMyTopic(''));
    dispatch({ type: Types.CLOSE_PID1 });
  };
};

export const setTopicUuid = (uuid: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_TOPIC_UUID,
      payload: uuid
    });
  };
};
export const setUsers = (usersFeed: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_USERS_FEED,
      payload: usersFeed
    });
  };
};
export const setRecentchanges = (recentchanges: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_RECENT_CHANGES,
      payload: recentchanges
    });
  };
};




export const setclosePid2 = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    //making scroll of body good again.
    document.body.style.overflow = "";
    dispatch({ type: Types.CLOSE_PID2 });
  };
};

export const setMenuChaptersView = (view: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_CHAPTERS_VIEW, payload: view } as any);
  };
};

export const setMenuChaptersViewYoma = (view: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_CHAPTERS_VIEW_YOMA, payload: view } as any);
  };
};

export const setIsUserProfile = (view: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_USER_PROFILE, payload: view } as any);
  };
};
export const setIsUserProfileOpen = (view: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_USER_PROFILE_OPEN, payload: view } as any);
  };
};

export const setIsOpenNavPage = (view: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_IS_OPEN_NAV_PAGE, payload: view } as any);
  };
};

export const setIsOpenSearch = (view: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_IS_OPEN_SEARCH, payload: view } as any);
  };
};

export const setIsArticles = (view: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.IS_ARTICLES, payload: view } as any);
  };
};








export const setIsOnLineParty = (view: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    // making scroll of body hidden to prevent extra scroll when menu open
    document.body.style.overflow = "hidden";
    dispatch({ type: Types.SET_ON_LINE_PARTY, payload: view } as any);
  };
};


export const setNotificationsAll = (view: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    // making scroll of body hidden to prevent extra scroll when menu open
    dispatch({ type: Types.SET_NOTIFICATIONS_ALL, payload: view });
  };
};
export const setNotificationsOpen = (view: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    // making scroll of body hidden to prevent extra scroll when menu open
    dispatch({ type: Types.SET_NOTIFICATIONS_OPEN, payload: view } as any);
  };
};
export const setEmailOpen = (view: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    // making scroll of body hidden to prevent extra scroll when menu open
    dispatch({ type: Types.SET_EMAIL_OPEN, payload: view } as any);
  };
};

export const setPageHidushim = (view: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    // making scroll of body hidden to prevent extra scroll when menu open
    dispatch({ type: Types.PAGE_HIDUSHIM, payload: view } as any);
  };
};



export const setUserProfileView = (view: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_USER_PROFILE_VIEW, payload: view } as any);
  };
};

export const setIsTraile = (view: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_RTAILE, payload: view } as any);
  };
};
export const setBookListAll = (view: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_BOOK_LIST_ALL, payload: view } as any);
  };
};
export const setBookYoma = (view: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_BOOK_YOMA, payload: view } as any);
  };
};
export const setPageYoma = (view: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_PAGE_YOMA, payload: view } as any);
  };
};
export const setUserYoma = (view: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_USER_YOMA, payload: view } as any);
  };
};
export const setMyTopic = (view: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.MY_TOPICS, payload: view } as any);
  };
};
export const setRegistration = (view: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_REGISTRATION, payload: view } as any);
  };
};
export const setCurrentEvents = (view: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_CURRENT_EVENTS, payload: view } as any);
  };
};
export const setProjectNameShasmehulak = (view: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.PROJECT_NAME_SHAS_MEHULAK, payload: view } as any);
  };
};
export const setIsIfame = (toggle: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_IS_IFRAME,
      payload: toggle
    });
  };
};
export const setIsOpenTemplates = (toggle: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_IS_OPEN_TEMPLATES,
      payload: toggle
    });
  };
};
export const setCkEdittorContent = (toggle: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.CKEDITTOR_CONTENT,
      payload: toggle
    });
  };
};