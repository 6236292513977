import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./BookPage.css";
import { getKeyByValue, getLetterByNumber, hebDec, getGmaraBigWord } from "../../utils/menuUtils";
import { getMasoretHshas } from "../../utils/dataUtils";
import WellcomeMoblie from "../wellcomeMoblie/wellcomeMoblie";
import FormPage from "../FormPage/FormPage";
import BlockAnnonimus from "../BlockAnnonimus/BlockAnnonimus";
import Templates from "../Templates/Templates";
import Sfarim from "../Sfarim/Sfarim";
import HeaderMoblie from "../HeaderMoblie/HeaderMoblie";
import OpenNavPage from "../OpenNavPage/OpenNavPage";
import Hidushim from "../Hidushim/Hidushim";
import {
  setCommentaryselectChecked,
  setSelectedTextUnit,
  setTabOpenRight,
  setIsSfarimOn,
  setCommentaryToShow,
  getPageData,
} from "../../store/actions/pageActions";
import {
  setIsUserProfileOpen,
  setEmailOpen,
  setUserProfileView,
  setNotificationsOpen,
  setIsOpenNavPage,
  setIsOpenTemplates
} from "../../store/actions/viewActions";
import FooterMobile from "../FooterMobile/FooterMobile";
import {
  getAllcommentary,
  getIsLoading,
  getLayoutData,
  getIsUserProfile,
  getPageTitle,
  getSelectedUnitText,
  getMainText,
  getCommentarySefarim,
  getSelectedCommentaryFromUrl,
  getCommentaryToShowFromUrl,
  getSelectedUnitTextFromUrl,
  getUserName,
  getChapterTitle,
  getChapterNumberTitle,
  getBookName,
  getCurrentPageTitle,
  getSubPage,
  getIsMenuOpen,
  getIsMenuOpen1,
  getIsPidOpen2,
  getIsPidOpen1,
  getIsPidOpen,
  getCategoryName,
  getCurrentBook,
  getOpenTabsRight,
  getOpenTabsLeft,
  getUserProfileView,
  getIsUserProfileOpen,
  getEmailOpen,
  getNotificationsOpen,
  getNextPage,
  getPrevPage,
  getIsOpenNavPage,
  getSectionNames,
  getAlts,
  getIsOpenTemplate
} from "../../store/selectors/selectors";
import {
  augmentPageText,
  boldRashiTosafot,
  flatTextUnits,
  flatTextUnitsT,
  generateRenderUnits,
  getFirstWord,
  getRowTopMargin,
  getSpanStyle,
  groupBy
} from "../../utils/dataUtils";
import {
  IRenderData,
  IRenderTextUnit,
  IRenderUnit
} from "../../models/interfaces";
import { ReactComponent as NewGemaraLogo } from "../../svg/new-gemara-logo-Copy.svg";
import { ReactComponent as CloseSign } from "../../svg/group-11.svg";
import HeaderNav from "../HeaderNav/HeaderNav";
import { closeMenu, closeMenu1, setIsUserProfile, setclosePid, setclosePid1, setclosePid2 } from "../../store/actions/viewActions";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import isMobile from "ismobilejs";
import { ThunkDispatch } from "@reduxjs/toolkit";


const BookPage = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const userProfileView = useSelector(getUserProfileView);
  const pageText = useSelector(getMainText);
  const nextPage = useSelector(getNextPage);
  const commentary = useSelector(getAllcommentary);
  const selectedUnitText = useSelector(getSelectedUnitText);
  const renderWords = useSelector(getLayoutData);
  const isLoading = useSelector(getIsLoading);
  const isUserProfile = useSelector(getIsUserProfile);
  const isOpenNavPage = useSelector(getIsOpenNavPage);
  const isUserProfileOpen = useSelector(getIsUserProfileOpen);
  const pageTitle = useSelector(getPageTitle);
  const selectedTextUnit = useSelector(getSelectedUnitText);
  const commentarySefarim = useSelector(getCommentarySefarim);
  const selectedCommentaryFromUrl = useSelector(getSelectedCommentaryFromUrl);
  const commentaryToShowFromUrl = useSelector(getCommentaryToShowFromUrl);
  const selectedUnitTextFromUrl = useSelector(getSelectedUnitTextFromUrl);
  const chapterName = useSelector(getChapterTitle);
  const chapterNumberTitle = useSelector(getChapterNumberTitle);
  const selectedUserName = useSelector(getUserName);
  const bookName = useSelector(getBookName);
  const emailOpen = useSelector(getEmailOpen);
  const notificationsOpen = useSelector(getNotificationsOpen);
  const currentPage = useSelector(getCurrentPageTitle);
  const subpage = useSelector(getSubPage);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const isMenuOpen1 = useSelector(getIsMenuOpen1);
  const isPidOpen = useSelector(getIsPidOpen);
  const isPidOpen1 = useSelector(getIsPidOpen1);
  const isPidOpen2 = useSelector(getIsPidOpen2);
  const categoryName = useSelector(getCategoryName);
  const currentBook = useSelector(getCurrentBook);
  const tabsOpenedRight = useSelector(getOpenTabsRight);
  const tabsOpenedLeft = useSelector(getOpenTabsLeft);
  const [touchStartPageX, setTouchStartPageX] = useState(0);
  const [touchStartPageY, setTouchStartPageY] = useState(0);
  const prevPage = useSelector(getPrevPage);
  const sectionNames = useSelector(getSectionNames);
  const alts = useSelector(getAlts);
  const isOpenTemplate = useSelector(getIsOpenTemplate);

  const getSubPageDot = () => {
    let subpageDot;
    if (subpage === "a") {
      subpageDot = ".";
    } else if (subpage === "b") {
      subpageDot = ":";
    }
    return subpageDot;
  };


  useEffect(() => {

    function isItemFromUrlInCommentary(itemUrl: string) {
      if (itemUrl in commentarySefarim.kishurimDic) {
        return {
          [itemUrl]: commentarySefarim.kishurimDic[itemUrl]
        };
      }
      if (itemUrl in commentarySefarim.commentaryDic) {
        return {
          [itemUrl]: commentarySefarim.commentaryDic[itemUrl]
        };
      }
      if (itemUrl in commentarySefarim.extraBooksHe) {
        return {
          [itemUrl]: commentarySefarim.extraBooksHe[itemUrl]
        };
      }
      return "";
    }
    if (selectedCommentaryFromUrl && commentarySefarim) {
      let newSelectedCommentary: any = [];
      selectedCommentaryFromUrl.forEach((itemUrl: string) => {
        let checkUrlItem = isItemFromUrlInCommentary(itemUrl);
        if (checkUrlItem) {
          newSelectedCommentary.push(checkUrlItem);
        }
      });
      if (newSelectedCommentary.length > 0) {
        dispatch(setCommentaryselectChecked(newSelectedCommentary));
        dispatch(setTabOpenRight("mefarshim"));
        if (commentaryToShowFromUrl) {
          let checkUrlItem: any = "";
          newSelectedCommentary.forEach((item: any) => {
            if (commentaryToShowFromUrl in item) {
              checkUrlItem = item;
            }
          });
          if (checkUrlItem) {
            dispatch(setCommentaryToShow(commentaryToShowFromUrl));
          } else {
            dispatch(setCommentaryToShow(Object.keys(newSelectedCommentary[0])[0]));
          }
        } else {
          dispatch(
            setCommentaryToShow(Object.keys(newSelectedCommentary[0])[0])
          );
        }
        dispatch(setIsSfarimOn(true));
      }
    }
    if (selectedUnitTextFromUrl >= 0 || selectedUnitTextFromUrl !== null) {
      if (
        selectedUnitTextFromUrl >= 0 &&
        selectedUnitTextFromUrl < pageText.length
      ) {
        dispatch(setSelectedTextUnit(selectedUnitTextFromUrl));
      }
    }
  }, [
    selectedCommentaryFromUrl,
    commentarySefarim,
    commentaryToShowFromUrl,
    selectedUnitTextFromUrl,
    pageText,
    selectedUserName,
    pageTitle,
    dispatch,
  ]);


  useEffect(() => {
    const setHeightOfCredits = () => {
      if (renderWords) {
        let rashiTosafotHeightRow = 22;
        if ((isMobile().phone || isMobile().tablet)) {
          rashiTosafotHeightRow = 9;
        }


        let gmaraHeightRow = 26;

        if ((isMobile().phone || isMobile().tablet)) {
          gmaraHeightRow = 9;
        }
        let height = renderWords.tosafot.textUnits.length * rashiTosafotHeightRow;
        // let who = "tosafot";
        if (renderWords.rashi.textUnits.length * rashiTosafotHeightRow > height) {
          height = renderWords.rashi.textUnits.length * rashiTosafotHeightRow;
          //    who = "rashi";
        }
        if (renderWords.gmara.textUnits.length * gmaraHeightRow > height) {
          height = renderWords.gmara.textUnits.length * gmaraHeightRow;
          //     who = "gmara";
        }
        //added margin bottom
        height = height + 60;
      }
    };
    setHeightOfCredits();
  }, [renderWords, selectedUserName]);


  const unitTextClicked = (idx: any, event: any) => {
    event.stopPropagation();
    dispatch(closeMenu());
    dispatch(setSelectedTextUnit(idx));
    if (isUserProfile) {
      dispatch(setIsUserProfile(false));
    }
    if (isOpenNavPage) {
      dispatch(setIsOpenNavPage(false));
    }
    if (isUserProfileOpen) {
      dispatch(setIsUserProfileOpen(false));
    }
    if (emailOpen) {
      dispatch(setEmailOpen(false));
    }
    if (notificationsOpen) {
      dispatch(setNotificationsOpen(false));
    }
  };


  useLayoutEffect(() => {
    function scrollToTopMenu() {
      const selectedPage = document.getElementById("margin-main");
      if (selectedPage === null) {
        setTimeout(function () {
          scrollToTopMenu();
        }, 500);
      } else {
        selectedPage.scrollTo({
          top: 0
        });
      }
    }
    scrollToTopMenu();
  }, [pageText, selectedUnitTextFromUrl]);


  const handleCloseMenu = () => {
    if (isMenuOpen)
      dispatch(closeMenu());
    if (isMenuOpen1)
      dispatch(closeMenu1());
    if (isPidOpen)
      dispatch(setclosePid());
    if (isPidOpen1)
      dispatch(setclosePid1());
    if (isPidOpen2)
      dispatch(setclosePid2());
  };


  const pageClicked = () => {
    dispatch(setSelectedTextUnit(null));
  };


  const closeUserProfile = async (event: any) => {

    let hidushimIframe = document.getElementById("hidushim-iframe");


    dispatch(setIsUserProfile(false));
    if (hidushimIframe) {
      // @ts-ignore
      hidushimIframe.src = iframeHidushimUrl;

    }
  };


  const getClassTanachTitle = () => {
    if (categoryName.length === 3) {
      return 'tanach-title-new-book-shulchan-arukh'
    }
    else if ((isMobile().phone || isMobile().tablet)) {
      return 'tanach-title-new-book-Liturgy';
    }
    else {
      return 'tanach-title-new-book-Talmud'
    }

  }

  const sectionLayoutTeanach = (
    pageText: any,
    prefix: any,
    allerts: any
  ) => {

    let flatten = flatTextUnitsT(pageText);




    return flatten.map((text: any, index: any) => {
      if (prefix === 'Shulchan_Arukh') {

        let data: any;
        if (typeof text.word !== 'string')
          data = text.word.join(' ').split("־").join(' ');
        else
          data = text.word.split("־").join(' ');

        let textsplit = '';
        if (allerts) {
          if (data.includes('<br>')) {
            textsplit = data.split('<br>')[0];
            data = data.replace('<br>', `<br>(${allerts[text.index]}) `);
            data = data.split('<br>')[1];
          }
          else {
            data = `(${allerts[text.index]}) ${data}`;
          }
        }


        return (
          <>
            {
              textsplit !== '' &&
              <div className='aaaaaa' dangerouslySetInnerHTML={{
                __html: `${currentPage.split(' ')[2].split(':')[0]} ${textsplit}`
              }}  >

              </div>
            }
            <span className={
              selectedUnitText !== text.index
                ? "text-unit-shulchan-srukh"
                : "text-unit-shulchan-srukh-selected"
            }
              onClick={e => unitTextClicked(text.index, e)}
              key={`${prefix}_${text.index}_${text.index}`}
              id={selectedTextUnit ? window.location.pathname.split(' ').join('_').split('/')[1] + '_' + text.index : ''}

              dangerouslySetInnerHTML={{
                __html: ` ${data}`
              }}>
            </span>
          </>
        );

      }
      if (prefix === 'Shulchan_Arukh1' && categoryName[0] !== 'Liturgy') {


        let data: any;


        if (typeof text.word !== 'string')
          data = text.word.join(' ').split("־").join(' ');
        else
          data = text.word.split("־").join(' ');

        if (allerts) {

          if (data.includes('<br>')) {
            // textsplit = data.split('<br>')[0];
            data = data.replace('<br>', `<br>(${allerts[text.index]}) `);
            data = data.split('<br>')[1];
          }
          else {
            data = `${data}`;
          }
        }

        return (
          <>
            <span className={
              selectedUnitText !== text.index
                ? !(isMobile().phone || isMobile().tablet) ? "text-unit-shulchan-srukh1" : "text-unit-shulchan-srukh1-m"
                : !(isMobile().phone || isMobile().tablet) ? "text-unit-shulchan-srukh-selected1" : "text-unit-shulchan-srukh-selected1-m"
            }
              onClick={e => unitTextClicked(text.index, e)}
              key={`${prefix}_${text.index}_${text.index}`}
              id={selectedTextUnit ? window.location.pathname.split(' ').join('_').split('/')[1] + '_' + text.index : ''}

              dangerouslySetInnerHTML={{
                __html: data ? `${data.includes("</b><br>") ? '<div class="shulchan-srukh-title">' + data.split("</b><br>")[0] + '</b><br></div>' : ''}${text.index > 0 || categoryName[0] !== 'Liturgy' ? `<small>${getLetterByNumber(text.index + 1)})</small>` : ''} ${data.includes('</b><br>') ? data.split("</b><br>")[1] : data}<br>` : ''
              }}>
            </span>
          </>
        );
      }

      if (categoryName[0] === 'Liturgy') {
        let data: any;
        if (typeof text.word !== 'string')
          data = text.word.join(' ').split("־").join(' ');
        else
          data = text.word.split("־").join(' ');


        return (
          <>
            <span className={
              selectedUnitText !== text.index
                ? `${!(isMobile().phone || isMobile().tablet) ? 'text-unit-Liturgy' : 'text-unit-Liturgy-m'}`
                : `${!(isMobile().phone || isMobile().tablet) ? 'text-unit-Liturgy-selected' : 'text-unit-Liturgy-selected-m'}`
            }
              onClick={e => unitTextClicked(text.index, e)}
              key={`${prefix}_${text.index}_${text.index}`}
              id={selectedTextUnit ? window.location.pathname.split(' ').join('_').split('/')[1] + '_' + text.index : ''}

              dangerouslySetInnerHTML={{
                __html: data ? `${data.includes("</b><br>") ? '<div class="shulchan-srukh-title">' + data.split("</b><br>")[0] + '</b><br></div>' : ''} ${data.includes('</b><br>') ? data.split("</b><br>")[1] : data}<br>` : ''
              }}>
            </span>
          </>
        );
      }

      if (prefix[0] === 'Talmud') {
        let data: any;
        if (typeof text.word !== 'string')
          data = text.word.join(' ').split("־").join(' ');
        else
          data = text.word.split("־").join(' ');
        let textsplit = '';

        return (
          <>
            {
              textsplit !== '' &&
              <div className='aaaaaa' dangerouslySetInnerHTML={{
                __html: `${currentPage.split(' ')[2].split(':')[0]} ${textsplit}`
              }}  >

              </div>
            }
            {categoryName[0] === 'Talmud' && categoryName[1] !== 'Bavli' && <span className={
              selectedUnitText !== text.index
                ? "text-unit-Talmud"
                : "text-unit-Talmud-selected"
            }
              onClick={e => unitTextClicked(text.index, e)}
              key={`${prefix}_${text.index}_${text.index}`}
              id={selectedTextUnit ? window.location.pathname.split(' ').join('_').split('/')[1] + '_' + text.index : ''}

              dangerouslySetInnerHTML={{
                __html: ` ${data}`
              }}>
            </span>}
            {categoryName[0] === 'Talmud' && categoryName[1] === 'Bavli' && <div className={
              selectedUnitText !== text.index
                ? "text-unit-Talmud"
                : "text-unit-Talmud-selected"
            }
              onClick={e => unitTextClicked(text.index, e)}
              key={`${prefix}_${text.index}_${text.index}`}
              id={selectedTextUnit ? window.location.pathname.split(' ').join('_').split('/')[1] + '_' + text.index : ''}

              dangerouslySetInnerHTML={{
                __html: ` ${data}`
              }}>
            </div>}
          </>
        );

      }

      if (prefix === 'chumash') {

        let data: any;
        if (typeof text.word !== 'string') {
          data = text.word.join(' ').split("־").join(' ');
          data.word = data.word.split('<br>').join('').split('{').join('').split('}').join('').trim();;
        }
        else {
          data = text.word.split("־").join(' ');
          data = data.split('<br>').join('').split('{').join('').split('}').join('').trim();
        }

        if (data === '')
          return (<></>)
        if (allerts)
          return (<span className={
            selectedUnitText !== text.index
              ? "text-unit"
              : "text-unit-selected"
          }
            onClick={e => unitTextClicked(text.index, e)}
            key={`${prefix}_${text.index}_${text.index}`}
            id={selectedTextUnit ? window.location.pathname.split(' ').join('_').split('/')[1] + '_' + text.index : ''}

            dangerouslySetInnerHTML={{
              __html: `<small>${allerts[text.index]})</small> ${data}: `
            }}>
          </span>
          );

      }

      if (text && text.word.length > 0 && prefix === 'rashi') {

        return text.word.map((unitsText: any, index1: any) => {
          return (

            <span className={
              selectedUnitText !== text.index
                ? "text-unit"
                : "text-unit-selected"
            }
              onClick={e => unitTextClicked(text.index, e)}
              key={`${prefix}_${text.index}_${index1}`}

              dangerouslySetInnerHTML={{
                __html: unitsText + ' '
              }}>
            </span>
          );
        })

      }

      if ((text && prefix === 'Onkelos') || prefix === 'Yonatan') {


        return (<span className={
          selectedUnitText !== text.index
            ? "text-unit"
            : "text-unit-selected"
        }
          onClick={e => unitTextClicked(text.index, e)}
          key={`${prefix}_${text.index}_${text.index}`}
          id={selectedTextUnit ? window.location.pathname.split(' ').join('_').split('/')[1] + '_' + text.index : ''}

          dangerouslySetInnerHTML={{
            __html: `<span style="font-family: PFT_vilna;">${allerts[text.index]}</span> ${text.word}  `
          }}>
        </span>
        );
      }




      if (prefix === 'Mishnah_Berurah') {

        if (typeof text.word === 'object') {
          if (text.word.length === 0) {
            return (<></>)
          }
        }
        else if (text.word.split(" - ").length > 1)
          text.word = "<strong>" + text.word.split(" - ").join('</strong>: ');
        else
          text.word = "<strong>" + text.word.split(" – ").join('</strong>: ');
        return (
          <div
            className={
              selectedUnitText !== text.index
                ? "text-unit"
                : "text-unit-selected"
            }

            onClick={e => unitTextClicked(text.index, e)}
            id={selectedTextUnit ? window.location.pathname.split(' ').join('_').split('/')[1] + '_' + text.index : ''}
            dangerouslySetInnerHTML={{
              __html: `${text.word}  `
            }}>
          </div>)
      }
      return (<></>)
    })

  }


  const sectionLayout = (
    prefix: string,
    textData: Array<Array<string>>,
    alignData: IRenderData,
    rowHeight: number
  ) => {
    let title: any;
    let isTitle = false;
    let titleWord = null;


    if (
      prefix === "gmara" &&
      renderWords &&
      "title" in renderWords &&
      renderWords.title
    ) {
      isTitle = true;
      const titleUtil = getFirstWord(textData);
      textData = titleUtil.newArray;
      titleWord = titleUtil.removedWord;


      title = (
        <div className="gmara-title-new-book">
          <NewGemaraLogo className="new-gemara-logo-svg" />
          <span
            className="gmara-title-header"
            dangerouslySetInnerHTML={{
              __html: titleWord
            }}
          />
        </div>
      );
    }


    if (
      prefix === "gmara" &&
      renderWords
    ) {

      textData = getGmaraBigWord(textData, alts, commentary['Rashi'], commentary['Tosafot']);

    }
    if (prefix === "rashi") {

      textData = boldRashiTosafot(textData, prefix, pageTitle);
    }

    if (prefix === "tosafot") {
      textData = boldRashiTosafot(textData, prefix, pageTitle);
    }
    const flatten = flatTextUnits(textData, prefix);
    const renderText = generateRenderUnits(
      flatten,
      alignData.textUnits,
      prefix
    );
    let ff = alignData.pixelOffset + alignData.rowsOffset * rowHeight;

    if (ff > 250 && ff < 350) {
      ff = 280
    }
    else if (ff >= 100 && ff < 150) {
      ff = 100;
      if (prefix === "rashi" && (isMobile().phone || isMobile().tablet)) {
        ff = 40;
      }
    }

    const style = {
      marginTop: `${ff}px`
    };
    const groupByBlock = groupBy(renderText);

    return (
      <React.Fragment>
        {groupByBlock.map((block: any, index: any) => {
          return (
            <div className={isTitle && (isMobile().phone || isMobile().tablet) ? `${prefix}-new  ${prefix}-new-title-mobile` : `${prefix}-new`} style={style} key={index}>
              {renderText.length > 0 && title}
              {block.map((renderedRow: IRenderUnit, index: string) => {
                const spanStyle = getSpanStyle(renderedRow.nudgeDiff);
                const topMargin = getRowTopMargin(renderedRow.top);
                let lineClass = `${prefix}-new-row ${renderedRow.rowAlign}-row `;
                if (renderedRow.textAlign === "center") {
                  lineClass += "text-align-center ";
                }
                switch (renderedRow.size) {
                  case 1:
                    if ((renderedRow.text[0].text.length < 23 && (!renderedRow.text[1] || renderedRow.text[1].text.length < 20) && prefix !== "gmara") && renderedRow.nudgeDiff === "marginLeft:0") {
                      lineClass += "text-align-center ";
                    }
                    lineClass += `${prefix}-third-row`;
                    break;
                  case 2:

                    lineClass += `${prefix}-half-row`;
                    break;
                  case 3:
                    if (renderedRow.text[0].text.length < 16 && prefix !== "gmara") {
                      lineClass += "text-align-center ";
                    }
                    lineClass += `${prefix}-halfpage-row`;
                    break;
                  case 4:
                    if (renderedRow.text[0].text.length < 16 && prefix !== "gmara") {
                      lineClass += "text-align-center ";
                    }
                    lineClass += `${prefix}-stretch-row`;
                    break;
                  case 5:
                    if (renderedRow.text[0].text.length < 16 && (!renderedRow.text[1] || renderedRow.text[1].text.length < 20) && prefix !== "gmara") {
                      lineClass += "text-align-center ";
                    }
                    lineClass += `${prefix}-full-row`;
                    break;
                  case 6:
                    if (renderedRow.text[0].text.length < 16) {
                      lineClass += "text-align-center ";
                    }
                    lineClass += `tosafot-third-row-small`;
                    break;
                  case 7:

                    if ((renderedRow.text[0].text.length < 23 && (!renderedRow.text[1] || renderedRow.text[1].text.length < 20) && prefix !== "gmara") && renderedRow.nudgeDiff === "marginLeft:0") {
                      lineClass += "text-align-center ";
                    }
                    lineClass += `${prefix}-half-third-row`;
                    break;
                  case 8:

                    if ((renderedRow.text[0].text.length < 23 && (!renderedRow.text[1] || renderedRow.text[1].text.length < 20) && prefix !== "gmara") && renderedRow.nudgeDiff === "marginLeft:0") {
                      lineClass += "text-align-center ";
                    }
                    lineClass += `${prefix}-half-special-row`;
                    break;
                }

                if (renderedRow.text[0].text.includes('~')) {
                  lineClass = lineClass.replace('center', '')
                }
                return (
                  <div
                    className={
                      (!isUserProfile || !isOpenNavPage) ? `${lineClass} ` : `${lineClass} show-all`
                    }
                    key={`${prefix}_${index}`}
                    style={topMargin}
                  >
                    {renderedRow.text.filter((item: any) => {
                      return item.text !== ':';
                    }).map(
                      (rowText: IRenderTextUnit, rowIndex: any) => {
                        if (rowText.text && rowText.text.includes('~') && spanStyle) {
                          spanStyle.marginRight = '0px';
                          rowText.text = rowText.text.replace('~', '');
                        }
                        if (rowText.text.includes('dataimage')) {
                          rowText.text = rowText.text.replace('dataimage', 'data:image')
                        }

                        return (
                          <span key={rowIndex}>
                            {window.location.origin === 'https://dev.test.shitufta.org.il' && rowIndex === 0 && <div className="text-unit-index">{index + 1}</div>}
                            {<span
                              className={
                                selectedUnitText !== rowText.unit
                                  ? "text-unit"
                                  : "text-unit-selected"
                              }
                              style={rowIndex === 0 ? spanStyle : {}}
                              onClick={e => unitTextClicked(rowText.unit, e)}
                              id={selectedTextUnit ? window.location.pathname.split(' ').join('_').split('/')[1] + '_' + index : ''}
                              key={`${prefix}_${index}_${rowIndex}`}
                              dangerouslySetInnerHTML={{
                                __html: rowText.text
                              }}
                            />}

                          </span>
                        )
                      }
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </React.Fragment>
    );
  };


  const getLetters = () => {
    return currentPage;
    if (!currentPage)
      return;

    var n = currentPage.indexOf(":");

    const letters = currentPage.substring(n + 1).split("׳").join('').split("-")[0];
    const lettersEnd = currentPage.substring(n + 1).split("׳").join('').split("-")[1];

    if (n === -1) {
      return;
    }
    let lettersNum = getKeyByValue(letters.replace('״', ''));


    if (lettersNum) {
      let lettersMideel = String(getLetterByNumber(Number(lettersNum) + 1))
      return [letters.replace('״', ''), lettersMideel.replace('״', ''), lettersEnd.replace('״', '')];
    }
  }


  const extract = (item: any, index: any, isBR: boolean) => {
    return item.map((x: any) => {
      if (x.txt && x.txt.includes('Tur,')) {
        return (<></>)
      }

      return (
        <>
          <span className={`header-nav-subpage-page-ein-mispot-item-index ${selectedTextUnit === index ? 'text-unit-selected' : ''}`}><b>{getLetterByNumber(index + 1)}{!isBR && ')'}</b></span>
          <span key={x.txt} className={`header-nav-subpage-page-ein-mispot-item ${selectedTextUnit === index ? 'text-unit-selected' : ''}`}
            onClick={e => unitTextClicked(index, e)}
          >
            {' ' + getMasoretHshas(x)}:
            {isBR && <br></br>}
          </span>
        </>
      )
    })
  }


  const getClassName = () => {
    //שתיהם סגורים או פתוחים
    if ((tabsOpenedRight === null && tabsOpenedLeft === null) || (tabsOpenedRight !== null && tabsOpenedLeft !== null) || isMobile().phone || isMobile().tablet) {
      if (categoryName[1] === 'Bavli' && categoryName[2].includes('Minor Tractates')) {
        return "book-page-container-yer"
      }
      if (categoryName[0] === 'Talmud' && categoryName[1] === 'Bavli' && categoryName.length === 3 && !categoryName[2].includes('on Talmud')) {
        return "book-page-container"
      }
      else if (categoryName[0] === 'Talmud' && categoryName[1] !== 'Bavli') {
        return "book-page-container-yer"
      }
      else {
        return "book-page-container-t " + ((isMobile().phone || isMobile().tablet) ? 'book-page-container-t-mobile' : '')
      }

    }
    else if (tabsOpenedRight !== null && tabsOpenedLeft === null) {


      if (categoryName[1] === 'Bavli' && categoryName[2].includes('Minor Tractates')) {
        return "book-page-container-open-right-yer"
      }
      if (categoryName[1] === 'Bavli' && categoryName.length === 3 && !categoryName[2].includes('on Talmud')) {
        return "book-page-container-open-right"
      }
      else if ((categoryName[1] !== 'Bavli' || categoryName.length !== 3) || categoryName[2].includes('on Talmud')) {
        return "book-page-container-open-right-yer"
      }
    }
    else if (tabsOpenedRight === null && tabsOpenedLeft !== null) {
      if (categoryName[1] === 'Bavli') {
        return "book-page-container-open-left"
      }
      else if (categoryName[1] !== 'Bavli') {
        return "book-page-container-open-left-yer"
      }
      if (categoryName[0] === 'Tanakh' &&
        categoryName[1] !== 'Rishonim on Tanakh' &&
        categoryName[1] !== 'Acharonim on Tanakh')
        return 'book-page-container-open-right-tanach'
      if (categoryName[0] === 'Halakhah')
        return 'book-page-container-open-left-shul'
    }
    else {

    }
  }


  const bookPageMainNewLayout = () => {
    //make sure css is same as declare here - rowheight.

    const rowHeight = 25;
    return (
      <>
        <div
          className={getClassName()}
          id="main-container"
          onClick={() => {
            pageClicked();
          }}
        >
          {renderWords && !(isMobile().phone || isMobile().tablet) &&
            <div className={
              (tabsOpenedLeft === null && tabsOpenedRight === null) || (tabsOpenedLeft !== null && tabsOpenedRight !== null) ?
                `header-nav-ein-mispot-wrapper` : (tabsOpenedLeft !== null && tabsOpenedRight === null) ?
                  'header-nav-ein-mispot-wrapper-open-left' : 'header-nav-ein-mispot-wrapper-open-right'}>
              <div className="header-nav-subpage-page-ein-mispot-item-title" >
                {subpage === 'b' ? 'עין משפט נר מצוה' : 'מסורת הש"ס השלם'}
              </div>
              {subpage === 'a' && commentarySefarim.mesoretHashas.map((item: any, key: any) => {
                if (item.length > 0)
                  return (<span key={key} >
                    {extract(item, key, false)}
                  </span>)
                else
                  return (<></>)
              })}
              {subpage === 'b' && commentarySefarim.einMishpatHashalem.map((item: any, key: any) => {
                if (item.length > 0)
                  return (<span key={key} >
                    {extract(item, key, true)}
                  </span>)
                else
                  return (<></>)
              })}
            </div>}
          {renderWords &&
            <div className="header-nav-subpage-page"
              style={{ textAlign: subpage === 'b' ? 'right' : 'left', fontSize: !(isMobile().phone || isMobile().tablet) ? 26 : 15 }}>
              <div >{pageTitle && pageTitle.split(' ')[pageTitle.split(' ').length - 2].replace('׳', '')}
                {subpage && getSubPageDot()}</div>

            </div>}
          {renderWords &&
            <div className={`header-nav-gmara-wrapper`} style={{ position: 'absolute', width: !(isMobile().phone || isMobile().tablet) ? 834 : '88%', fontSize: !(isMobile().phone || isMobile().tablet) ? 26 : 15 }}
              onClick={() => dispatch(setIsOpenNavPage(true))}
            >
              {<div className={`title-name-book-page`} style={{ width: !(isMobile().phone || isMobile().tablet) ? 235 : 135, textAlign: 'left' }}>
                {chapterName}
              </div>}
              <div className={`title-name-book-page`} style={{ width: !(isMobile().phone || isMobile().tablet) ? 300 : 70, textAlign: 'center' }}>
                {chapterNumberTitle && chapterNumberTitle}
              </div>
              {<div className={`title-name-book-page`} style={{ width: !(isMobile().phone || isMobile().tablet) ? 235 : 135, textAlign: 'right' }}>
                {bookName}
              </div>}
            </div>}
          {renderWords && tabsOpenedLeft === null && tabsOpenedRight === null && !(isMobile().phone || isMobile().tablet) &&
            <div className={`header-nav-ein-mispot-wrapper-left`}>
              <div className="header-nav-subpage-page-ein-mispot-item-title" >
                {subpage === 'a' ? 'עין משפט נר מצוה' : 'מסורת הש"ס השלם'}
              </div>
              {subpage === 'b' && commentarySefarim.mesoretHashas.map((item: any, key: any) => {
                if (item.length > 0)
                  return (<span key={key} >
                    {extract(item, key, false)}
                  </span>)
                else
                  return (<></>)
              })}
              {subpage === 'a' && commentarySefarim.einMishpatHashalem.map((item: any, key: any) => {
                if (item.length > 0)
                  return (<span key={key} >
                    {extract(item, key, true)}
                  </span>)
                else
                  return (<></>)
              })}
            </div>}
          {
            (
              (
                categoryName[0] === 'Talmud'
                &&
                categoryName[1] === 'Yerushalmi'
              )
              ||
              (
                categoryName[0] === 'Kabbalah' ||
                categoryName[0] === 'Chasidut' ||
                categoryName[0] === 'Jewish Thought' ||

                categoryName[0] === 'Musar' ||
                categoryName[0] === 'Midrash' ||
                categoryName[0] === 'Mishnah' ||
                categoryName[0] === 'Tanaitic' ||
                categoryName[0] === 'Philosophy' ||
                categoryName[0] === 'Responsa' ||


                categoryName[0] === 'Talmud'
              )) &&
            !renderWords &&
            <div className={`header-nav-gmara-wrapper-yer`}
              onClick={() => dispatch(setIsOpenNavPage(true))}
            >
              <div className="page-titles-container-no-page-number">
                {categoryName[0] !== "Kabbalah" && categoryName[1] !== 'Yerushalmi' && categoryName[0] !== 'Liturgy' && categoryName[0] !== 'Midrash' && !(categoryName[0] === 'Talmud' && categoryName[2].includes('on Talmud')) &&
                  <div className={!(isMobile().phone || isMobile().tablet) ? `title-name-book-page-yer` : 'title-name-book-page-yer-m'}>
                    {bookName}
                  </div>
                }
                {
                  (categoryName[0] === 'Talmud' && categoryName[2].includes('on Talmud')) &&
                  <div className={`title-name-book-page-Talmud`}>
                    {!bookName.includes('ביאור שט') && 'חידושי'}
                    {bookName.includes('ביאור שט') && 'ביאור'}
                  </div>
                }
                {
                  (categoryName[0] === 'Talmud' && categoryName[2].includes('on Talmud')) &&
                  <div className={`title-name-book-page-Talmud-1`}>
                    {bookName.includes('ביאור שט') && 'שיתופתא'}
                    {!bookName.includes('ביאור שט') && bookName.split('על').length > 1 && bookName.split('על')[0].trim().replace('חידושי', '')}
                    {!bookName.includes('ביאור שט') && bookName.split('על').length === 1 && bookName.split(' ')[0].trim().replace('חידושי', '')}
                  </div>
                }

                {categoryName[0] !== 'Liturgy' && categoryName[0] !== 'Midrash' && currentPage &&
                  !(categoryName[0] === 'Talmud' &&
                    categoryName[2].includes('on Talmud')) &&
                  !(categoryName[1] === 'Yerushalmi') &&
                  <div className={!(isMobile().phone || isMobile().tablet) ? `title-name-book-page-yer` : 'title-name-book-page-yer-m'}>

                    {currentPage.includes(':') && currentPage.split(':')[0]}
                    {categoryName[0] === 'Talmud' && categoryName.length < 3 && bookName.split('על')[1]}
                    {
                      categoryName[0] === 'Talmud' &&
                      categoryName[1] === 'Bavli' &&
                      (categoryName[2] === 'Acharonim on Talmud' || categoryName[2] === 'Rishonim on Talmud') &&
                      pageTitle.split(' ')[pageTitle.split(' ').length - 2] + ' ' + pageTitle.split(' ')[pageTitle.split(' ').length - 1]
                    }
                    {categoryName[0] !== 'Liturgy' && categoryName[0] !== 'Midrash' && !(categoryName[0] === 'Talmud' &&
                      categoryName[1] === 'Bavli' &&
                      (categoryName[2] === 'Acharonim on Talmud' || categoryName[2] === 'Rishonim on Talmud')) &&
                      !currentPage.includes(':') &&
                      currentPage.replace(bookName, '')
                    }
                  </div>
                }


                {currentPage && (categoryName[1] === 'Yerushalmi') &&
                  <div className={!(isMobile().phone || isMobile().tablet) ? `title-name-book-page-yer` : 'title-name-book-page-yer-m'}>
                    {pageTitle}
                  </div>
                }
                {categoryName[0] === 'Talmud' && (categoryName[2].includes('on Talmud')) &&
                  <div className={`title-name-book-page-Talmud-2`}>
                    {!bookName.includes('על') &&
                      bookName.replace(bookName.split(' ')[0].trim().replace('חידושי', ''), '')
                    }
                    {bookName.includes('על') &&
                      categoryName[0] === 'Talmud' &&
                      bookName.split('על')[1].replace('מסכת', '')
                    }
                    {sectionNames && sectionNames[0] === 'Daf' && ' ' + (pageTitle.split(' ')[pageTitle.split(' ').length - 2])}

                    {pageTitle.split(' ')[pageTitle.split(' ').length - 1] === 'ב' && ':'}

                    {pageTitle.split(' ')[pageTitle.split(' ').length - 1] === 'א' && '.'}
                    {pageTitle.split(' ')[pageTitle.split(' ').length - 1] !== 'ב' &&
                      pageTitle.split(' ')[pageTitle.split(' ').length - 1] !== 'א' &&

                      ' ' + pageTitle.split(' ')[pageTitle.split(' ').length - 1]
                    }
                  </div>
                }
              </div>
            </div>
          }
          {(categoryName[0] === 'Liturgy' || categoryName[0] === 'Tosefta' || categoryName[0] === 'Midrash') &&
            <div className={!(isMobile().phone || isMobile().tablet) ? `header-nav-gmara-wrapper-Liturgy` : `header-nav-gmara-wrapper-Liturgy-m`}
              onClick={() => dispatch(setIsOpenNavPage(true))}
            >
              <div className="page-titles-container-no-page-number">
                {currentPage.includes(bookName) &&
                  <div className={!(isMobile().phone || isMobile().tablet) ? `title-name-book-page-Liturgy` : `title-name-book-page-Liturgy-m`}>

                    {
                      currentPage
                    }
                  </div>
                }
              </div>
            </div>
          }
          {categoryName[0] === 'Tanakh' && categoryName.length === 2 &&
            <div className={`header-nav-gmara-wrapper-tanach ${categoryName[1]}-header`}
              onClick={() => {
                dispatch(setIsOpenNavPage(true))
              }}
            >
              <div className='header-nav-subpage-container-tanach' id='container_tanach' style={{ justifyContent: 'right' }}>
                {currentPage && !renderWords && currentPage.split(':')[1]}
                {subpage && getSubPageDot()}
              </div>
              <div className="header-nav-subpage-container-tanach" style={{ justifyContent: 'center' }}>
                {!renderWords && bookName}
              </div>
              <div className={'header-nav-subpage-container-tanach'} style={{ justifyContent: 'flex-end' }}>
                {chapterNumberTitle && chapterNumberTitle}
                {!renderWords && currentPage.split(':')[0]}
              </div>
            </div>}
          {!renderWords && categoryName[1] === 'Shulchan Arukh' && categoryName.length === 2 &&
            <div
              className={(tabsOpenedRight === null) === (tabsOpenedLeft === null) ? 'header-nav-gmara-wrapper-shulchan-srukh' : 'header-nav-gmara-wrapper-shulchan-srukh-open'}
              id='Shulchan_Arukh_header'
              onClick={() => dispatch(setIsOpenNavPage(true))}
            >
              {currentPage && <div className='header-nav-subpage-container-shulchan-srukh' id='container_Shulchan_Arukh'>
                <b>
                  {currentPage &&
                    currentPage.split(' ')[2].split(':')[1]}
                </b>
              </div>}
              <div className="header-nav-subpage-container-shulchan-srukh">
                <b>{pageTitle.substring(0, pageTitle.lastIndexOf(" ")) + ' סימן ' + pageTitle.substring(pageTitle.lastIndexOf(" "), pageTitle.length)}</b>
              </div>
            </div>
          }
          {((categoryName[0] === 'Halakhah' && ((categoryName[1] === 'Rishonim on Tanakh' || categoryName[1] === 'Acharonim on Tanakh') || categoryName[2] === 'Commentary')) || (categoryName[0] === 'Tanakh' && categoryName.length > 2) || (categoryName[1] === 'Mishneh Torah') || (categoryName[0] === 'Halakhah' && categoryName.length === 1) || (categoryName[0] === 'Halakhah' && categoryName[1] !== 'Shulchan Arukh' && categoryName.length === 2) ||
            (!renderWords &&
              categoryName &&
              categoryName[1] === 'Shulchan Arukh' && categoryName[2] === 'Commentary' && categoryName.length !== 2)) &&
            < div
              className={(tabsOpenedRight === null) === (tabsOpenedLeft === null) ? 'header-nav-gmara-wrapper-shulchan-srukh-commentary' : 'header-nav-gmara-wrapper-shulchan-srukh-open'}
              id='Shulchan_Arukh_header'
              onClick={() => dispatch(setIsOpenNavPage(true))}
            >
              {(isMobile().phone || isMobile().tablet) &&
                <div className='header-nav-subpage-container-shulchan-srukh-title-m'>
                  <b>
                    {pageTitle}
                  </b>
                </div>}
              {!(isMobile().phone || isMobile().tablet) &&
                <div className='header-nav-subpage-container-shulchan-srukh-title'>
                  <b>
                    {pageTitle}
                  </b>
                </div>}
              {!(isMobile().phone || isMobile().tablet) && <div className='header-nav-subpage-container-shulchan-srukh-title-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: 100 }} />
              </div>}
            </div>
          }
          {categoryName[0] === 'Tanakh' && categoryName[1] !== 'Rishonim on Tanakh' && categoryName[1] !== 'Acharonim on Tanakh' &&
            <div className="tanach-title-new-book-continer">

              <div className={(categoryName[1] === 'Rishonim on Tanakh' || categoryName[1] === 'Acharonim on Tanakh') ? '' : "continer"}>
                <div className={`tanach-title-new-book ${categoryName[1]}`}>
                  {pageText &&
                    categoryName[0] === 'Tanakh' && categoryName.length === 2 &&
                    sectionLayoutTeanach(pageText, 'chumash', getLetters())}
                </div>
                <div className="tanach-title-new-book-onk">
                  {categoryName[0] === 'Tanakh' && categoryName[1] === 'Torah' && <div className='center-onke'  >    <b> {'אונקלוס'}</b><br></br> </div>}

                  {pageText &&
                    !renderWords && commentary && commentary[`Onkelos ${currentBook}`] &&
                    sectionLayoutTeanach(commentary[`Onkelos ${currentBook}`], 'Onkelos', getLetters())
                  }

                </div>

              </div>
              {!renderWords && (categoryName[0] === 'Tanakh') &&
                commentary &&
                commentary["Rashi"] &&
                commentary["Rashi"].length > 0 &&
                <div className="tanach-title-new-book-rashi-header" >
                  <b>
                    {'רש"י'}
                  </b>
                  <br>
                  </br>
                </div>
              }
              <div className="tanach-title-new-book-rashi">
                {pageText &&
                  !renderWords &&
                  commentary &&
                  commentary["Rashi"] &&
                  commentary["Rashi"].length > 0 &&
                  sectionLayoutTeanach(commentary["Rashi"], 'rashi', getLetters())
                }
              </div>

            </div>
          }

          {!renderWords && pageText &&
            ((categoryName[0] === 'Tanakh' &&
              (categoryName.length > 2)) ||
              categoryName[1] === 'Mishneh Torah' ||
              categoryName[1] === 'Shulchan Arukh' ||
              categoryName[0] === 'Halakhah'
            ) && categoryName[3] !== 'Mishnah Berurah' &&
            <div className={"tanach-title-new-book-shulchan-arukh"} id='Shulchan_Arukh_texts'>
              {sectionLayoutTeanach(pageText, 'Shulchan_Arukh1', getLetters())}
            </div>
          }
          {!renderWords &&
            (
              categoryName[0] === 'Talmud' ||
              categoryName[0] === 'Kabbalah' ||
              categoryName[0] === 'Chasidut' ||
              categoryName[0] === 'Jewish Thought' ||
              categoryName[0] === 'Musar' ||
              categoryName[0] === 'Tosefta' ||
              categoryName[0] === 'Midrash' ||
              categoryName[0] === 'Responsa' ||
              categoryName[0] === 'Mishnah' ||
              categoryName[0] === 'Philosophy' ||
              categoryName[0] === 'Liturgy' ||
              categoryName[0] === 'Tanaitic'
            )
            &&
            <div
              className={getClassTanachTitle()}
              id='Shulchan_Arukh_texts'   >
              {pageText &&
                !renderWords && categoryName[1] === 'Shulchan Arukh' && categoryName.length === 2 &&
                sectionLayoutTeanach(pageText, 'Shulchan_Arukh', getLetters())}
              {pageText &&
                categoryName[0] === 'Talmud' &&
                sectionLayoutTeanach(pageText, categoryName, getLetters())}
              {pageText &&
                !(categoryName[0] === 'Talmud' || (categoryName[1] === 'Shulchan Arukh' && categoryName.length === 2)) &&
                sectionLayoutTeanach(pageText, 'Shulchan_Arukh1', getLetters())}
            </div>
          }

          {
            categoryName[3] === 'Mishnah Berurah' && <div className={"tanach-title-new-book-mishnah_Berurah"} id='Shulchan_Arukh_texts' style={{ fontSize: '1' }}>
              {sectionLayoutTeanach(pageText, 'Mishnah_Berurah', pageText)}
            </div>
          }
          {//["Halakhah", "Mishneh Torah", "Commentary", "Kessef Mishneh", "Sefer Madda"]
            categoryName[0] === 'Halakhah' && categoryName[1] === 'Mishneh Torah' && categoryName[2] === 'Commentary' &&
            <div className={"tanach-title-new-book-mishnah_Berurah"} id='Shulchan_Arukh_texts' style={{ fontSize: '1' }}>
              {sectionLayoutTeanach(pageText, 'Shulchan_Arukh1', pageText)}
            </div>
          }

          {commentary &&
            commentary["Rashi"] &&
            renderWords &&
            sectionLayout(
              "rashi",
              commentary["Rashi"],
              renderWords.rashi,
              rowHeight
            )}
          {commentary &&
            commentary["Tosafot"] &&
            renderWords &&
            sectionLayout(
              "tosafot",
              commentary["Tosafot"],
              renderWords.tosafot,
              rowHeight
            )}
          {pageText &&
            renderWords && categoryName[0] === 'Talmud' &&
            sectionLayout(
              "gmara",
              augmentPageText(pageText),
              renderWords.gmara,
              25
            )}

        </div>
        <div className="book-page-footer"></div>
      </>
    );
  };


  if (isLoading)
    return <LoadingIndicator />;


  const handleChange = (bookId: string) => {

    if (bookId) {
      const splitBookid = bookId.split(" ");
      const page = splitBookid.pop();
      const book = splitBookid.join("_");
      dispatch(getPageData(book, page));
    }
  };


  return (
    <>
      <div
        className={
          isUserProfile
            ? "user-profile-on-container"
            : "user-profile-none-container"
        }
        id="user-profile-on-container"
      >
        <div
          className={isUserProfile ? (userProfileView === 'FormPageCreate' ? "user-profile-on-create" : "user-profile-on") : "user-profile-none"}
        >
          {isUserProfile && <FormPage />}
          <div className="user-profile-close">
            <div className="close-user-svg-container">
              <CloseSign
                className="close-user-svg"
                onClick={(e: any) => {
                  if (userProfileView === 'FormPageCreate' || userProfileView === 'FormPageForget') {
                    dispatch(setUserProfileView("FormPageConnect"));
                  }
                  else {
                    closeUserProfile(e);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={(((!isUserProfile) && !(selectedUserName === 0 &&
        tabsOpenedLeft === 'hidushim')) &&
        !(tabsOpenedLeft === 'hidushim' && isOpenTemplate))
        ? "" : "show-all"}
        onClick={() => {
          if (isUserProfile) {
            dispatch(setIsUserProfile(false));
          }
          if (isOpenNavPage) {
            dispatch(setIsOpenNavPage(false));
          }
          if (isOpenTemplate) {
            dispatch(setIsOpenTemplates(!isOpenTemplate));
          }

        }}
      >

        {!(isMobile().phone || isMobile().tablet) && <HeaderNav />}
        {(isMobile().phone || isMobile().tablet) && <HeaderMoblie />}
        {
          userProfileView !== 'HidushimMobile' &&
          userProfileView !== 'UserProfileMobile' &&
          userProfileView !== 'LastHidushimMobile' &&
          userProfileView !== 'ListOfRenewMobile' &&
          userProfileView !== 'HidushimMenuMobile' &&
          userProfileView !== 'MefarshimMobile' &&
          userProfileView !== 'MenuMobile' &&
          userProfileView !== 'FormPageConnectMobile' &&
          userProfileView !== 'MenuYomaMobile' &&
          <div
            className={
              (isPidOpen2 || isPidOpen1 || isPidOpen || isMenuOpen1 || isMenuOpen) ? 'header-hidden' :
                "book-page-full-container"}
            onTouchStart={(e) => {
              setTouchStartPageX(e.changedTouches[0].pageX)
              setTouchStartPageY(e.changedTouches[0].pageY)
            }
            }
            onTouchEnd={(e) => {
              if (touchStartPageX - e.changedTouches[0].pageX < -60.0 && Math.abs(touchStartPageY - e.changedTouches[0].pageY) < 30.0) {
                handleChange(nextPage)
              }
              if (touchStartPageX - e.changedTouches[0].pageX > 60.0 && Math.abs(touchStartPageY - e.changedTouches[0].pageY) < 30.0) {
                handleChange(prevPage)
              }

              setTouchStartPageX(0)
              setTouchStartPageY(0)

            }

            }


            onClick={() => {
              if (isUserProfileOpen) {
                dispatch(setIsUserProfileOpen(false));
              }
              if (emailOpen) {
                dispatch(setEmailOpen(false));
              }
              if (notificationsOpen) {
                dispatch(setNotificationsOpen(false));
              }
              handleCloseMenu()
            }}
          >

            {categoryName && <div className="margin-wrapper-main" id="margin-main">

              {categoryName[0] === 'Talmud' && categoryName[1] === 'Bavli' && bookPageMainNewLayout()}
              {categoryName[0] === 'Talmud' && categoryName[1] !== 'Bavli' && bookPageMainNewLayout()}
              {(categoryName[0] === "Tanakh") && bookPageMainNewLayout()}
              {categoryName[0] === 'Halakhah' && bookPageMainNewLayout()}
              {(categoryName[0] === 'Kabbalah' ||
                categoryName[0] === 'Chasidut' ||
                categoryName[0] === 'Jewish Thought' ||
                categoryName[0] === 'Musar' ||
                categoryName[0] === 'Tosefta' ||
                categoryName[0] === 'Midrash' ||
                categoryName[0] === 'Philosophy' ||
                categoryName[0] === 'Responsa' ||
                categoryName[0] === 'Liturgy' ||
                categoryName[0] === 'Tanaitic'
              ) && bookPageMainNewLayout()}
              {categoryName[0] === 'Mishnah' && bookPageMainNewLayout()}
              {categoryName[0] === 'Mishneh Torah' && bookPageMainNewLayout()}
              {categoryName[3] === "Mishnah_Berurah" && bookPageMainNewLayout()}
            </div>}
            {!(isMobile().phone || isMobile().tablet) &&
              <div className="panel-right-side-container" id='left'>
                <Sfarim />

              </div>}
            {!(isMobile().phone || isMobile().tablet) &&
              <div className="panel-left-side-container" id='right'>
                <Hidushim />
              </div>}

          </div>
        }
        {isOpenNavPage &&
          <OpenNavPage />
        }

        {(isMobile().phone || isMobile().tablet) &&
          (userProfileView === 'HidushimMobile' ||
            userProfileView === 'UserProfileMobile' ||
            userProfileView === 'LastHidushimMobile' ||
            userProfileView === 'ListOfRenewMobile' ||
            userProfileView === 'HidushimMenuMobile' ||
            userProfileView === 'MenuYomaMobile' ||
            userProfileView === 'MefarshimMobile' ||
            userProfileView === 'FormPageConnectMobile' ||
            userProfileView === 'MenuMobile') &&
          <WellcomeMoblie link='' />}
        {(isMobile().phone || isMobile().tablet) && <FooterMobile />}
      </div >
      {
        selectedUserName === 0 &&
        tabsOpenedLeft === 'hidushim' &&
        !isLoading &&
        <BlockAnnonimus text={'<b>התחבר או הירשם</b><br>על מנת לצפות בחידושים יש להתחבר או להירשם'} />
      }
      {tabsOpenedLeft === 'hidushim' && isOpenTemplate && <Templates />}
    </>
  );
};

export default BookPage;
