import "./NavList.css";
import copy from 'copy-to-clipboard';
import React from "react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import {
  getPageId,
  getUserProfileView
} from "../../store/selectors/selectors";
import { useSelector, useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  closeMenu,
  setUserProfileView,
  setCurrentEvents, setMenuChaptersView,
} from "../../store/actions/viewActions";
import {
  getPageData, setPageNumberFromUrl
} from "../../store/actions/pageActions";
import {
  isPentateuchIncludes
} from "../../utils/booksAndChapters2";

interface PageEntity {
  book: string,
  page: string,
  hePage: string,
  isLink: boolean,
  sectionName: string[]
}

const NavList: React.FC<PageEntity> = ({ book, page, hePage, sectionName, isLink }) => {
  const pageId = useSelector(getPageId);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const userProfileView = useSelector(getUserProfileView);
  const link = book + '/' + page;

  const handleSelectPage = (book: any, page: string) => {
    if (userProfileView === "MenuMobile") {
      dispatch(setUserProfileView(""));
    }

    if (isPentateuchIncludes(book)) {
      dispatch(setPageNumberFromUrl(page));
      dispatch(setMenuChaptersView('subPage'));
      return
    }
    if (book && page) {
      dispatch(closeMenu());
      dispatch(getPageData(book, page));
    }
    dispatch(setCurrentEvents(''));
  };



  if (!page) {
    return (<></>)
  }



  return (
    <>
      <ContextMenuTrigger id={page === pageId ? "selected-page" : page}>
        <button
          className={
            page === pageId
              ? `${isLink === false ? 'bookChosen-page-item' : 'openNav-book-item-ram'} bookChosen-current-item`
              : `${isLink === false ? 'bookChosen-page-item' : 'openNav-book-item-ram'}`
          }
          id={page === pageId ? "selected-page" : page}
          key={page}

          onClick={() => handleSelectPage(book, page)}
        >
          {<p className="bookChosen-page-item-text">
            {hePage}
          </p>}
        </button>
      </ContextMenuTrigger>
      <ContextMenu className={'context-menu'} id={page === pageId ? "selected-page" : page} >
        <MenuItem className={'context-menu-item'} onClick={() => {
          window.open(`/${link}`, 'new_window')
        }} >
          {'פתיחת קישור בכרטיסייה חדשה'}                         </MenuItem>
        <MenuItem className={'context-menu-item'} onClick={() => {
          window.open(`/${link}`, '_blank', 'toolbar=0,location=0,menubar=0')
        }} >
          {'פתיחת קישור בחלון חדש'}
        </MenuItem>
        <MenuItem className={'context-menu-item'} onClick={() => {
          copy(`${window.location.origin}/${link}`, {
            debug: true,
            message: 'Press #{key} to copy',
          });
        }} >
          {'העתק כתובת קישור'}
        </MenuItem>
      </ContextMenu>

    </>
  );
}

export default NavList;
