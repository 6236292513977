import books from "../book/booksAndChapters2.json";
import { parseBookPageToHebrew } from "../utils/menuUtils";

const Bereshit = [
	"Bereshit",
	"בראשית",
	"Noach",
	"נח",
	"Lech Lecha",
	"לך לך",
	"Vayera",
	"וירא",
	"Chayei Sara",
	"חיי שרה",
	"Toldot",
	"תולדות",
	"Vayetzei",
	"ויצא",
	"Vayishlach",
	"וישלח",
	"Vayeshev",
	"וישב",
	"Miketz",
	"מקץ",
	"Vayigash",
	"ויגש",
	"Vayechi",
	"ויחי",
]
const Shemot = [
	"Shemot",
	"שמות",
	"Vaera",
	"וארא",
	"Bo",
	"בא",
	"Beshalach",
	"בשלח",
	"Yitro",
	"יתרו",
	"Mishpatim",
	"משפטים",
	"Terumah",
	"תרומה",
	"Tetzaveh",
	"תצווה",
	"Ki Tisa",
	"כי תשא",
	"Vayakhel",
	"ויקהל",
	"Pekudei",
	"פקודי",]
const Vayikra = [
	"Vayikra",
	"ויקרא",
	"Tzav",
	"צו",
	"Shmini",
	"שמיני",
	"Tazria",
	"תזריע",
	"Metzora",
	"מצורע",
	"Achrei Mot",
	"אחרי מות",
	"Kedoshim",
	"קדושים",
	"Emor",
	"אמור",
	"Behar",
	"בהר",
	"Bechukotai",
	"בחוקותי",
]
const Bamidbar = [
	"Bamidbar",
	"במדבר",
	"Nasso",
	"נשא",
	"Beha'alotcha",
	"בהעלותך",
	"Sh'lach",
	"שלח",
	"Korach",
	"קורח",
	"Chukat",
	"חוקת",
	"Balak",
	"בלק",
	"Pinchas",
	"פינחס",
	"Matot",
	"מטות",
	"Masei",
	"מסעי",
]
const Devarim = [
	"Devarim",
	"דברים",
	"Vaetchanan",
	"ואתחנן",
	"Eikev",
	"עקב",
	"Re'eh",
	"ראה",
	"Shoftim",
	"שופטים",
	"Ki Teitzei",
	"כי תצא",
	"Ki Tavo",
	"כי תבוא",
	"Nitzavim",
	"נצבים",
	"Vayeilech",
	"וילך",
	"Ha'Azinu",
	"האזינו",
	"V'Zot HaBerachah",
	"וזאת הברכה"
]
const Joshua = [
	"Joshua",
	"יהושע",
	"Judges",
	"שופטים",
	"I Samuel",
	"שמואל א",
	"II Samuel",
	"שמואל ב",
	"I Kings",
	"מלכים א",
	"II Kings",
	"מלכים ב",
	"Isaiah",
	"ישעיהו",
	"Jeremiah",
	"ירמיהו",
	"Ezekiel",
	"יחזקאל",
	"Hosea",
	"הושע",
	"Joel",
	"יואל",
	"Amos",
	"עמוס",
	"Obadiah",
	"עובדיה",
	"Jonah",
	"יונה",
	"Micah",
	"מיכה",
	"Nahum",
	"נחום",
	"Habakkuk",
	"חבקוק",
	"Zephaniah",
	"צפניה",
	"Haggai",
	"חגי",
	"Zechariah",
	"זכריה",
	"Malachi",
	"מלאכי"
]
const Psalms = [
	"Psalms",
	"תהילים",
	"Proverbs",
	"משלי",
	"Job",
	"איוב",
	"Song of Songs",
	"שיר השירים",
	"Ruth",
	"רות",
	"Lamentations",
	"איכה",
	"Ecclesiastes",
	"קהלת",
	"Esther",
	"אסתר",
	"Daniel",
	"דניאל",
	"Ezra",
	"עזרא",
	"Nehemiah",
	"נחמיה",
	"I Chronicles",
	"דברי הימים א",
	"II Chronicles",
	"דברי הימים ב"
]
const Shulchan_Arukh = [
	'Even_HaEzer',
	"Yoreh_De'ah",
	"Orach_Chayim",
	"Choshen_Mishpat",
	'Even HaEzer',
	"Yoreh De'ah",
	"Orach Chayim",
	"Choshen Mishpat",
	"Orach Chaim",
	"Orach_Chaim",
	'Yoreh_Deah',
	'Yoreh Deah'
]


export function getAllCategory() {
	return books.map((a) => {
		if (a.heCategory) return a.heCategory;

	})
}

export function getCategory(category: string) {
	return books.filter(a => a.category === category).map((a) => {
		if (a.category === category) return a;
	})
}
//אם bookBefor מלא צריך לוודא שזה תואם את בחיר ת הרמה
export function getAllHeCategoryName(book: string, bookBefor: string[] = [], booksT: any = [], level: number = 0): any {
	console.log(book, bookBefor, level);

	if (!book) {
		return
	}
	if (bookBefor && bookBefor[0] && (bookBefor[0] === 'Tanakh' || bookBefor[0] === 'תנ"ך')) {
		bookBefor = bookBefor.slice(1);
		if (bookBefor[0] === 'Torah') {
			bookBefor[0] = 'Pentateuch'
		}
	}


	if (bookBefor && bookBefor[1] && bookBefor.length > 2 && (['Tur', 'Shulchan Arukh'].includes(bookBefor[1]))) {
		bookBefor = bookBefor.map((i: string) => {
			if ('Commentary' === i) return `${i} ${bookBefor[1]}`
			else return i
		}).filter((i: string) => { return !['Tur', 'Shulchan Arukh'].includes(i) });
	}

	book = book.split('_').join(' ')
	if (booksT.length === 0) {
		booksT = books;
	}
	for (let index = 0; index < booksT.length; index++) {

		if (booksT[index].heTitle === book || booksT[index].title === book || booksT[index].category === book || booksT[index].heCategory === book) {
			return [];
		}

		if (booksT[index].contents) {
			if (bookBefor && bookBefor.length > 0 && bookBefor.length > level) {
				if (bookBefor[level] === booksT[index].category || bookBefor[level] === booksT[index].heCategory) {
					return [booksT[index].heCategory].concat(getAllHeCategoryName(book, bookBefor, booksT[index].contents, level + 1));
				}

			}
			if (bookBefor && bookBefor.length > 0 && bookBefor.length === level) {
				return []
			}

			/*const contents = getAllHeCategoryName(book, bookBefor, booksT[index].contents, level + 1)
			if (contents) {

				if (bookBefor && bookBefor.length === level) {

					return [booksT[index].heCategory]
				}
				if (bookBefor && bookBefor[level] && bookBefor[level] === booksT[index].heCategory) {
					return [booksT[index].heCategory].concat(contents);
				}
				if (bookBefor && (bookBefor.length === 0)) {
					return [booksT[index].heCategory].concat(contents);
				}
			}*/
		}
		else {
			return []
		}
	}
}
/*להשלים מקבל רשימה מחזיר את הרשימה פחות רמה אחת*/
export function getAllHeCategoryList(book: string[], booksT: any = [], i = 0): any {
	if (booksT.length === 0) {
		booksT = books;
	}

	for (let index = 0; index < booksT.length; index++) {

		if (booksT && booksT[index] && booksT[index].category) {
			if (book && book[i] && (book[i] === booksT[index].category || book[i] === booksT[index].heCategory)) {
				if (book.length === i + 1)
					return booksT[index]
				if (book.length > i + 1)
					return getAllHeCategoryList(book, booksT[index].contents, i + 1)
			}
		}

	}

}



export function getAllHeCategoryNameByCategory(category: any, booksT: any = []): any {
	category = category.split('_').join(' ')
	if (booksT.length === 0) {
		booksT = books;
	}
	for (let index = 0; index < booksT.length; index++) {

		if (booksT[index].heTitle || booksT[index].title) {
			if (booksT[index].category === category || booksT[index].heCategory === category) {
				return [];
			}
		}

		if (booksT[index].contents) {
			if (getAllHeCategoryNameByCategory(category, booksT[index].contents)) {
				return [booksT[index].heCategory].concat(getAllHeCategoryNameByCategory(category, booksT[index].contents));
			}
		}

	}
}

export function getAllCategoryName(book: string, bookBefor: string[] = [], booksT: any = [], level = 0): any {
	if (!book) {
		return
	}
	if (bookBefor && bookBefor[0] && (bookBefor[0] === 'Tanakh' || bookBefor[0] === 'תנ"ך')) {
		bookBefor = bookBefor.slice(1);
	}
	if (bookBefor && bookBefor.length > 1 && !bookBefor.includes(book)) {
		bookBefor.push(book);
	}

	book = book.split('_').join(' ')
	if (booksT.length === 0) {
		booksT = books;
	}
	for (let index = 0; index < booksT.length; index++) {
		if (booksT[index].heTitle || booksT[index].title) {
			if (booksT[index].heTitle === book || booksT[index].title === book) {
				return [];
			}
		}
		if (booksT[index].contents && (booksT[index].heCategory === bookBefor[level] || booksT[index].category === bookBefor[level] || bookBefor.length === 0)) {



			if (bookBefor.length >= level + 1 || bookBefor.length === 0) {
				const allCategoryName = getAllCategoryName(book, bookBefor, booksT[index].contents, level + 1)

				return [booksT[index].category].concat(getAllCategoryName(book, bookBefor, booksT[index].contents, level + 1));
			}
		}

	}
}

export function getHeCategory(heCategory: string, booksL: any = []) {

	if (booksL.length === 0) {
		booksL = books
	}
	return booksL.filter((a: any) => a.heCategory === heCategory).map((a: any) => {
		if (a.heCategory === heCategory) {
			return a.contents.map((b: any) => {
				if (!b.contents && b.heTitle) {
					return b.heTitle
				}
				else if (b.contents) {
					return getHeCategory(b.heCategory, a.contents)
				}
			})
		}
	})
}

export function getTitle(title: string, booksL: any = []): any {
	title = title.split('_').join(' ')
	if (booksL.length === 0) {
		booksL = books
	}

	for (let index = 0; index < booksL.length; index++) {
		const a = booksL[index];
		if (title === a.heCategory) {
			return a.category.split(' ').join('_')
		}
		if (title === a.category) {
			return a.heCategory.split(' ').join('_')
		}
		if (a.heCategory) {
			const category = getTitle(title, a.contents)
			if (category) {
				return category
			}
		}
		if (a.title === title) {
			return a.heTitle.split(' ').join('_')
		}
		if (a.heTitle === title) {
			return a.title.split(' ').join('_')
		}
	}
}


export function getAllHeCategory(heCategory: string, heCategoryb: string[], booksL: any = [], level = 0): any {

	if (booksL.length === 0) {
		booksL = books
	}

	for (let index = 0; index < booksL.length; index++) {
		const a = booksL[index];

		if (a.heCategory) {
			if (heCategoryb && heCategoryb.length > 0 && heCategoryb[level] && a.heCategory === heCategoryb[level]) {
				const category = getAllHeCategory(heCategory, heCategoryb, a.contents, level + 1)

				if (category) {
					return category
				}
			}
			if (heCategoryb && heCategoryb.length === 0) {
				const category = getAllHeCategory(heCategory, heCategoryb, a.contents, level + 1)
				if (category) {
					return category
				}
			}

		}
		if (a.heCategory === heCategory) {
			return a
		}



	}


}

export function getAllnodes(nodes: any, book: string, sectionName: boolean, pageId: string): any {

	if (isPentateuchG(getTitle(book))) {
		nodes = nodes[0]
	}



	const node = getAllnodesPrivet(nodes, book, sectionName, '', '', pageId);


	node.map((item: any) => {


		if (item && item.length && item.length > 0) {
			let heTitle = '';
			let isArray = false;
			item.forEach((element: any) => {

				if (element && element[1]) {
					heTitle = element[1].hebook;
					isArray = true;
					element.unshift({
						book: '~',
						page: '~',
						hebook: heTitle !== '' ? heTitle : book,
						hePage: `~`,
						isLink: false
					});
					return;
				}
				if (element && element.hebook) {
					heTitle = element.hebook;
					return;
				}
			});

			if (!isArray)
				item.unshift({
					book: '~',
					page: '~',
					hebook: heTitle !== '' ? heTitle : book,
					hePage: `~`,
					isLink: false
				});

			return item
		}
		else {
			return item
		}
	})


	return node.flat().flat();
}

interface PageEntity {
	book: string,
	page: string,
	hebook: string,
	hePage: string,
	sectionNames: string[],
	isLink: boolean
}

function isNumber(str: any) {
	return !Number.isNaN(Number(str));
}

function getAllnodesPrivet(nodes: any, book: string, sectionName: boolean, title: string = '', heTitle: string = '', pageId: string = ''): Array<PageEntity> {


	if (!nodes.content_counts && !nodes.nodes) {


		if (isNumber(nodes)) {
			return [...Array(Number(nodes))].map((item: any, index: any) => {
				return {
					book: getTitle(book),
					page: `${index}`,
					hebook: book,
					hePage: parseBookPageToHebrew(`${index + 1}`, false),
					isLink: false,
					sectionNames: [''],

				}
			})

		}


		return nodes.map((element: any, index: number) => {
			if (!element) {
				return
			}
			if (element.content_counts && !element.nodes) {

				if (typeof element.content_counts === 'number') {

					if (title !== '') {

						return {
							book: getTitle(book) + ',_' + title,
							page: ',_' + element.title.split(' ').join('_'),
							hebook: heTitle,
							hePage: element.heTitle,
							isLink: true,
							sectionName: element.sectionNames,
							heTitle: heTitle
						}
					}
					else {
						let cc = ''
						if (book === 'טור') {
							cc = pageId
						}
						else {
							cc = getTitle(book)
						}

						return {
							book: cc,
							page: ',_' + element.title.split(' ').join('_'),
							hebook: book,
							hePage: element.heTitle,
							isLink: true,
							sectionName: element.sectionNames,
						}
					}

				}
				else {
					const content_counts = element.content_counts.map((item: any, index: any) => {


						if (item != '0') {
							if (element.title !== '') {
								if (title !== '')
									return {
										book: getTitle(book) + ',_' + title + ',_' + element.title.split(' ').join('_'),
										page: `${index + 1}`,
										hebook: element.heTitle,
										hePage: parseBookPageToHebrew(`${index + 1}`, element.sectionNames && element.sectionNames[0] === 'Daf' && element.sectionNames[1] === 'Line'),
										sectionName: element.sectionNames,
										isLink: false
									}
								if (title === '')
									return {
										book: getTitle(book) + ',_' + element.title.split(' ').join('_'),
										page: `${index + 1}`,
										hebook: element.heTitle,
										hePage: parseBookPageToHebrew(`${index + 1}`, element.sectionNames && element.sectionNames[0] === 'Daf' && element.sectionNames[1] === 'Line'),
										sectionName: element.sectionNames,
										isLink: false
									}
							}
							if (element.key === "default") {
								let bookD = ''
								let pageD = ''
								if (book === 'טור') {
									bookD = pageId
									pageD = `${index + 1}`
								}
								else if (title === '') {

									bookD = getTitle(book)
									pageD = `${index + 1}`
								}
								else {
									bookD = getTitle(book)
									pageD = ',_' + title + `_${index + 1}`
								}

								return {
									book: bookD,
									page: pageD,
									hebook: book,
									hePage: parseBookPageToHebrew(`${index + 1}`, element.sectionNames && element.sectionNames[0] === 'Daf' && element.sectionNames[1] === 'Line'),
									sectionName: element.sectionNames,
									isLink: false
								}
							}
							else {
								return {
									book: getTitle(book),
									page: `${index + 1}`,
									hebook: book,
									hePage: element.heTitle,
									isLink: false
								}

							}
						}
					})
					return content_counts
				}

			}
			if (!element.content_counts && element.nodes) {

				if (title === '')
					return getAllnodesPrivet(element.nodes, book, sectionName, (element.title).split(' ').join('_'), element.heTitle)
				if (title !== '')
					return getAllnodesPrivet(element.nodes, book, sectionName, (title + ',_' + element.title).split(' ').join('_'), element.heTitle)

			}
			if (element.title) {
				return {
					book: getTitle(book),
					page: ' ,_' + element.title.split(' ').join('_'),
					hebook: book,
					hePage: element.heTitle,
					isLink: true
				}
			}
			else {

				return {
					book: getTitle(book),
					page: `${index + 1}`,
					hebook: book,
					hePage: parseBookPageToHebrew(`${index + 1}`, sectionName),
					isLink: false
				}
			}
		});
	}

	if (nodes.content_counts && !nodes.nodes) {

		return nodes.content_counts.map((item: any, index: any) => {

			let cc = ''
			if (book === 'בית יוסף') {
				cc = pageId
			}
			else if (title === '') {
				cc = "default"
			}
			else {
				cc = getTitle(book) + title
			}
			if (item != 0) {
				return {
					book: cc,
					page: `${index + 1}`,
					hebook: book,
					hePage: parseBookPageToHebrew(`${index + 1}`, sectionName),
					sectionName: nodes.sectionNames,
					isLink: false
				}
			}
		})
	}


	return getAllnodesPrivet(nodes.nodes, book, sectionName, '', '', pageId)

}

export function getAllCategoryNameSub(): Array<string> {

	return ['Writings', 'Prophets', 'Pentateuch']
}

export function isPentateuchIncludes(book: string) {
	if (book)
		book = book.trim().split('_').join(' ')
	if (isPentateuchG(book))
		return true
	if (Devarim.includes(book))
		return true
	if (Psalms.includes(book))
		return true
	if (Joshua.includes(book))
		return true
	else
		return false;
}
export function isPentateuch(book: string) {

	if (Bereshit.includes(book))
		return 'Genesis'
	if (Shemot.includes(book))
		return 'Exodus'
	if (Vayikra.includes(book))
		return 'Leviticus'
	if (Bamidbar.includes(book))
		return 'Numbers'
	if (Devarim.includes(book))
		return 'Deuteronomy'
	else
		return book;
}
export function isShulchanArukh(book: string) {
	const isIncludes = Shulchan_Arukh.filter(a => book.includes(a))
	if (isIncludes.length > 0)
		return true
	return false
}

export function isPentateuchG(book: string) {
	return ['Genesis', 'בראשית', 'שמות', 'ויקרא', 'במדבר', 'דברים',
		'Exodus',
		'Leviticus',
		'Numbers',
		'Deuteronomy'].includes(book)
}


export function getIsSelect(): Array<string> {

	return ['Pentateuch', 'Bavli']
}

export function getAllPages(chapters: any) {
	if (chapters === '')
		return [];
	if (chapters && chapters[0] && chapters[0].chapters) {
		let allPages = chapters[0].chapters.map((chapter: any) => {
			return chapter.split(":")[0];
		});
		const removeDuplicates = Array.from(new Set(allPages.flat()));
		return removeDuplicates;
	}
	return chapters
}

export function getHeTitle(heTitle: string, bookBefor: string[]) {
	if (heTitle) {
		if (heTitle !== bookBefor[1] && bookBefor[1] && bookBefor[1].length > 2 && heTitle.includes(bookBefor[1]))
			return heTitle.replace(bookBefor[1], '')
		if (heTitle.includes(' על תלמוד ירושלמי')) {
			return heTitle.replace(' על תלמוד ירושלמי', '')
		}

		if (heTitle.includes('תלמוד ירושלמי '))
			return heTitle.split('תלמוד ירושלמי')[1]
		if (heTitle.includes(' על משנה'))
			return heTitle.split(' על משנה')[1]
	}





	return heTitle
}