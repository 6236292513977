import React, { useEffect, useState } from "react";
import "./App.css";
import wikiService from "./services/wikiService";
import { matchPath, Route, Routes, useLocation } from "react-router";
import LandingPage from "./components/LandingPage/LandingPage";

import { ReactComponent as CloseSign } from './svg/group-11.svg';
import { ReactComponent as OnkeyEd } from './svg/onkeyEd.svg';
import Cookies from 'js-cookie';
import BookPage from "./components/BookPage/BookPage";
import {
  getPageData,
  setSelectedTextUnitFromUrl,
  setTabOpenRight,
  setCompetition,
  setTabOpenLeft,
  setCommentaryToShowFromUrl,
  setSelectedCommentaryFromUrl,
  setPageNumberFromUrl,
  setBookNameFromUrl
} from "./store/actions/pageActions";
import {
  setMenuBookEmpty,
  setMenuBook,
  setRegistration,
  setCurrentEvents,
  openMenu,
  changeMenuView,
  setOpenPid1,
  setOpenPid,
  setTopicUuid,
  setUsers,
  setRecentchanges
} from "./store/actions/viewActions";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import * as Types from "./store/types/types";

import NotFound from "./components/NotFound/NotFound";
import isMobile from "ismobilejs";
import queryString from "query-string";


const Routes1: React.FC = () => {

  const location = useLocation();
  /*wikiService.getAllViewTopic(1, '', [], '').then((response: any) => {
    response.flow['view-topic'].result[0].map((item: any, index: any) => {

      let line = `<sitemap><url>
<loc>https://shitufta.org.il/statusUuid/${item.topic_id}</loc>
<lastmod>2024-01-03T09:29:28.584Z</lastmod>
</url></sitemap>`;
      console.log(line);
    });

  })*/
  const path = matchPath({ path: "/:book" }, location.pathname);
  let { book } = (path && path.params) || { book: "", page: "" };
  //<Route path="/Search" element={<LandingPage />} />
  return (
    <>
      <Routes>
        <Route path="/about" element={<LandingPage />} />
        <Route path="/contact" element={<LandingPage />} />
        <Route path="/work" element={<LandingPage />} />
        <Route path="/Search" element={<LandingPage />} />
        <Route path="/search" element={<LandingPage />} />
        <Route path="/Shechter/:book" element={<LandingPage />} />
        <Route path="/ironSwords/:book" element={<LandingPage />} />
        <Route path="/navSfarim/:book" element={<LandingPage />} />

        <Route path="/Psalms" element={<LandingPage />} />

        <Route path="/statusUuid/:uuid" element={<LandingPage />} />
        <Route path="/:book" element={<LandingPage />} />

        <Route path="/" element={<LandingPage />} />

        <Route path="/:book/:page" element={book !== 'cohen' && book !== 'Shechter' && book !== 'ironSwords' && book !== 'kaniyevski' ? <BookPage /> : <LandingPage />} />
      </Routes>
    </>
  );
};



const App: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const location = useLocation();
  const [isNone, setIsNone] = useState(window.localStorage.getItem('username'));
  const [isOnkeyEd, setisOnkeyEd] = useState(window.localStorage.getItem('onkeyEd'));
  const error = useSelector((state: any) => state.view.error);
  let pathname = location.pathname;
  if (pathname.split('/').length === 2)
    pathname = pathname.replace('.', '/');
  const path = matchPath({ path: "/:book/:page" }, pathname);




  let { book, page } = (path && path.params) || { book: "", page: "" };



  let currentEvents = '';
  const queryParamsToChange = queryString.parse(location.search);
  for (let paramItem in queryParamsToChange) {
    if (paramItem === "selectedunittext" && paramItem && queryParamsToChange[paramItem]) {
      const selectedTextUnitFromUrl = parseInt(queryParamsToChange[paramItem] as string);
      if (!Number.isNaN(selectedTextUnitFromUrl)) {
        dispatch(setSelectedTextUnitFromUrl(selectedTextUnitFromUrl));
      }
    }
    if (paramItem === "ayun") {
      dispatch(setTabOpenRight(paramItem));
    }
    if (paramItem === "biyur") {
      dispatch(setTabOpenRight(paramItem));
    }
    if (paramItem === "hidushim") {
      dispatch(setTabOpenLeft(paramItem));
    }
    if (paramItem === "registration" && queryParamsToChange !== null && queryParamsToChange[paramItem]) {
      let registration = queryParamsToChange[paramItem];
      if (registration) {
        registration = (registration as string).trim();
      }
      dispatch(setRegistration(registration))
    }
    if (paramItem === "mefarshim") {
      dispatch(setTabOpenRight(paramItem));
    }
    if (paramItem === "Competition") {
      dispatch(setCompetition(paramItem));
    }
    if (paramItem === "currentEvents") {
      let currentEvents = queryParamsToChange[paramItem];
      if (currentEvents) {
        currentEvents = (currentEvents as string).trim();
      }
      if (currentEvents === 'Shechter') {
        dispatch(openMenu());
        dispatch(setCurrentEvents(currentEvents));
        dispatch(changeMenuView('allbooksYomaSub'));
      }
      if (currentEvents === 'ironSwords') {
        dispatch(openMenu());
        dispatch(setCurrentEvents(currentEvents));
        dispatch(changeMenuView('allbooksYomaSub'));
      }
      if (currentEvents === 'cohen') {
        dispatch(openMenu());
        dispatch(setCurrentEvents(currentEvents));
        dispatch(changeMenuView('allbooksYomaSub'));
      }

      if (currentEvents === 'basic') {
        dispatch(openMenu());
        dispatch(setCurrentEvents(currentEvents));
      }

    }
    if (book === 'navSfarim') {
      dispatch(openMenu());
    }
    if (paramItem === "commentary") {
      let commentaryToSelect = queryParamsToChange[paramItem];
      if (commentaryToSelect) {
        commentaryToSelect = (commentaryToSelect as string).trim();
        commentaryToSelect = (commentaryToSelect as string).split(",");

      }
      if (commentaryToSelect && commentaryToSelect.length > 0) {
        dispatch(setSelectedCommentaryFromUrl(commentaryToSelect) as any);
      }
      if ("commentarytoshow" in queryParamsToChange) {
        dispatch(
          setCommentaryToShowFromUrl(queryParamsToChange.commentarytoshow) as any
        );
      }
    }
    if (paramItem === 'statusUuid') {
      dispatch(setTopicUuid(queryParamsToChange[paramItem] as string));
      dispatch(setOpenPid1());
    }
    if (paramItem === 'users') {
      dispatch(setUsers(queryParamsToChange[paramItem] as string));
      dispatch(setOpenPid());
    }

    if (paramItem === 'recentchanges') {
      dispatch(setRecentchanges(queryParamsToChange[paramItem] as string));
      dispatch(setOpenPid1());
    }

  }
  if (book === 'navSfarim') {
    dispatch(openMenu());
  }

  function getError() {
    if (error === 404) {
      return <NotFound text={"האתר בתחזוקה, אנא נסו במועד מאוחר יותר"} />;
    } else if (error === 500) {
      return <NotFound text={"הדף המבוקש אינו קיים"} />;
    } else if (error === "serverdown") {
      return <NotFound text={"האתר בתחזוקה, אנא נסו במועד מאוחר יותר"} />;
    } else {
      return <NotFound text={"תקלה באתר, אנא נסו במועד מאוחר יותר"} />;
    }
  }

  function camelCaseRoute(book: string) {
    let camelBook = book.charAt(0).toUpperCase() + book.slice(1);
    let camelCaseBookName = camelBook
      .split("")
      .map((letter: string, index: number, array) => {
        if (array[index - 1] === "_") {
          return letter.toUpperCase();
        } else {
          return letter;
        }
      })
      .join("");
    return camelCaseBookName;
  }
  const NewBook = camelCaseRoute(book as string);

  useEffect(() => {
    if (
      currentEvents === 'cohen' ||
      currentEvents === 'Shechter' ||
      currentEvents === 'ironSwords' ||
      currentEvents === 'kaniyevski'
    ) {

    }
    else {
      if (book === 'statusUuid') {
        dispatch(setTopicUuid(page ? page : ''));
        wikiService.getUserInfo().then((response: any) => {
          if (response.userinfo && response.userinfo.id !== 0) {
            dispatch({ type: Types.SET_USER_NAME, payload: response.userinfo.name } as any);
            dispatch({ type: Types.SET_USER_ID, payload: response.userinfo.id } as any);
            dispatch({ type: Types.SET_USER_FOLLOWERS, payload: response.userinfo.follow } as any);
          }
          else {
            dispatch({ type: Types.SET_USER_NAME, payload: 0 } as any);
            dispatch({ type: Types.SET_USER_ID, payload: 0 } as any);
          }
        });
      }
      else if (book !== 'navSfarim' && NewBook && page && currentEvents !== 'cohen' && book !== 'Shechter' && book !== 'kaniyevski' && book !== 'ironSwords') {
        dispatch(setBookNameFromUrl(NewBook));
        if ((page === '1a' || page === '1b') && !NewBook.includes('Jerusalem_Talmud')) {
          dispatch(setPageNumberFromUrl('2a'));
          dispatch(getPageData(NewBook, '2a'));
        }
        else {
          dispatch(setPageNumberFromUrl(page));
          dispatch(getPageData(book as string, page));
        }
      }
      else if (book === 'navSfarim') {
        dispatch(setMenuBook(page || '', page || '', 'bookChosen'));
      }
      else {

        dispatch(setMenuBookEmpty());
      }
    }
  }, [book, page, NewBook, currentEvents, dispatch]);


  return (
    <>
      <div className={`App ${!Cookies.get('onkeyEd') && (isOnkeyEd === '1' || isOnkeyEd === '3' || isOnkeyEd === '5') ? 'show-all' : ''}`}
        onClick={() => {
          if (Cookies.get('onkeyEd')) {
            return;
          }
          if (isOnkeyEd === '1') {
            window.localStorage.setItem('onkeyEd', '2');
            Cookies.set('onkeyEd', '2');
            setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
          }
          if (isOnkeyEd === '3') {
            window.localStorage.setItem('onkeyEd', '4');
            setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
            Cookies.set('onkeyEd', '2');
          }
          if (isOnkeyEd === '5') {
            window.localStorage.setItem('onkeyEd', '6');
            setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
            Cookies.set('onkeyEd', '2');
          }
        }}
      >
        <Routes1 />

        {error && getError()}
        {!(isMobile().phone || isMobile().tablet) && isNone === '1' && <div className='fixed1' id='fixed1'>
          <CloseSign className='rrrrrrrrr'
            onClick={() => {

              window.localStorage.setItem('username', '2');
              setIsNone(window.localStorage.getItem('username'))
            }}
          />
          {!(isMobile().phone || isMobile().tablet) && <div style={{ display: 'flex' }}

          > {'מצאת בעיה במאגר? תרצה לומר לנו משהו? נשמח לדעת!'}<div
            className={'tttttttt'}
            onClick={() => {
              window.open('https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=%D7%9E%D7%A9%D7%95%D7%91+%D7%A9%D7%99%D7%AA%D7%95%D7%A4%D7%AA%D7%90+&to=office@shitufta.org.il', 'new_window')
              setIsNone(window.localStorage.getItem('username'))
            }}>{' צור קשר'}
            </div>
          </div>}

        </div>}

      </div>
      {!Cookies.get('onkeyEd') && (isOnkeyEd === '1' || isOnkeyEd === '3' || isOnkeyEd === '5') &&
        <div className={(isMobile().phone || isMobile().tablet) ? 'app-advertising-yoma-mobile' : "app-advertising-yoma"}>
          <div className='onkeyEd-onClick'
            onClick={() => {
              if (Cookies.get('onkeyEd')) {
                return;
              }
              if (isOnkeyEd === '1') {
                window.localStorage.setItem('onkeyEd', '2');
                Cookies.set('onkeyEd', '2');
                setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
              }
              if (isOnkeyEd === '3') {
                window.localStorage.setItem('onkeyEd', '4');
                setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
                Cookies.set('onkeyEd', '2');
              }
              if (isOnkeyEd === '5') {
                window.localStorage.setItem('onkeyEd', '6');
                setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
                Cookies.set('onkeyEd', '2');
              }
            }}
          ></div>
          <CloseSign className='rrrrrrrrr-CloseSign'
            onClick={() => {
              if (Cookies.get('onkeyEd')) {
                return;
              }
              if (isOnkeyEd === '1') {
                window.localStorage.setItem('onkeyEd', '2');
                Cookies.set('onkeyEd', '2');
                setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
              }
              if (isOnkeyEd === '3') {
                window.localStorage.setItem('onkeyEd', '4');
                setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
                Cookies.set('onkeyEd', '2');
              }
              if (isOnkeyEd === '5') {
                window.localStorage.setItem('onkeyEd', '6');
                setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
                Cookies.set('onkeyEd', '2');
              }
            }}
          />
          <a href="https://shitufta.org.il/Order.html?utm_source=shitufta" rel="noopener noreferrer" target={'_blank'}
            onClick={() => {
              if (Cookies.get('onkeyEd')) {
                return;
              }
              if (isOnkeyEd === '1') {
                window.localStorage.setItem('onkeyEd', '2');
                Cookies.set('onkeyEd', '2');
                setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
              }
              if (isOnkeyEd === '3') {
                window.localStorage.setItem('onkeyEd', '4');
                setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
                Cookies.set('onkeyEd', '2');
              }
              if (isOnkeyEd === '5') {
                window.localStorage.setItem('onkeyEd', '6');
                setisOnkeyEd(window.localStorage.getItem('onkeyEd'))
                Cookies.set('onkeyEd', '2');
              }
            }}
          >
            <OnkeyEd className="Poto_02" />

          </a>

        </div>
      }
    </>

  );
};

export default App;
