import React, { useState, useEffect } from "react";
import "./OpenPid1.css";
import { ReactComponent as MihlolLogo } from "../../svg/logoqq.svg";
import { useSelector, useDispatch } from "react-redux";
import BlockAnnonimus from "../BlockAnnonimus/BlockAnnonimus";
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as Line } from "../../svg/HidushimLineShare.svg";
import { ReactComponent as SearchCircle } from "../../svg/searchCircle.svg";
import {
  getIsPidOpen1,
  getUserName,
  getMyTopics,
  getAllTopics,
  getAllTopicsIds,
  getPageId,
  getTopicUuid
} from "../../store/selectors/selectors";
import { ReactComponent as BookUserHidush } from "../../svg/bookUserHidush.svg";
import isMobile from "ismobilejs";
import ShowFeed from "../ShowFeed/ShowFeed";
import {
  setclosePid1,
  setTopicUuid,
  setMyTopic
} from "../../store/actions/viewActions";
import {
  setAllTopics,
  setAllTopicsIds
} from "../../store/actions/pageActions";
import InfiniteScroll from 'react-infinite-scroll-component';
import wikiService from "../../services/wikiService";
import { ThunkDispatch } from "@reduxjs/toolkit";
import history from "../../history/history";
import DOMPurify from 'dompurify';
const OpenPid1 = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isPidOpen1 = useSelector(getIsPidOpen1);
  const myTopics = useSelector(getMyTopics);
  const userName = useSelector(getUserName);
  const allTopics = useSelector(getAllTopics);
  const pageId = useSelector(getPageId);
  const statusUuid = useSelector(getTopicUuid);
  const [progress, setProgress] = useState(0);
  const allTopicsIds = useSelector(getAllTopicsIds);
  const [continu, setContinu] = useState(1);
  const [isEnd, setIsEnd] = useState(true);
  const [isLoading2, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');


  function ValidateName(Field: string) {
    let AlphaNumericChars = new RegExp("^[\u0590-\u05FF]+$");
    if (!AlphaNumericChars.test(Field.split(' ').join(''))) {
      return false;
    } else
      return true;
  }

  useEffect(() => {
    if (!isPidOpen1) {
      if (searchText !== '') {
        setSearchText('');
      }
      if (allTopics.length !== 0) {
        dispatch(setAllTopics([]))
      }
      if (allTopicsIds.length !== 0)
        dispatch(setAllTopicsIds([]))
      setIsEnd(true)
      setContinu(1)
      return;
    }
    if (isPidOpen1) {
      if (searchText !== '') {

      }
      else if (statusUuid !== '' && myTopics === '') {
        if (allTopics.length === 0)
          wikiService.getOneTopic(statusUuid).then((response) => {
            dispatch(setAllTopicsIds(response?.items2))
            let newArray = [];
            for (const prop in response?.workflowId) {
              if (prop !== 'undefined') {
                newArray.push(response?.workflowId[prop][0])
              }
            }
            setContinu(2)

            const array3 = allTopics.concat(newArray);

            dispatch(setAllTopics(array3))
            setIsLoading(true)
          });
      }
      else if ((continu === 1) || (allTopics.length === 0 && isPidOpen1 && myTopics !== 'my')) {

        wikiService.getViewTopic('1', myTopics, allTopicsIds).then((response) => {
          dispatch(setAllTopicsIds(response?.items2))
          let newArray = [];
          for (const prop in response?.workflowId) {
            if (prop !== 'undefined') {
              newArray.push(response?.workflowId[prop][0])
            }
          }
          setContinu(15)
          if (newArray.length < 14) {
            setIsEnd(false)
          }
          const array3 = allTopics.concat(newArray);

          dispatch(setAllTopics(array3))
          setIsLoading(true)
        });
      }

    }
    const timer = setInterval(() => {
      if (isEnd && !isLoading2)
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 20));
    }, 800);


    return () => {
      clearInterval(timer);
    };

  }, [isPidOpen1, dispatch, myTopics, allTopicsIds, allTopics, continu, isEnd]);

  const steStatusTitle = (name: any, x: any) => {
    if (myTopics === '')
      dispatch(setMyTopic(name.creator.id + '`' + name.creator.name));

    return (
      <span className="recent-feed-main-item-icon-text">
        {!name.creator.name.includes('מתורתו') && 'חידושו של'}{!name.creator.name.includes('מתורתו') && <br />}
        {name.creator.name}
      </span>
    )
  }

  const loadFunc = () => {
    if (!isEnd)
      return;
    setIsLoading(false)
    setContinu(continu + (continu === 1 ? 14 : 15))
    RecentHidushim(continu + (continu === 1 ? 14 : 15), searchText);
    setIsLoading(true)
  }



  const RecentHidushim = (offset: any, auprefix = '') => {
    if (!userName)
      return;


    wikiService.getViewTopic(offset, myTopics, allTopicsIds, auprefix).then((response) => {
      dispatch(setAllTopicsIds(response?.items2))
      let newArray = [];
      for (const prop in response?.workflowId) {
        if (prop !== 'undefined') {
          newArray.push(response?.workflowId[prop][0])
        }
      }


      if (newArray.length < 14) {
        setIsEnd(false)
      }
      const array3 = allTopics.concat(newArray);
      dispatch(setAllTopics(array3))

    });
  }

  const sreach = (auprefix: any) => {
    if (isPidOpen1) {
      dispatch(setAllTopics([]))
      wikiService.getViewTopic(1, myTopics, [], auprefix).then(
        (response) => {
          dispatch(setAllTopicsIds(response?.items2))
          let newArray = [];
          for (const prop in response?.workflowId) {
            if (prop !== 'undefined') {
              newArray.push(response?.workflowId[prop][0])
            }
          }
          /*if (newArray.length < 14) {
            setIsEnd(false)
          }*/
          setIsLoading(false)
          dispatch(setAllTopics(newArray))

          setIsEnd(true)
          setIsLoading(true)
        }
      )
    }
  }

  const handleChange = (e: any) => {
    if (ValidateName(e.currentTarget.value))
      setSearchText(e.currentTarget.value);
  }

  return (
    <div
      className={isPidOpen1 &&
        !(isMobile().phone || isMobile().tablet) ? `header-openPid ${pageId === '' ? 'pageId' : ''}` : isPidOpen1 && (isMobile().phone || isMobile().tablet) ? `header-openPidD` : "header-hidden"}
      id={"openPid1"}
    >
      {!(isMobile().phone || isMobile().tablet) && <div className='onclick-close'
        onClick={() => {
          history.push('?');
          dispatch(setTopicUuid(''))
          dispatch(setclosePid1())
        }} >
      </div>}
      <div
        className={myTopics === '' ? "InfiniteScroll-list" : 'InfiniteScroll-list-my-topics'}
        id="InfiniteScroll-list"
      >
        {allTopics &&
          <InfiniteScroll
            dataLength={allTopics.length}
            hasMore={true}
            next={loadFunc}
            loader={
              <div className="loading-indicator-loadingsvg10" key={'g'}>
                {isEnd && <CircularProgress color={"secondary"} thickness={1} size={"2rem"} variant="determinate" value={progress} />}
                {isEnd && <MihlolLogo className="loading-indicator-gmara" />}
              </div>}
            pullDownToRefreshThreshold={100}
            scrollableTarget="openPid1"
          >
            {statusUuid === '' && myTopics === '' &&
              isPidOpen1 && <div className={pageId === '' ? 'feed1-search-main-home-page' : "feed1-search-main"}>
                <div className={`feed1-search ${(isMobile().phone || isMobile().tablet) ? 'users-search-mobile' : ''}`}>
                  <input className={`users-search-input  ${(isMobile().phone || isMobile().tablet) ? 'users-search-input-mobile' : ''}`}
                    placeholder="חפש חידוש"
                    maxLength={20}
                    onChange={(e) => {
                      if ((userName === 0))
                        dispatch(setclosePid1())
                      else
                        handleChange(e)
                    }}
                    onKeyPress={(e) => {

                      if (e.key === 'Enter') {
                        sreach(searchText)
                      }
                    }}
                    onClick={(e) => {
                      if ((userName === 0))
                        dispatch(setclosePid1())
                      else
                        handleChange(e)
                    }}
                  >
                  </input>
                  <div className='users-search-circle'
                    onClick={() => {
                      sreach(searchText)

                    }}
                  >
                    <SearchCircle />
                  </div>
                </div>
              </div>
            }

            {allTopics.map((x: any, index: any) => {

              if (x === undefined) {
                return (<></>)
              }
              let root = x.roots[0];
              let post = x.posts[root];

              return (

                <div className={`${(userName === 0 && statusUuid === '') ? 'show-all' : `show-all-list`}`}
                  onClick={() => {
                    if ((userName === 0))
                      dispatch(setclosePid1());
                  }}
                  key={index}
                >

                  {statusUuid === '' && myTopics !== '' && index === 0 &&
                    <div className="recent-feed">

                      <div className="recent-feed-main-item-icon">
                        <BookUserHidush />
                        {<span className="recent-feed-main-item-icon-text">
                          {'חידושי הרב '}{<br />}
                          {myTopics.includes('תורתו') && myTopics.split('`')[1].replace('מתורתו של', '')}
                          {!myTopics.includes('תורתו') && myTopics.split('`')[1]}
                          {myTopics === 'my' && userName.replace('הרב', '')}
                        </span>}
                      </div>
                    </div>}
                  {index === 0 && statusUuid !== '' &&
                    <>
                      <div className="recent-feed">
                        <div className="recent-feed-main-item-icon">
                          <BookUserHidush />
                          {steStatusTitle(x.revisions[post], x.workflowId)}
                        </div>
                      </div>
                    </>}
                  {index === 0
                    && <ShowFeed
                      topic={x.revisions[post]}
                      isTitle={true}
                      revisions={x.revisions}
                      posts={x.posts}
                      LikedCounts={allTopicsIds}
                      level={0}
                      statusUuid={statusUuid}
                      searchText={searchText}
                      increment={() => { }}
                    />}
                  {index > 0 && x.workflowId !== window.location.pathname.split('/')[2] &&
                    <ShowFeed
                      topic={x.revisions[post]}
                      isTitle={true}
                      revisions={x.revisions}
                      posts={x.posts}
                      LikedCounts={allTopicsIds}
                      level={0}
                      statusUuid={''}
                      searchText={searchText}
                      increment={() => { }}
                    />}
                  {index === 0 && statusUuid !== '' && <>
                    <div className="recent-feed-line-2">
                      <Line />
                    </div>
                  </>}
                </div>)
            })
            }
          </InfiniteScroll>}
      </div>

      {isPidOpen1 && !isLoading2 && (userName === 0 && statusUuid !== '') &&
        <BlockAnnonimus text={'<b>התחבר או הירשם</b><br>על מנת לצפות בחידושים יש להתחבר או להירשם'} />
      }
    </div>
  );
};

export default OpenPid1;
