import config from "./configService";
import Axios from "axios";
import books from "../book/booksAndChapters.json";
import wikiService from "./wikiService";
import {
  isPentateuch
} from "../utils/booksAndChapters2";
class MenuService {
  async getBookPagesAndChapters(book: string, seder: any = '', bookNew: string = '') {
    try {

      let c = '';
      if (seder !== '')
        c = seder;
      else {
        c = book;
      }
      if (c === '') {

        return;
      }
      let shitufta_api_url = config.shitufta_api_url
      c = isPentateuch(c);


      const res: any = await Axios.get(
        `${shitufta_api_url}v2/index/${c}?with_content_counts=1`
      );


      let content_counts_menu = res.data.schema.content_counts;
      if (res.data.alts && res.data.alts.Parasha && !res.data.alts.Chapters && res.data.categories[0] !== 'Midrash' && res.data.categories[1] !== 'Targum') {
        content_counts_menu = [res.data.alts.Parasha.nodes, res.data.schema.content_counts];
      }
      else if (!content_counts_menu && res.data.schema.nodes) {

        if (res.data.categories[1] === "Tur") {
          content_counts_menu = res.data.schema.nodes.filter((obj: any) => {

            return bookNew.split('_').join(' ').includes(obj.title)
          })

          content_counts_menu = content_counts_menu[0];
        }
        else
          content_counts_menu = res.data.schema.nodes;
      }
      else if (res.data.schema && res.data.schema.key === "Shulchan Arukh, Even HaEzer") {
        content_counts_menu = res.data.schema.nodes[0].content_counts;
      }

      let sectionNames = res.data.sectionNames;
      if (!sectionNames && res.data.schema.nodes && res.data.schema.nodes[0] && res.data.schema.nodes[0].sectionNames) {
        sectionNames = res.data.schema.nodes[0].sectionNames;
      }

      let categoryName = '';

      if (res.data.categories[1] === 'Bavli') {
        categoryName = 'talmud'
      }
      else if (res.data.categories[1] === 'Torah') {
        categoryName = 'teanach'
      }
      else if (res.data.categories[1] === 'Mishneh Torah') {
        categoryName = 'Mishneh Torah'
      }
      else if (res.data.categories[1] === 'Prophets' || res.data.categories[1] === 'Writings') {
        categoryName = 'Prophets'
      }
      else if (res.data.categories[1] === 'Shulchan Arukh') {
        categoryName = 'Shulchan_Arukh';
        if (res.data.categories[3] && res.data.categories[3] === 'Mishnah Berurah') {
          categoryName = 'Mishnah_Berurah';
        }
        else {

        }
      }//Prophets
      else if (res.data.categories[0] === 'Liturgy' && res.data.categories[1] === 'Haggadah') {
        categoryName = 'Haggadah'
      }




      let schema: any;
      if (res.data.alts && res.data.categories[0] !== 'Midrash') {

        if (res.data.alts.Chapters) {
          schema = res.data.alts.Chapters.nodes;
        }
        else if (res.data.alts.Topic) {
          schema = res.data.alts.Topic.nodes;
        }
        else if (res.data.alts.Parasha) {
          schema = res.data.alts.Parasha.nodes;
        }
        else {
          schema = ''
        }

      }
      else {
        if (res.data.schema.nodes) {
          schema = res.data.schema.nodes;
        }
        else {
          schema = '';
        }
      }
      const dividedByChapter = this.divideByChapterName(
        schema,
        categoryName
      );




      const getAllPages = this.getAllPages(schema);

      if (getAllPages.length === 0 && (categoryName !== 'Haggadah' || res.data.categories[0] !== 'Chasidut')) {
        if (res.data.schema.lengths)
          for (let index = 1; index < res.data.schema.lengths[0] + 1; index++) {
            getAllPages.push(c + ` ${index}`)
          }
        else {
          if (res.data.schema.content_counts)
            for (let index = 1; index < res.data.schema.content_counts.length; index++) {
              getAllPages.push(c + ` ${index}`)
            }
        }

      }
      const list = dividedByChapter.filter((element: any) => {
        return element['chapterNumber'] === book.replace('_', ' ');
      });

      if (list.length === 0)
        return [dividedByChapter, getAllPages, '', content_counts_menu, sectionNames, res.data.heTitle, res.data.categories];
      else if (res.data.categories[0] === 'Liturgy' && res.data.categories[1] === 'Haggadah') {
        let nee = list[0]['chapters'].map((x: any) => x.split(':')[0])

        list[0]['chapters'] = list[0]['chapters'].filter(function (item: string, pos: string) {

          return nee.indexOf(item.split(':')[0]) === pos;
        })

        return [
          dividedByChapter,
          list[0]['chapters'],
          '',
          content_counts_menu,
          sectionNames,
          res.data.heTitle,
          res.data.categories
        ];
      }
      else {
        return [
          dividedByChapter,
          list[0]['chapters'],
          '',
          content_counts_menu,
          sectionNames,
          res.data.heTitle,
          res.data.categories
        ];
      }

    } catch (err) {
      // console.error(err);
      return undefined;
    }
  }




  async getUserData() {

    try {
      const allBooksNamesPromise = await Promise.resolve({ data: books });//TO DO remove promise
      const userInfo = await wikiService.getUserInfo();
      const allUsers = await wikiService.AllUsers(userInfo.userinfo.name);

      if (userInfo.userinfo && userInfo.userinfo.id !== 0) {

        const notificationsAll = await wikiService.getNotifications('list', 'read|!read', '');
        return [allBooksNamesPromise, notificationsAll, userInfo, '', allUsers];
      }
      else {

      }
      return [allBooksNamesPromise, [], [], userInfo, allUsers];
    } catch (err) {
      // console.error(err);
      return undefined;
    }
  }



  private divideByChapterName(chapters: any, categoryName = '') {

    if (chapters === '' || !chapters) {
      return []
    }

    if (categoryName === '' || categoryName === 'teanach' || categoryName === 'Shulchan_Arukh' || categoryName === 'talmud')
      return chapters.map((chapter: any) => {
        const newChapters: any = [];
        if (chapter.refs) {
          chapter.refs.forEach((page: string) => {
            if (page.includes(":")) {
              newChapters.push(page.split("-")[0]);
            } else {
              newChapters.push(page);
            }
          });

          return {
            chapterName: chapter.heTitle,
            chapterNumber: chapter.title,
            chapters: newChapters
          };
        }
        else {
          return {
            chapterName: chapter.heTitle,
            chapterNumber: chapter.title,
            chapters: ''
          };
        }
      });
    else
      return chapters.map((chapter: any) => {
        return {
          chapterName: chapter.heTitle,
          chapterNumber: chapter.title,
          chapters: chapter.nodes
        };
      });
  }

  private getAllPages(chapters: any) {
    if (chapters === '')
      return [];

    let allPages = chapters.map((chapter: any) => {

      const newChapters: any = [];
      if (chapter.refs)
        chapter.refs.forEach((page: string) => {
          if (page.includes(":")) {
            newChapters.push(page.split(":")[0]);
          } else {
            newChapters.push(page);
          }
        });

      return newChapters;
    });

    const removeDuplicates = Array.from(new Set(allPages.flat()));
    return removeDuplicates;
  }
}
export default new MenuService();
