export const getMainText = (state: any) => state.page.pageData.text || null;
export const getAllcommentary = (state: any) => state.page.pageData.commentary;
export const getCommentarySefarim = (state: any) =>
  state.page.pageData.commentarySefarim;
export const getPageNumberFromUrl = (state: any) =>
  state.page.pageNumberFromUrl ;
export const getSelectedcommentary = (state: any) =>
  state.page.selectedCommentary;
export const getPageId = (state: any) => state.page.pageData.id;
export const getPageTitle = (state: any) => state.page.pageData.pageTitle;
export const getBookName = (state: any) =>
  state.page.pageData.titles.book || null;
export const getSederName = (state: any) =>
  state.page.pageData.navigation.seder || null;
export const getSubPage = (state: any) =>
  state.page.pageData.navigation.subPage;
export const getOpenTabsRight = (state: any) => state.view.openTabsRight;
export const getOpenTabsLeft = (state: any) => state.view.openTabsLeft;
export const getSelectedUnitText = (state: any) => state.page.selectedUnitText;
export const getSelectedUnitTextFromUrl = (state: any) =>
  state.page.selectedUnitTextFromUrl;
export const getCommentaryToShow = (state: any) => state.page.commentaryToShow;
export const getCommentaryToShowFromUrl = (state: any) =>
  state.page.commentaryToShowFromUrl;
export const getSelectedCommentaryFromUrl = (state: any) =>
  state.page.selectedCommentaryFromUrl;
export const getLayoutData = (state: any) => state.page.layoutData;
export const getIsLoading = (state: any) => state.view.isLoading;
export const getNextPage = (state: any) => state.page.pageData.navigation.next;
export const getPrevPage = (state: any) => state.page.pageData.navigation.prev;
export const getIsSfarimOn = (state: any) => state.view.isSfarimOn;
export const getIsBiyur = (state: any) => state.view.isBiyur;
export const getIsOpenNavPage = (state: any) => state.view.isOpenNavPage;
export const getIsOpenSearch = (state: any) => state.view.isOpenSearch;
export const getIsArticles = (state: any) => state.view.isArticles;
export const getIsUserProfile = (state: any) => state.view.isUserProfile;
export const getIsUserProfileOpen = (state: any) => state.view.isUserProfileOpen;
export const getIsMenuOpen = (state: any) => state.view.menu.isOpen;
export const getIsMenuOpenYoma = (state: any) => state.view.menuYoma.isOpen;
export const getIsMenuOpen1 = (state: any) => state.view.menu1.isOpen;
export const getIsPidOpen = (state: any) => state.view.pid.isOpen;
export const getIsOnLinePartyOpen = (state: any) => state.view.onLineParty.isOpen;
export const getIsPidOpen1 = (state: any) => state.view.pid1.isOpen;
export const getIsPidOpen2 = (state: any) => state.view.pid2.isOpen;
export const getIsBiyurScrolling = (state: any) => state.view.isBiyurScrolling;
export const getHeTitleBook = (state: any) => state.view.menu.heBook;
export const getTitleBook = (state: any) => state.view.menu.book;
export const getCurrentBook = (state: any) =>
  state.page.pageData.navigation.book;
export const getAllBooks = (state: any) => state.page.pageData.allbooksNames;
export const getLicense = (state: any) => state.page.pageData.license;
export const getAlts = (state: any) => state.page.pageData.navigation.alts;
export const getMenuView = (state: any) => state.view.menu.view;
export const getMenuViewYoma = (state: any) => state.view.menuYoma.view;
export const getMenuChapters = (state: any) => state.view.menu.chapters;
export const getMenuChaptersYoma = (state: any) => state.view.menuYoma.chapters;
export const getMenuAllPages = (state: any) => state.view.menu.allPages;
export const getMenuAllPagesYoma = (state: any) => state.view.menuYoma.allPages;
export const getMenuChaptersView = (state: any) => state.view.menu.chaptersView;
export const getMenuChaptersViewYoma = (state: any) => state.view.menuYoma.chaptersView;
export const getCurrentPageTitle = (state: any) =>
  state.page.pageData.titles.currentPageTitle;
export const getChapterNumberTitle = (state: any) =>
  state.page.pageData.titles.chapterNumberTitle;
export const getChapterTitle = (state: any) =>
  state.page.pageData.titles.chapterTitle;
export const getUserName = (state: any) =>
  state.view.user.name;
export const getUserPage = (state: any) =>
  state.view.user.page;
export const getUserid = (state: any) =>
  state.view.user.id;
export const getUserFollower = (state: any) =>
  state.view.user.follow;
export const getCategoryName = (state: any) =>
  state.page.pageData.navigation.categoryName || null;
export const getAllTopics = (state: any) =>
  state.view.allTopics || [];
export const getAllTopicsIds = (state: any) =>
  state.view.allTopicsIds || [];
export const getIsIfame = (state: any) =>
  state.view.isIfame;
export const getNotificationsAll = (state: any) =>
  state.view.notificationsAll || null;
export const getNotificationsOpen = (state: any) =>
  state.view.notificationsOpen || null;
export const getEmailOpen = (state: any) =>
  state.view.emailOpen || null;
export const getTextNumber = (state: any) =>
  state.page.pageData.text || null;
export const getPageHidushim = (state: any) =>
  state.view.pageHidushim || [];
export const getCompetition = (state: any) =>
  state.view.competition || null;
export const getTopicsFollow = (state: any) =>
  state.view.topicsFollow || null;
export const getIsTraile = (state: any) =>
  state.view.isTraile;
export const getMyTopics = (state: any) =>
  state.view.myTopics;
export const getUserProfileView = (state: any) =>
  state.view.userProfileView;
export const getBookListAll = (state: any) =>
  state.view.bookListAll || [];
export const getProjectNameShasmehulak = (state: any) =>
  state.view.projectNameShasmehulak;
export const getBookYoma = (state: any) =>
  state.view.bookYoma;
export const getPageYoma = (state: any) =>
  state.view.pageYoma;
export const getRegistration = (state: any) =>
  state.view.registration;
export const getTopicUuid = (state: any) =>
  state.view.statusUuid;
export const getContentCountsMenu = (state: any) =>
  state.view.content_counts_menu || [];
export const getResultFromSearch = (state: any) =>
  state.view.resultFromSearch;
export const getSectionNames = (state: any) =>
  state.view.sectionNames;
export const getNumberOfmefarshim = (state: any) =>
  state.page.numberOfmefarshim;
export const getIsOpenTemplate = (state: any) =>
  state.view.isOpenTemplate;
export const getCkEdittorContent = (state: any) =>
  state.view.ckEdittorContent;