import Axios from "axios";
import config from "./configService";
import { manualDataFixes, manualDataFixesSfarim } from "../utils/dataUtils";
import books from "../book/booksAndChapters.json";
import {
  isPentateuch
} from "../utils/booksAndChapters2";
const chapterNumberTitleHeb = {
  "Chapter 1": "פרק ראשון",
  "Chapter 2": "פרק שני",
  "Chapter 3": "פרק שלישי",
  "Chapter 4": "פרק רביעי",
  "Chapter 5": "פרק חמישי",
  "Chapter 6": "פרק שישי",
  "Chapter 7": "פרק שביעי",
  "Chapter 8": "פרק שמיני",
  "Chapter 9": "פרק תשיעי",
  "Chapter 10": "פרק עשירי",
  "Chapter 11": "פרק אחד עשר",
  "Chapter 12": "פרק שניים עשר",
  "Chapter 13": "פרק שלוש עשר",
  "Chapter 14": "פרק ארבע עשר",
  "Chapter 15": "פרק חמש עשר",
  "Chapter 16": "פרק שש עשר",
  "Chapter 17": "פרק שבעה עשר",
  "Chapter 18": "פרק שמונה עשר",
  "Chapter 19": "פרק תשע עשר",
  "Chapter 20": "פרק עשרים",
  "Chapter 21": "פרק אחד ועשרים",
  "Chapter 22": "פרק שניים ועשרים",
  "Chapter 23": "פרק שלוש ועשרים",
  "Chapter 24": "פרק ארבע ועשרים"
};
function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

function isANumber(str: any) {
  return !/\D/.test(str);
}
class PageService {

  async getPage(book: string, pageNumber: string) {
    const date = new Date().toISOString();
    if (pageNumber)
      pageNumber = pageNumber.trim();
    if (book)
      book = book.trim();

    try {
      if (book === 'Song_Of_Songs') {
        book = 'Song_of_Songs'
      }
      if (book === 'Ran_On_Avodah_Zarah') {
        book = 'Ran_on_Avodah_Zarah'
      }
      if (book === 'Mishnah_avot' || book === 'Mishnah_Avot') {
        book = 'Pirkei Avot'
      }
      if (book.includes('Baba')) {
        book = book.replace('Baba', 'Bava')
      }
      /* if (pageNumber.includes(',_Chapter')) {
         book += pageNumber;
       }*/



      book = isPentateuch(book);

      book = book.split('%2C').join(',');


      //TODO need to change name its not layout
      //getting chapters data
      //if book is tow words
      // book = book.split('_').join(' ');
      let shitufta_api_url = config.shitufta_api_url;
      let shitufta_api_url_links = config.shitufta_api_url;

      const chaptersPromise = await Axios.get(
        `${shitufta_api_url}v2/index/${book.split(',_')[0].trim().split('%2C').join(',')}?with_content_counts=1`
      );


      let categoriesTalmud = [];
      if (chaptersPromise.data.categories) {
        categoriesTalmud = chaptersPromise.data.categories
      }
      if ((categoriesTalmud[0] === 'Talmud' &&
        categoriesTalmud[1] === 'Bavli' &&
        !categoriesTalmud[2].includes('Minor Tractates') &&
        categoriesTalmud.length <= 3)) {
        shitufta_api_url = config.shitufta_api_url_dev;
      }

      //תיקון לפרקים בגמרות הביא רק חלק מהדף 
      if (chaptersPromise && chaptersPromise.data.sectionNames && chaptersPromise.data.sectionNames[0] === 'Daf') {
        pageNumber = pageNumber.split('.')[0]
      }
      if (pageNumber &&
        !(categoriesTalmud[0] === "Tanakh" && categoriesTalmud[1] === "Torah") &&
        !(categoriesTalmud[0] === "Tanakh" && categoriesTalmud[1] === "Prophets") &&
        !(categoriesTalmud[0] === "Tanakh" && categoriesTalmud[1] === "Writings")) {
        pageNumber = pageNumber.split('.')[0].split(':')[0];
      }



      //if book is tow words
      book = book.split(' ').join('_');
      book = capitalize(book)
      if (pageNumber === '~') {
        pageNumber = chaptersPromise.data.firstSectionRef.split(' ').slice(-1)[0].split(':')[0].split('.')[0]
      }
      if (chaptersPromise.data &&
        chaptersPromise.data.schema &&
        chaptersPromise.data.schema.sectionNames &&
        chaptersPromise.data.schema.sectionNames[0] === 'Daf' && pageNumber &&
        isANumber(pageNumber)
      ) {
        pageNumber = pageNumber + 'a'
      }

      let categories = chaptersPromise.data.categories;
      let heCategories = chaptersPromise.data.heCategories;
      const base_text_titles = chaptersPromise.data.base_text_titles;

      let version = '';
      if (
        categories[0] === 'Talmud' &&
        categories[1] === 'Bavli' &&
        !categories[2].includes('Minor Tractates') &&
        categories.length <= 3
      ) {
        version = '&vhe=Wikisource_Talmud_Bavli';
      }
      if (
        categories[0] === "Halakhah" &&
        categories[1] === "Mishneh Torah" &&
        categories[2] !== "Commentary"
      ) {
        version = '&vhe=Wikisource_Mishneh_Torah';
      }
      if (
        book.includes('Rashi_on_Lamentations')
      ) {
        version = '&vhe=On_Your_Way';
      }
      if (
        categories[0] === 'Talmud' &&
        categories[1] === 'Bavli' &&
        categories[2].includes('on Talmud')
      ) {

        version = '&pad=0';
      }


      if (categories === 'Haggadah') {

      }
      else if ((categories[0] === 'Tanakh' && categories.length === 2) && !pageNumber.includes('.') && !pageNumber.includes('-')) {
        pageNumber += '.1-3'
        pageNumber = pageNumber.split(" ").join("");
      }
      else if (pageNumber && pageNumber.includes('.') && !pageNumber.includes('-')) {
        let v = Number(pageNumber.split(".")[1]);
        if (v % 3 === 0) {
          pageNumber = pageNumber.split(".")[0] + `.${v - 2}-${v}`
        }
        else if (v % 3 === 1) {
          pageNumber = pageNumber.split(".")[0] + `.${v}-${v + 2}`
        }
        else if (v % 3 === 2) {
          pageNumber = pageNumber.split(".")[0] + `.${v - 1}-${v + 1}`
        }
      }
      let commentary = `commentary=0${categories[0] === 'Talmud' && categories[1] === 'Bavli' ? '0' : '0'}${version}&`;
      if ((book === 'Sanhedrin' && pageNumber === '100b')) {
        commentary = '';
      }
      // )  // Making request to get the page data from Sefaria API / Our data
      let dataTextPromise;





      if (categories[0] === 'Talmud' && (categories[1] === 'Bavli') && categories.length <= 3) {
        dataTextPromise = Axios.get(
          `${shitufta_api_url}texts/${capitalize(book)}${(categories[1] === 'Haggadah' || pageNumber === '' || (pageNumber.includes(',') && categories.length === 1) || (pageNumber.includes(',') && categories[0] !== 'Halakhah')) ? '' : '.'}${pageNumber}?${commentary}context=0&pad=0&wrapLinks=0&multiple=0`//&vhe=William_Davidson_Edition_-_Aramaic`
        ).then((dataRes) => {

          document.title = `${dataRes.data.heRef.replace('שטיינזלץ', '').replace('״', '').replace('׳', '')} - שיתופתא`;
          const he = dataRes.data.he;
          let text = '';
          if (typeof he === 'object') {
            text = he.join(' ').split('<')[0]
          }
          else {
            text = he.split('<')[0];
          }
          let allInputsEl = document.querySelectorAll(`meta[name="description"]`);
          if (allInputsEl[0])
            allInputsEl[0].setAttribute("content", text)
          allInputsEl = document.querySelectorAll(`meta[name="og:description"]`);
          if (allInputsEl[0])
            allInputsEl[0].setAttribute("content", text)
          allInputsEl = document.querySelectorAll(`meta[name="og:title"]`);
          if (allInputsEl[0])
            allInputsEl[0].setAttribute("content", `${dataRes.data.heRef.replace('שטיינזלץ', '').replace('״', '').replace('׳', '')} - שיתופתא`)
          allInputsEl = document.querySelectorAll(`meta[name="og:url"]`);
          if (allInputsEl[0]) {
            let url = dataRes.data.ref.split(' ');
            var removed = url.splice(-1);
            allInputsEl[0].setAttribute("content", `${window.location.origin}/${url.join('_')}/${removed}`)

          }

          return dataRes
        }

        );
      }
      else if (categories[0] === 'Liturgy') {

        dataTextPromise = Axios.get(
          `${shitufta_api_url}texts/${capitalize(book)}${(categories[1] === 'Haggadah' || pageNumber === '' || (pageNumber.includes(',') && categories.length === 1) || (pageNumber.includes(',') && categories[0] !== 'Halakhah')) ? '' : '.'}${pageNumber}?${commentary}pad=0&wrapLinks=0&multiple=0`//&vhe=William_Davidson_Edition_-_Aramaic`
        ).then((dataRes) => {
          document.title = `${dataRes.data.heRef.replace('שטיינזלץ', '').split('״').join('').split('׳').join('')} - שיתופתא`;
          const he = dataRes.data.he;
          let text = '';
          if (typeof he === 'object') {
            text = he.join(' ').split('<')[0]
          }
          else {
            text = he.split('<')[0];
          }
          let allInputsEl = document.querySelectorAll(`meta[name="description"]`);
          if (allInputsEl[0])
            allInputsEl[0].setAttribute("content", text)
          allInputsEl = document.querySelectorAll(`meta[name="og:description"]`);
          if (allInputsEl[0])
            allInputsEl[0].setAttribute("content", text)
          allInputsEl = document.querySelectorAll(`meta[name="og:title"]`);
          if (allInputsEl[0])
            allInputsEl[0].setAttribute("content", `${dataRes.data.heRef.replace('שטיינזלץ', '').replace('״', '').replace('׳', '')} - שיתופתא`)


          return dataRes
        }

        );
      }
      else {
        if (pageNumber) {
          let comma = '.';
          if (!Number(pageNumber.split('.')[0])) {
            comma = ''
          }

          dataTextPromise = Axios.get(
            `${shitufta_api_url}texts/${capitalize(book)}${comma}${pageNumber}?${commentary}context=0&pad=0&wrapLinks=0&multiple=0`
          ).then((dataRes) => {
            document.title = `${dataRes.data.heRef.replace('שטיינזלץ', '').split('״').join('').split('׳').join('')} - שיתופתא`;
            const he = dataRes.data.he;
            let text = '';
            if (typeof he === 'object') {
              text = he.join(' ').split('<')[0]
            }
            else {
              text = he.split('<')[0];
            }
            let allInputsEl = document.querySelectorAll(`meta[name="description"]`);
            if (allInputsEl[0])
              allInputsEl[0].setAttribute("content", text)
            allInputsEl = document.querySelectorAll(`meta[name="og:description"]`);
            if (allInputsEl[0])
              allInputsEl[0].setAttribute("content", text)
            allInputsEl = document.querySelectorAll(`meta[name="og:title"]`);
            if (allInputsEl[0])
              allInputsEl[0].setAttribute("content", `${dataRes.data.heRef.replace('שטיינזלץ', '').replace('״', '').replace('׳', '')} - שיתופתא`)


            return dataRes
          }

          );
        }
        else {
          dataTextPromise = Axios.get(
            `${shitufta_api_url}texts/${capitalize(book)}?context=0&pad=0&wrapLinks=0&multiple=0`
          ).then((dataRes) => {
            document.title = `${dataRes.data.heRef.replace('שטיינזלץ', '').split('״').join('').split('׳').join('')} - שיתופתא`;
            const he = dataRes.data.he;
            let text = '';
            if (typeof he === 'object') {
              text = he.join(' ').split('<')[0]
            }
            else {
              text = he.split('<')[0];
            }
            let allInputsEl = document.querySelectorAll(`meta[name="description"]`);
            if (allInputsEl[0])
              allInputsEl[0].setAttribute("content", text)
            allInputsEl = document.querySelectorAll(`meta[name="og:description"]`);
            if (allInputsEl[0])
              allInputsEl[0].setAttribute("content", text)
            allInputsEl = document.querySelectorAll(`meta[name="og:title"]`);
            if (allInputsEl[0])
              allInputsEl[0].setAttribute("content", `${dataRes.data.heRef.replace('שטיינזלץ', '').replace('״', '').replace('׳', '')} - שיתופתא`)


            return dataRes
          }

          );
        }
      }



      // Making request to get the page data commentary links without data from Sefaria API / Our data
      let dataLinksPromise;
      if (pageNumber && categories[0] === 'Talmud' && categories[1] === 'Bavli' && !categories[2].includes('Minor Tractates')) {

        dataLinksPromise = Axios.get(
          `${shitufta_api_url}links/${book}${(categories[1] === 'Haggadah' || pageNumber === '' || (pageNumber.includes(',') && categories.length === 1) || (pageNumber.includes(',') && categories[0] !== 'Halakhah')) ? '' : '.'}${pageNumber}?with_text=0`
        );
      }
      else if (pageNumber && categories[0] === 'Talmud' && categories[1] === 'Yerushalmi') {
        const numberpage = pageNumber.replace('a', '').replace('b', '')

        dataLinksPromise = Axios.get(
          `${shitufta_api_url_links}links/${book}.${numberpage}?with_text=0`
        );
      }
      else {
        if (pageNumber) {
          let comma = '.';

          if (!Number(pageNumber.split('.')[0])) {
            comma = ''
          }
          dataLinksPromise = Axios.get(
            `${shitufta_api_url_links}links/${book}${comma}${pageNumber}?with_text=0`
          );
        }

        else {


          dataLinksPromise = Axios.get(
            `${shitufta_api_url_links}links/${book}?with_text=0`
          );
        }
      }


      let dataRashiPromise: any;
      if (((categories[0] === 'Talmud' && (categories[1] === 'Bavli') && !categories[2].includes('Minor Tractates') && categories.length === 3) || (categories[0] === 'Tanakh' && categories.length === 2))) {
        if (book === 'Kiddushin') {
          dataRashiPromise = Axios.get(
            `https://shitufta-back.shitufta.org.il/newapi/texts/Rashi_on_${book}.${pageNumber}?commentary=0&context=0&pad=0&wrapLinks=0&multiple=0`//&vhe=William_Davidson_Edition_-_Aramaic`
          );
        }
        else {
          if (book.includes('Lamentations')) {
            dataRashiPromise = Axios.get(
              `${shitufta_api_url}texts/Rashi_on_${book}.${pageNumber}?commentary=0&context=0&pad=0&wrapLinks=0&multiple=0&vhe=On_Your_Way`
            );
          }
          else
            dataRashiPromise = Axios.get(
              `${shitufta_api_url}texts/Rashi_on_${book}.${pageNumber}?commentary=0&context=0&pad=0&wrapLinks=0&multiple=0`//&vhe=William_Davidson_Edition_-_Aramaic`
            );
        }


      }
      let dataRanPromise: any;

      if (book === 'Nedarim') {
        dataRanPromise = Axios.get(
          `${shitufta_api_url}texts/Ran_on_${book}.${pageNumber}?commentary=0&context=0&pad=0&wrapLinks=0&multiple=0`//&vhe=William_Davidson_Edition_-_Aramaic`
        );

      }
      let dataRabbeinuGershomPromise: any;

      if (book === 'Makkot' && pageNumber === '24a') {
        dataRabbeinuGershomPromise = Axios.get(
          `${shitufta_api_url}texts/Rabbeinu_Gershom_on_${book}.${pageNumber}?commentary=0&context=0&pad=0&wrapLinks=0&multiple=0`//&vhe=William_Davidson_Edition_-_Aramaic`
        );

      }

      if (book === 'Pesachim' || book === 'Bava_Batra') {
        dataRabbeinuGershomPromise = await Axios.get(
          `${shitufta_api_url}texts/Rashbam_on_${book}.${pageNumber}?commentary=0&context=0&pad=0&wrapLinks=0&multiple=0`//&vhe=William_Davidson_Edition_-_Aramaic`
        );
      }


      let dataTosafotPromise: any;
      if (categories[0] === 'Talmud' && (categories[1] === 'Bavli') && !categories[2].includes('Minor Tractates') && categories.length === 3) {
        if (book === 'Kiddushin') {
          dataTosafotPromise = Axios.get(
            `https://shitufta-back.shitufta.org.il/newapi/texts/Tosafot_on_${book}.${pageNumber}?commentary=0&context=0&pad=0&wrapLinks=0&multiple=0`//&vhe=William_Davidson_Edition_-_Aramaic`
          );
        }
        else
          dataTosafotPromise = await Axios.get(
            `${shitufta_api_url}texts/Tosafot_on_${book}.${pageNumber}.1-100?commentary=0&context=0&pad=0&wrapLinks=0&multiple=0`
          );

      }
      let dataOnkelosPromise: any;

      if (categories[0] === 'Tanakh' && categories[1] === 'Torah' && categories.length === 2) {
        dataOnkelosPromise = await Axios.get(
          `${shitufta_api_url}texts/Onkelos_${book}.${pageNumber}?commentary=0&context=0&pad=0&wrapLinks=1&multiple=0`//&vhe=William_Davidson_Edition_-_Aramaic`
        );
      }
      const allBooksNamesPromise = Promise.resolve({ data: books });


      let layoutPromise = null;

      if (categories[0] === 'Talmud' && categories[1] === 'Bavli' && categories[2].includes('Seder')) {
        //getting layout data
        layoutPromise = Axios.get(
          `${config.layout_api}${book}/${pageNumber.split('.')[0]}`
        );
      }



      const [
        dataRes,
        chaptersRes,
        allBooksNamesRes,
        layoutRes,
        dataLinks,
        dataRashi,
        dataTosafot,
        dataRan,
        dataRabbeinuGershom,
        dataOnkelos
      ] = await Promise.all([
        dataTextPromise,
        chaptersPromise,
        allBooksNamesPromise,
        layoutPromise,
        dataLinksPromise,
        dataRashiPromise,
        dataTosafotPromise,
        dataRanPromise,
        dataRabbeinuGershomPromise,
        dataOnkelosPromise
      ]);

      const sectionNames = dataRes.data.sectionNames;

      let listCommentayBasic = [];
      if (dataRashi && !dataRashi.data['error'])
        listCommentayBasic.push(dataRashi);
      if (dataTosafot && !dataTosafot.data['error'])
        listCommentayBasic.push(dataTosafot);
      if (dataRan && !dataRan.data['error'])
        listCommentayBasic.push(dataRan);
      if (dataRabbeinuGershom && !dataRabbeinuGershom.data['error'])
        listCommentayBasic.push(dataRabbeinuGershom);
      if (dataOnkelos && !dataOnkelos.data['error'])
        listCommentayBasic.push(dataOnkelos);

      const pageToReturn: any = this.createPage(
        dataRes.data,
        chaptersRes.data,
        allBooksNamesRes.data,
        dataLinks.data,
        listCommentayBasic,
        categories,
        heCategories,
        dataRes.data.heVersionTitleInHebrew,
        dataRes.data.license,
        base_text_titles
      );
      if (layoutRes) {
        return [pageToReturn, layoutRes.data, null, categories, sectionNames];
      }
      else {
        return [pageToReturn, null, null, categories, sectionNames];
      }
    } catch (err) {

      if (err === 500) return [undefined, undefined, 500];
      return [
        undefined,
        undefined,
        "serverdown"
      ];
    }


  }




  // creating the transformed page
  private createPage(
    PageInfoFromApi: any,
    BookInfoFromApi: any,
    AllBooksName: any,
    dataLinks: any,
    listCommentayBasic: any,
    category: any,
    heCategory: any,
    version: any,
    license: any,
    base_text_titles: any
  ) {

    let schema: any;

    if (BookInfoFromApi.alts && BookInfoFromApi.categories[0] !== 'Midrash') {

      if (BookInfoFromApi.alts.Chapters && !BookInfoFromApi.alts.Parasha) {
        schema = BookInfoFromApi.alts.Chapters.nodes;
      }
      else if (BookInfoFromApi.alts.Topic) {
        schema = BookInfoFromApi.alts.Topic.nodes;
      }
      else if (!BookInfoFromApi.alts.Chapters && BookInfoFromApi.alts.Parasha) {
        schema = BookInfoFromApi.alts.Parasha.nodes;
      }

    }
    else {

      if (BookInfoFromApi.schema.nodes) {
        schema = BookInfoFromApi.schema.nodes;
      }
      else {
        schema = '';
      }
    }
    const [
      chapterTitle,
      chapterNumberTitle
    ] = this.getChapterNameAndNumberTitle(
      PageInfoFromApi.sectionRef,
      schema
    );




    let pageNumber: any = ''; //this.getPageNumber(PageInfoFromApi.sections[0]);
    let subPage: any = ''; //this.getSubPAge(PageInfoFromApi.sections[0]);
    if (category[0] === 'Tanakh' && category[1] !== 'Rishonim on Tanakh' && category[1] !== 'Acharonim on Tanakh') {
      pageNumber = PageInfoFromApi.ref.split(' ')[PageInfoFromApi.ref.split(' ').length - 1].replace(':', '. ')

      subPage = '';
    }
    else if (category[1] !== "Yerushalmi" && category[0] === 'Talmud' && PageInfoFromApi.sectionNames[0] === 'Daf') {
      pageNumber = this.getPageNumber(PageInfoFromApi.sections[0]);
      subPage = this.getSubPAge(PageInfoFromApi.sections[0]);
    }
    else if (category[0] === 'Halakhah' && category[2] !== 'Commentary') {

      pageNumber = PageInfoFromApi.ref.split(' ')[PageInfoFromApi.ref.split(' ').length - 1].replace(':', '. ');
      subPage = '';
    }
    else if (category[0] === 'Halakhah' && category[2] === 'Commentary') {
      pageNumber = PageInfoFromApi.ref.split(' ')[PageInfoFromApi.ref.split(' ').length - 1].replace(':', '. ');
      subPage = '';
    }
    else {
      pageNumber = PageInfoFromApi.ref.split(' ')[PageInfoFromApi.ref.split(' ').length - 1].replace(':', '. ');
      subPage = '';
    }




    const commentary = this.extractCommentary(
      dataLinks,
      PageInfoFromApi.he.length,
      listCommentayBasic,
      pageNumber
    );


    let currentPageTitle = this.getCurrentPageTitle(PageInfoFromApi.heRef, category);


    const getPrevStart = (nettemp1: any) => {
      nettemp1 = nettemp1.replace('.', '');
      let count: any;

      let content_counts = BookInfoFromApi.schema.content_counts;

      if (!content_counts) {
        content_counts = BookInfoFromApi.schema.nodes[0].content_counts;
      }
      if (BookInfoFromApi.schema && BookInfoFromApi.schema.key === "Shulchan Arukh, Even HaEzer") {
        content_counts = BookInfoFromApi.schema.nodes[0].content_counts;
      }

      count = content_counts[nettemp1 - 2];

      return PageInfoFromApi.prev + '.' + count;

    }
    const getPrevMiddle = (nettemp: any) => {
      //if get the sub page
      if (PageInfoFromApi.prev && PageInfoFromApi.prev.includes(':'))
        return PageInfoFromApi.prev;
      //if not get the sub page
      return PageInfoFromApi.sectionRef + '.' + (nettemp - 1);

    }

    const getNext = (nettemp: any, subPage: any) => {
      nettemp = nettemp.replace('.', '');
      let count: any;

      let content_counts = BookInfoFromApi.schema.content_counts;

      if (!content_counts) {
        content_counts = BookInfoFromApi.schema.nodes[0].content_counts;
      }
      count = content_counts[nettemp - 1];

      if (count > (Number(subPage)))
        return PageInfoFromApi.sectionRef + '.' + (Number(subPage) + 3);
      else
        return PageInfoFromApi.next;
    }

    const commentarySefarim = this.extractCommentarySefarim(
      dataLinks,
      PageInfoFromApi.he.length,
      PageInfoFromApi.book.replace(' ', '_'),
      pageNumber,
      subPage
    );

    let prev = '';
    let next = '';
    if (PageInfoFromApi.prev !== null) {

      if (PageInfoFromApi.next && PageInfoFromApi.next.includes(',')) {
        next = PageInfoFromApi.next.split(' ').join('_');
        prev = PageInfoFromApi.prev.split(' ').join('_');
      }

      else if (category[1] === 'Haggadah') {
        if (PageInfoFromApi.next !== null)
          next =
            'Pesach_Haggadah ' +
            PageInfoFromApi.next
              .split('Haggadah')[1]
              .split(' ').join('_');
        if (PageInfoFromApi.prev !== null)
          prev = 'Pesach_Haggadah ' + PageInfoFromApi.prev.split('Haggadah')[1].split(' ').join('_');

      }
      else if (pageNumber === '' || (pageNumber.includes(',') && category[0] !== 'Halakhah')) {

        next = PageInfoFromApi.next.split('_').join(' ');
        prev = PageInfoFromApi.prev.split('_').join(' ');
      }
      else if (category[0] === 'Tanakh' && category.length === 2) {
        let nettemp1 = pageNumber.split(' ');
        let subPages = nettemp1[1].split('-');
        if (Number(subPages[0]) <= 3) {

          if (PageInfoFromApi.prev !== null) {
            prev = getPrevStart(nettemp1[0])
          }
        }
        else {

          prev = getPrevMiddle(subPages[0])
        }
        next = getNext(nettemp1[0], subPages[1]);

      }
      else {

        if (PageInfoFromApi.next)
          next = PageInfoFromApi.next.split(':')[0];
        if (PageInfoFromApi.prev)
          prev = PageInfoFromApi.prev.split(':')[0];
      }

    }



    let categories = 2;

    if ((category[0] === 'Tanakh' && category[1] !== 'Rishonim on Tanakh' && category[1] !== 'Acharonim on Tanakh') || category[0] === 'Halakhah')
      categories = 1;

    let toReturn: any = {
      id: PageInfoFromApi.sectionRef,
      pageTitle: PageInfoFromApi.heRef,
      text: PageInfoFromApi.he,
      commentary,
      commentarySefarim,
      navigation: {
        seder: PageInfoFromApi.categories[categories],
        book: PageInfoFromApi.book.replace(' ', '_'),
        pageNumber,
        subPage,
        next: next,
        prev: prev,
        categoryName: category,
        heCategoryName: heCategory,
        base_text_titles: base_text_titles,
        alts: PageInfoFromApi.alts
      },
      titles: {
        seder: PageInfoFromApi.categories[categories],
        book: PageInfoFromApi.heIndexTitle,
        currentPageTitle,
        chapterTitle,
        chapterNumberTitle,
      },
      allbooksNames: AllBooksName.basic,
      allBooksNamesT: AllBooksName.seder1,
      contentCounts: BookInfoFromApi.schema,
      version: version,
      license: license
    };



    toReturn = manualDataFixes(
      pageNumber,
      subPage,
      toReturn,
      config.manualDataFixes
    );

    return toReturn;
  }




  /**
   *
   * @param pageSection is the page number + page sub letter(a or b) together, this function split them and return the
   * all string without the sub letter.
   */
  private getPageNumber(pageSection: string) {

    return pageSection.substring(0, pageSection.length - 1);
  }

  private getCurrentPageTitle(fullTitle: string, category: any) {

    if (category[0] === 'Tanakh' && category[1] !== 'Rishonim on Tanakh' && category[1] !== 'Acharonim on Tanakh')
      return fullTitle.split(" ")[fullTitle.split(" ").length - 1].replace("׳", "")
    else if (category[0] === 'Talmud') {
      return fullTitle.split(" ")[fullTitle.split(" ").length - 1].replace("׳", "")
    }
    else if ((category[0] === 'Tanaitic' && category[1] !== "Minor Tractates") || category[0] === 'Chasidut') {
      return fullTitle.split(" ")[fullTitle.split(" ").length - 1].replace("׳", "")
    }
    else if (category[0] === 'Responsa') {
      return fullTitle.split(",")[1]
    }
    else
      return fullTitle;

  }

  private getChapterNameAndNumberTitle(id: string, chapters: any) {

    if (chapters === '' || chapters === undefined) {

      return [id, chapters];
    }

    let counter = 0;
    let nameTitle;
    let chapterNumberTitle;


    chapters.forEach((chapter: any) => {

      if (chapter.refs)
        chapter.refs.forEach((chapterName: string) => {
          if (chapterName.includes(id) && counter === 0) {
            nameTitle = chapter.heTitle;
            // @ts-ignore
            chapterNumberTitle = chapterNumberTitleHeb[chapter.title];
            counter++;
          }
        });
    });

    return [nameTitle, chapterNumberTitle];
  }

  /**
   *
   * @param pageSection is the page number + page sub letter(a or b) together, this function split them and return the
   * last letter in the string - the sub letter.
   */
  private getSubPAge(pageSection: string) {

    const res = pageSection.split("");
    let subpageDot;
    if (res[res.length - 1] === "a") {
      // subpageDot = ".";
      subpageDot = "a";
    } else if (res[res.length - 1] === "b") {
      // subpageDot = ":";
      subpageDot = "b";
    }
    return subpageDot;
  }

  // making the data for the Sfarim tab
  private extractCommentarySefarim(
    commentary: any,
    unitTextLength: number,
    bookName: any,
    pageNumber: string,
    subPage: any
  ) {

    let pageNumberInt = Number(pageNumber);

    let num = 0;
    if (pageNumber.includes('-'))
      num = Number(pageNumber.split(' ')[1].split('-')[0]) - 1;
    const sortedCommentaryHe: any = {
      mesoretHashas: [],
      einMishpatHashalem: [],
      TorahOrHashalem: [],
      extraBooks: {},
      Biyur: {}
    };

    let kishurimDic: any = {
      mesoretHashas: 'מסורת הש"ס השלם',
      einMishpatHashalem: "עין משפט השלם",
      TorahOrHashalem: "תורה אור השלם"
    };

    let newCommentary: any = {};
    let newCommentaryHe: any = {};
    let extraBooksHe: any = {};
    let extraBooksDic = [
      "Chasidut",
      "Musar",
      "Guides",
      "Tanaitic",
      "Responsa",
      "Philosophy",
      "Kabbalah",
      "Quoting Commentary"
    ];

    var result = commentary.filter((obj: any) => {
      return obj.collectiveTitle.en === "Steinsaltz"
    })



    // categories that shouldn't be inside the data.
    let removeFromData: any = manualDataFixesSfarim(
      config.manualDataFixes,
      bookName,
      pageNumberInt,
      subPage
    );

    let itemArrayCommentaryNumbers: any = [];
    // go over each one of the commentary and order them by our categories.
    commentary.forEach((unitCommentary: any, idx: number) => {

      const isRemove = removeFromData.some((removeItem: any) => {

        return (
          unitCommentary.category === removeItem ||
          unitCommentary.collectiveTitle.en === removeItem
        );
      });
      if (isRemove) {
        itemArrayCommentaryNumbers.push(idx);
        return;
      }


      for (let item in sortedCommentaryHe) {
        if (
          Array.isArray(sortedCommentaryHe[item]) &&
          !sortedCommentaryHe[item].length &&
          item !== "extraBooks"
        ) {
          const data = new Array(unitTextLength);
          for (let i = 0; i < unitTextLength; i++) {
            data[i] = [];
          }
          sortedCommentaryHe[item] = data;
        }
        if (
          item === "einMishpatHashalem" &&
          unitCommentary.category === "Halakhah"
        ) {
          if (sortedCommentaryHe[item][unitCommentary.anchorVerse - 1 - num]) {
            sortedCommentaryHe[item][unitCommentary.anchorVerse - 1 - num].push({
              title: unitCommentary.sourceHeRef,
              txt: '`' + unitCommentary.ref,
            });
            itemArrayCommentaryNumbers.push(idx);
          }
        } else if (
          item === "TorahOrHashalem" &&
          unitCommentary.category === "Tanakh"
        ) {
          if (sortedCommentaryHe[item][unitCommentary.anchorVerse - 1 - num]) {
            sortedCommentaryHe[item][unitCommentary.anchorVerse - 1 - num].push({
              title: unitCommentary.sourceHeRef,
              txt: '`' + unitCommentary.ref
            });
            itemArrayCommentaryNumbers.push(idx);
          }
        }
        else if (
          (item === "mesoretHashas" && unitCommentary.category === "Talmud") ||
          (item === "mesoretHashas" &&
            unitCommentary.category === "Mishnah") ||
          (item === "mesoretHashas" && unitCommentary.category === "Midrash")
        ) {
          if (sortedCommentaryHe[item][unitCommentary.anchorVerse - 1 - num]) {
            sortedCommentaryHe[item][unitCommentary.anchorVerse - 1 - num].push({
              title: unitCommentary.sourceHeRef,
              txt: '`' + unitCommentary.ref
            });
            itemArrayCommentaryNumbers.push(idx);
          }
        } else if (item === "extraBooks") {
          for (let i = 0; i < extraBooksDic.length; i++) {
            if (extraBooksDic[i] === unitCommentary.category) {
              const key = unitCommentary.collectiveTitle.he;
              const keyEn = unitCommentary.collectiveTitle.en;
              if (Array.isArray(unitCommentary.he)) {
                unitCommentary.he = unitCommentary.he.join(" ");
              }
              if (!sortedCommentaryHe[item][keyEn]) {
                const data = new Array(unitTextLength);
                for (let i = 0; i < unitTextLength; i++) {
                  data[i] = [];
                }
                extraBooksHe[keyEn] = key;
                sortedCommentaryHe[item][keyEn] = data;
              }
              if (
                sortedCommentaryHe[item][keyEn][unitCommentary.anchorVerse - 1 - num]
              ) {
                sortedCommentaryHe[item][keyEn][
                  unitCommentary.anchorVerse - 1 - num
                ].push('`' + unitCommentary.ref);
                itemArrayCommentaryNumbers.push(idx);
              }
            }
          }
        }
      }
    });





    let itemArrayCommentaryNumbersSet = new Set(itemArrayCommentaryNumbers);
    // put the data for the rest of the commentaries in the "commentary" variable, and make sure nothing slips in.
    commentary.forEach((unitCommentary: any, idx: any) => {
      if (itemArrayCommentaryNumbersSet.has(idx)) return;
      const key = unitCommentary.collectiveTitle.he;
      const keyEn = unitCommentary.collectiveTitle.en;
      if (Array.isArray(unitCommentary.he)) {
        unitCommentary.he = unitCommentary.he.join(" ");
      }
      if (!newCommentary[keyEn]) {
        const data = new Array(unitTextLength);
        for (let i = 0; i < unitTextLength; i++) {
          data[i] = [];
        }
        newCommentary[keyEn] = data;
        newCommentaryHe[keyEn] = key.replace(' שטיינזלץ', '');
      }
      if (newCommentary[keyEn][unitCommentary.anchorVerse - 1 - num]) {
        newCommentary[keyEn][unitCommentary.anchorVerse - 1 - num].push(
          '`' + unitCommentary.ref
        );
        itemArrayCommentaryNumbers.push(idx);
      }
    });

    //add the additional data - the 3 dic and the commentary variable to the data that will be use in the Sfarim tab.
    sortedCommentaryHe.commentary = newCommentary;
    sortedCommentaryHe.commentaryDic = newCommentaryHe;
    sortedCommentaryHe.extraBooksHe = extraBooksHe;
    sortedCommentaryHe.allDic = Object.assign(
      newCommentaryHe,
      extraBooksHe,
      kishurimDic
    );
    sortedCommentaryHe.Biyur = result;
    sortedCommentaryHe.kishurimDic = kishurimDic;
    return sortedCommentaryHe;
  }

  // index all the commentary to the text units index.
  private extractCommentary(commentary: any, unitTextLength: number, listCommentayBasic: any, pageNumber: string) {

    let num = 0;

    if (pageNumber.includes('-'))
      num = Number(pageNumber.split(' ')[1].split('-')[0]) - 1;
    const sortedCommentary: any = {};

    commentary.forEach((unitCommentary: any) => {
      // if (!unitCommentary.category || unitCommentary.category === 'Commentary') {
      //   return;
      //  }
      const key = unitCommentary.collectiveTitle.en;
      //if (Array.isArray(unitCommentary.he)) {
      //  unitCommentary.he = unitCommentary.he.join(" ");
      // }
      if (!sortedCommentary[key]) {
        const data = new Array(unitTextLength);
        for (let i = 0; i < unitTextLength; i++) {
          data[i] = [];
        }
        sortedCommentary[key] = data;
      }
      if (sortedCommentary[key][unitCommentary.anchorVerse - 1 - num]) {

        sortedCommentary[key][unitCommentary.anchorVerse - 1 - num].push(
          unitCommentary.ref
        );
      }
    });

    listCommentayBasic.forEach((unitCommentary: any) => {

      const conector = unitCommentary.data.collectiveTitle;
      const dataHe = unitCommentary.data.he;
      if (sortedCommentary[conector])
        sortedCommentary[conector] = dataHe;
      else
        sortedCommentary[unitCommentary.data.book] = dataHe;
    });

    return sortedCommentary;
  }
}

export default new PageService();
